/*----------------------------------------*/
/*  11 - Components - Slider
/*----------------------------------------*/
/* ---Slider With Category Menu--- */
.slider-with-category_menu {
	padding-top: 30px;
	.container-fluid {
		padding: 0 3%;
	}
	.banner-item {
		height: 100%;
		border: 1px solid $border-color;
		a {
			display: block;
			height: 100%;
		}
	}
}
/* --Slider Area One--- */
.hiraola-slider_area {
	.main-slider {
		.slider-content {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 5%;
			> h5 {
				font-weight: 500;
				text-transform: capitalize;
				margin-bottom: 0;
				padding-bottom: 12px;
				> span {
					color: $primary-color;
				}
			}
			> h2 {
				text-transform: capitalize;
				margin-bottom: 0;
				padding-bottom: 10px;
				font-size: 40px;
				@include breakpoint (normal) {
					font-size: 35px;
				}
				@include breakpoint (max-small) {
					font-size: 30px;
				}
			}
			> h3 {
				text-transform: capitalize;
				margin-bottom: 0;
				padding-bottom: 20px;
				font-size: 40px;
				@include breakpoint (normal) {
					font-size: 30px;
				}
				@include breakpoint (max-small) {
					font-size: 28px;
				}
			}
			> h4 {
				margin-bottom: 0;
				padding-bottom: 45px;
				font-size: 20px;
				> span {
					font-size: 30px;
					color: $primary-color;
				}
			}
		}
		.slick-arrow {
			display: none !important;
		}
	}
}
/* ---Slider Area Two--- */
.hiraola-slider_area-2 {
	.main-slider {
		.slider-content {
			max-width: 52%;
			text-align: center;
			position: absolute;
			top: 50%;
			left: auto;
			transform: translateY(-50%);
			z-index: 8;
			text-align: left;
			@include breakpoint (xx-small) {
				max-width: 100%;
			}
			> h5 {
				font-weight: 500;
				text-transform: capitalize;
				margin-bottom: 0;
				padding-bottom: 7px;
				> span {
					color: $primary-color;
				}
				@include breakpoint (max-small) {
					padding-bottom: 10px;
				}
			}
			> h2 {
				text-transform: capitalize;
				margin-bottom: 0;
				padding-bottom: 5px;
				font-size: 60px;
				@include breakpoint (max-small) {
					padding-bottom: 10px;
					font-size: 30px;
				}
			}
			> h3 {
				text-transform: capitalize;
				margin-bottom: 0;
				padding-bottom: 20px;
				font-size: 50px;
				@include breakpoint (max-small) {
					font-size: 30px;
				}
			}
			> h4 {
				margin-bottom: 0;
				padding-bottom: 45px;
				font-size: 20px;
				> span {
					font-size: 30px;
					color: $primary-color;
				}
			}
			.hiraola-btn-ps_center {
				display: flex;
				justify-content: flex-start;
			}
		}
	}
}
/* --Slider Background Image-- */
// Home One
.bg-1,
.bg-2,
.bg-3 {
	background-image: url('../images/slider/1.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 520px;
	@include breakpoint (xx-small) {
		min-height: 380px;
	}
}
.bg-2 {
	background-image: url('../images/slider/2.jpg');
}
.bg-3 {
	background-image: url('../images/slider/3.jpg');
}
// Home Two
.bg-4,
.bg-5 {
	background-image: url('../images/slider/4.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 825px;
	@include breakpoint (max-normal) {
		min-height: 520px;
	}
	@include breakpoint (max-x_small) {
		min-height: 410px;
	}
}
.bg-5 {
	background-image: url('../images/slider/5.jpg');
}

/* ---Hiraola's Slider Progress Bar--- */
.slider-progress {
	-webkit-animation: initial;
	animation: initial;
	background: rgba(0, 0, 0, .3);
	height: 5px;
	left: 0;
	opacity: 0.8;
	position: absolute;
	top: 0;
	z-index: 4;
}

.slick-current .slider-progress {
	-webkit-animation: timebar 8s ease-in-out 0s 1 backwards;
	animation: timebar 8s ease-in-out 0s 1 backwards;
}

// Hiraola's Keyframes
@-webkit-keyframes timebar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
@keyframes timebar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

/* Slider Pagination  */
.main-slider,
.hiraola-product-tab_slider-2,
.hiraola-product_slider-3,
.sp-img_slider,
.sp-slider,
.sp-img_slider-3 {
	.slick-arrow {
		background-color: rgba(255, 255, 255, .5);
		box-shadow: 0px 0px 9.8px 0.2px rgba(0, 0, 0, 0.05);
		font-size: 24px;
		width: 50px;
		height: 50px;
		line-height: 50px;
		display: block;
		color: $primary-color;
		text-align: center;
		position: absolute;
		z-index: 8;
		cursor: pointer;
		border-radius: $hiraola-border_radius;
		top: 50%;
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		opacity: 0;
		visibility: hidden;
		left: 20px;
		@include breakpoint (max-small) {
			display: none !important;
		}
		&:hover {
			background-color: darken($bg-white_color, 6);
		}
	}
	.slick-next {
		left: auto;
		right: 20px;
	}
	&:hover {
		.slick-arrow {
			opacity: 1;
			visibility: visible;
		}
	}
}

// Product Slider Pagination
.hiraola-product_slider,
.hiraola-product-tab_slider-3 {
	.slick-arrow {
		font-size: 24px;
		display: block;
		text-align: center;
		position: absolute;
		z-index: 8;
		cursor: pointer;
		top: -80px;
		left: auto;
		right: 30px;
		&:hover {
			color: $primary-color;
		}
		&.slick-next {
			right: 0;
		}
		@include breakpoint (max-small) {
			display: none !important;
		}
	}
}

/* ---Single Product Image Slider--- */
.sp-img_slider {
	.slick-arrow {
		width: 35px;
		height: 35px;
		line-height: 35px;
		font-size: 18px;
		left: -10px;
		right: auto;
		&.slick-next {
			right: -10px;
			left: auto;
		}
	}
}

/* ---Single Porduct Image Slider Two--- */
.sp-img_slider-2 {
	border: 1px solid $border-color;
}
.sp-img_slider-nav {
	padding: 0 30px;
	margin-top: 30px;
	.slick-list {
		margin: 0 -15px;
		.single-slide {
			border: 1px solid $border-color;
			margin: 0 15px;
		}
	}
}

/* ---Single Porduct Image Slider Three--- */
.sp-img_slider-3 {
	@include breakpoint (medium) {
		padding: 60px 0;
	}
	@include breakpoint (max-small) {
		flex-basis: 30% !important;
		width: 30% !important;
	}
	@include breakpoint (xx-small) {
		flex-basis: 40% !important;
		width: 40% !important;
	}
	.slick-arrow {
		top: 0;
		bottom: auto;
		width: 35px;
		height: 35px;
		line-height: 35px;
		left: 50%;
		transform: translateX(-50%);
		&.slick-prev {
			@include breakpoint (max-medium) {
				top: 30px;
			}
		}
		&.slick-next {
			top: auto;
			bottom: 0;
			@include breakpoint (max-medium) {
				bottom: 60px;
			}
		}
	}
	.slick-list {
		@include breakpoint (max-medium) {
			margin-left: 0;
		}
		.slick-slide {
			@include breakpoint (medium) {
				margin-left: 30px;
				margin-right: 30px;
			}
		}
	}
}

/* ---Slider Dots--- */
.main-slider {
	@include jb-slick_dots;
}

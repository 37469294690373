/*----------------------------------------*/
/*  16 - Components - Shipping
/*----------------------------------------*/
.hiraola-shipping_area {
	background-color: $white-smoke_color;
	.shipping-nav {
		border-bottom: 1px solid $border-color;
		padding: 80px 0 75px;
		.shipping-item {
			text-align: center;
			.shipping-icon {
				padding-bottom: 10px;
				font-size: 42px;
			}
			.shipping-content {
				> h6 {
					margin-bottom: 0;
					padding-bottom: 10px;
					font-size: 15px;
				}
				> p {
					margin-bottom: 0;
				}
			}
		}
		[class*="col"] {
			&:nth-child(-n + 2) {
				.shipping-item {
					@include breakpoint (medium) {
						padding-bottom: 25px;
					}
				}
			}
			&:nth-child(-n + 3) {
				.shipping-item {
					@include breakpoint (max-small) {
						padding-bottom: 25px;
					}
				}
			}
		}
	}
	&.hiraola-shipping_area-2 {
		background-color: $bg-white_color;
		.shipping-nav {
			padding: 60px 0 55px;
		}
	}
}

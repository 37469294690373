@charset "UTF-8";
/******************************

INDEX:

    00 - Base - Typography
    01 - Base - Variables
    02 - Base - Mixins

    03 - Utilities - Helper
    04 - Utilities - Color

    10 - Components - Button
    11 - Components - Slider
    12 - Components - Animation
    13 - Components - Navigation
    14 - Components - Banner
    15 - Components - Product
    16 - Components - Shipping
    17 - Components - Product Tab
    18 - Components - Form
    19 - Components - Social Link
    20 - Components - Modal
    21 - Components - Brand
    22 - Components - Breadcrumb

    25 - Section - Header
    26 - Section - Footer

    29 - Pages - Shop
    30 - Pages - Single Product
    31 - Pages - Blog
    32 - Pages - My Account
    33 - Pages - Login | Register
    34 - Pages - Wishlist
    35 - Pages - Cart
    36 - Pages - Checkout
    37 - Pages - Compare
    38 - Pages - Contact
    39 - Pages - About Us
    40 - Pages - FAQ
    41 - Pages - 404

******************************/
/*
    Body Background Color:    #ffffff;
    Primary Color:            #cda557;
    Primary Color 2:           #be8658;
    Body Font Family:         'Lato', sans-serif

*/
@import url("https://fonts.googleapis.com/css?family=Dancing+Script:400,700|Lato:300,300i,400,400i,700,700i,900,900i|Pacifico");
/*----------------------------------------*/
/*  00 - Base - Typography
/*----------------------------------------*/
body {
  font-family: "Lato", sans-serif;
  background-color: #ffffff;
  color: #595959;
  font-size: 16px;
  line-height: 24px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
  color: #333333;
  line-height: 1;
  font-weight: 700;
}

p {
  font-family: "Lato", sans-serif;
  color: #595959;
  font-size: 16px;
  line-height: 24px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input {
  color: #888888;
}

input::placeholder {
  color: #888888;
}

._keyfocus :focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
  outline: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

.img-full {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
}

a {
  color: #595959;
}

a:hover {
  color: #cda557 !important;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover {
  color: #cda557;
}

a,
a:hover,
a:focus,
a:active,
button,
button:focus,
input,
select,
textarea {
  outline: none;
}

button {
  cursor: pointer;
  border: none;
  padding: 0;
  outline: none;
  background: transparent;
}

/* ---Alista's Blog Section Default Youtube & Soundcloud Height--- */
.blog-grid-view_area .embed-responsive {
  min-height: 260px;
}

.blog-list-view_area .embed-responsive {
  min-height: 295px;
}

.blog-list-fullwidth_area .embed-responsive {
  min-height: 400px;
}

.blog-column-three_area .embed-responsive {
  min-height: 245px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-column-three_area .embed-responsive {
    min-height: 205px;
  }
}

@media (max-width: 991px) {
  .blog-column-three_area .embed-responsive {
    min-height: 100%;
  }
}

/*----------------------------------------*/
/*  03 - Utilities - Helper
/*----------------------------------------*/
/* ---Hiraola's Custom Column--- */
.grid-full {
  flex: 0 0 62.8%;
  max-width: 62.8%;
}

@media (max-width: 1500px) {
  .grid-full {
    flex: 0 0 55%;
    max-width: 55%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grid-full {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid-full {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .grid-full {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.grid-half {
  flex: 0 0 18.6%;
  max-width: 18.6%;
}

@media (max-width: 1500px) {
  .grid-half {
    flex: 0 0 22.5%;
    max-width: 22.5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grid-half {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid-half {
    flex: 0 0 55%;
    max-width: 55%;
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .grid-half {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid-half.grid-md_half {
    flex: 0 0 45%;
    max-width: 45%;
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .grid-half.grid-md_half {
    padding-top: 30px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid-half .category-menu .category-menu-list {
    display: block !important;
  }
}

/* ---Hiraola's Preloader---*/
.loading {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  z-index: 9999999999999;
}

.loading .middle {
  position: absolute;
  top: 50%;
  width: 100%;
}

.loading .middle .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.loading .middle .lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #cda557;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading .middle .lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.loading .middle .lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.loading .middle .lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.loading .middle .lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

/* ---Hiraola's Product Sticker---*/
.sticker,
.sticker-2 {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 45px;
  height: 20px;
  line-height: 18px;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  background: #cda557;
  color: #ffffff;
  text-transform: uppercase;
}

.sticker:before,
.sticker-2:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #cda557 transparent transparent transparent;
  left: 9px;
  top: 100%;
  position: absolute;
}

.sticker-2 {
  background: #ff4000;
  right: 20px;
  left: auto;
}

.sticker-2:before {
  border-color: #ff4000 transparent transparent transparent;
}

/* ---Hiraola's Section Title--- */
.hiraola-section_title {
  text-align: left;
  border-bottom: 1px solid #e5e5e5;
}

.hiraola-section_title > h4 {
  position: relative;
  margin-bottom: 0;
  text-transform: uppercase;
  padding-bottom: 25px;
}

.hiraola-section_title > h4:before {
  content: "";
  background-color: #cda557;
  height: 1px;
  width: 160px;
  position: absolute;
  bottom: 0;
  left: 0;
}

/* ---Hiraola's Spacing Between Slide Item & Other Related Stuff---*/
.slick-list {
  margin-left: -30px;
}

.slick-list .slick-slide {
  margin-left: 30px;
  outline: none;
}

/* ---Only For Single Product Page | Spacing Between Slide Item--- */
.sp-images .slick-list {
  margin-left: 0;
}

.sp-images .slick-list .slick-slide {
  margin-left: 0;
}

/* ---Hiraola's Spacing Between Two Row---*/
.hiraola-product_slider .slick-slide div:first-child .slide-item,
.hiraola-product-tab_slider-3 .slick-slide div:first-child .slide-item {
  margin-bottom: 30px;
}

/* ---Tab Content & Pane Fix--- */
.tab-content {
  width: 100%;
}

.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/* ---Hiraola's Rating Box Empty Star Color--- */
.rating-box > ul > li.silver-color > i {
  color: #bababa !important;
}

/* ---Hiraola's Global Overlay--- */
.global-overlay {
  background-color: rgba(51, 51, 51, 0.9);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  visibility: hidden;
}

.global-overlay.overlay-open {
  visibility: visible;
}

/* ---Hiraola's ScrollUp--- */
#scrollUp {
  right: 15px;
  bottom: 15px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  overflow: hidden;
  text-transform: uppercase;
  border-radius: 100%;
  z-index: 1000 !important;
}

#scrollUp:hover > i {
  animation: alisSlideInUp 1s infinite;
}

/* ---Hiraola's Tooltip--- */
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  border-radius: .25rem;
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  content: "";
  border-width: 5px 5px 0;
}

/* ---Hiraola's Pagination--- */
.hiraola-paginatoin-area {
  padding-top: 30px;
}

.hiraola-paginatoin-area .hiraola-pagination-box li {
  display: inline-block;
}

.hiraola-paginatoin-area .hiraola-pagination-box li.active > a {
  background-color: #cda557;
  color: #ffffff !important;
}

.hiraola-paginatoin-area .hiraola-pagination-box li > a {
  border: 1px solid #e5e5e5;
  padding: 9px 15px;
  display: block;
}

.hiraola-paginatoin-area .hiraola-pagination-box li:hover > a {
  background-color: #cda557;
  color: #ffffff !important;
}

.hiraola-paginatoin-area .product-select-box {
  display: flex;
  justify-content: flex-end;
}

.hiraola-paginatoin-area .product-select-box .product-short {
  display: flex;
  align-items: center;
}

.hiraola-paginatoin-area .product-select-box .product-short > p {
  all: inherit;
  padding-right: 15px;
}

.hiraola-paginatoin-area .product-select-box .product-short > span {
  padding-left: 15px;
}

/* ---Hiraola's Quantity--- */
.quantity .cart-plus-minus {
  position: relative;
  width: 76px;
  text-align: left;
}

.quantity .cart-plus-minus > .cart-plus-minus-box {
  border: 1px solid #e5e5e5;
  height: 46px;
  text-align: center;
  width: 48px;
  background: #ffffff;
}

.quantity .cart-plus-minus > .qtybutton {
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  cursor: pointer;
  height: 23px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  width: 28px;
  line-height: 21px;
}

.quantity .cart-plus-minus > .qtybutton:hover {
  background: #ebebeb;
}

.quantity .cart-plus-minus > .qtybutton.dec {
  bottom: 0;
  right: 0;
}

.quantity .cart-plus-minus > .qtybutton.inc {
  border-bottom: none;
  top: 0;
  right: 0;
}

/* ---Hiraola's  Nice Select Customization--- */
.myniceselect.nice-select {
  padding: 0;
  margin: 0;
  height: auto;
  line-height: auto;
}

.myniceselect.nice-select span {
  height: 30px;
  line-height: 30px;
  display: inline-block;
  padding: 0 60px;
}

.myniceselect.nice-select .list {
  width: 100%;
}

/* ---Base Transition--- */
a,
button, .global-overlay, .quantity .cart-plus-minus > .qtybutton, .popup_wrapper, .popup_wrapper .test .popup_off, .popup_wrapper .test .popup_off > i, .popup_wrapper .subscribe_area .subscribe-form-group form button, .template-color-1 .hiraola-product_slider .slide-item .single_product,
.template-color-1 .hiraola-product-tab_slider-2 .slide-item .single_product,
.template-color-1 .hiraola-product-tab_slider-3 .slide-item .single_product,
.template-color-1 .hiraola-product_slider-3 .slide-item .single_product,
.template-color-1 .shop-product-wrap .slide-item .single_product, .template-color-1 .hiraola-product_slider .list-slide_item .single_product,
.template-color-1 .hiraola-product-tab_slider-2 .list-slide_item .single_product,
.template-color-1 .hiraola-product-tab_slider-3 .list-slide_item .single_product,
.template-color-1 .hiraola-product_slider-3 .list-slide_item .single_product,
.template-color-1 .shop-product-wrap .list-slide_item .single_product, .template-color-2 .hiraola-product_slider .slide-item .single_product,
.template-color-2 .hiraola-product-tab_slider-2 .slide-item .single_product,
.template-color-2 .hiraola-product-tab_slider-3 .slide-item .single_product,
.template-color-2 .hiraola-product_slider-3 .slide-item .single_product,
.template-color-2 .shop-product-wrap .slide-item .single_product, .template-color-2 .hiraola-product_slider .list-slide_item .single_product,
.template-color-2 .hiraola-product-tab_slider-2 .list-slide_item .single_product,
.template-color-2 .hiraola-product-tab_slider-3 .list-slide_item .single_product,
.template-color-2 .hiraola-product_slider-3 .list-slide_item .single_product,
.template-color-2 .shop-product-wrap .list-slide_item .single_product, .template-color-3 .hiraola-product_slider .slide-item .single_product,
.template-color-3 .hiraola-product-tab_slider-2 .slide-item .single_product,
.template-color-3 .hiraola-product-tab_slider-3 .slide-item .single_product,
.template-color-3 .hiraola-product_slider-3 .slide-item .single_product,
.template-color-3 .shop-product-wrap .slide-item .single_product, .template-color-3 .hiraola-product_slider .list-slide_item .single_product,
.template-color-3 .hiraola-product-tab_slider-2 .list-slide_item .single_product,
.template-color-3 .hiraola-product-tab_slider-3 .list-slide_item .single_product,
.template-color-3 .hiraola-product_slider-3 .list-slide_item .single_product,
.template-color-3 .shop-product-wrap .list-slide_item .single_product, .template-color-4 .hiraola-product_slider .slide-item .single_product,
.template-color-4 .hiraola-product-tab_slider-2 .slide-item .single_product,
.template-color-4 .hiraola-product-tab_slider-3 .slide-item .single_product,
.template-color-4 .hiraola-product_slider-3 .slide-item .single_product,
.template-color-4 .shop-product-wrap .slide-item .single_product, .template-color-4 .hiraola-product_slider .list-slide_item .single_product,
.template-color-4 .hiraola-product-tab_slider-2 .list-slide_item .single_product,
.template-color-4 .hiraola-product-tab_slider-3 .list-slide_item .single_product,
.template-color-4 .hiraola-product_slider-3 .list-slide_item .single_product,
.template-color-4 .shop-product-wrap .list-slide_item .single_product, button.li-btn, .hiraola-btn,
.hiraola-banner_btn,
.hiraola-btn-bondi_blue,
.hiraola-btn_fullwidth,
.hiraola-compare_btn,
.hiraola-filter_btn,
.hiraola-btn_dark,
.hiraola-btn_limerick, .main-slider .slick-dots li, .mobile-menu_wrapper .offcanvas-menu-inner,
.offcanvas-minicart_wrapper .offcanvas-menu-inner,
.offcanvas-search_wrapper .offcanvas-menu-inner, .btn-close, .btn-close > i, .offcanvas-navigation .mobile-menu li > .menu-expand, .offcanvas-menu_wrapper .offcanvas-menu-inner, .category-menu .category-menu-list > ul > li.right-menu > a::after, .cat-mega-menu, .hiraola-product_slider .slide-item .single_product .product-img .add-actions,
.hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions,
.hiraola-trending-product_slider .slide-item .single_product .product-img .add-actions,
.hiraola-product-tab_slider-2 .slide-item .single_product .product-img .add-actions,
.hiraola-product-tab_slider-3 .slide-item .single_product .product-img .add-actions,
.shop-product-wrap .slide-item .single_product .product-img .add-actions, .hiraola-product_slider .slide-item .single_product .product-img .add-actions > ul li > a.hiraola-add_cart,
.hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions > ul li > a.hiraola-add_cart,
.hiraola-trending-product_slider .slide-item .single_product .product-img .add-actions > ul li > a.hiraola-add_cart,
.hiraola-product-tab_slider-2 .slide-item .single_product .product-img .add-actions > ul li > a.hiraola-add_cart,
.hiraola-product-tab_slider-3 .slide-item .single_product .product-img .add-actions > ul li > a.hiraola-add_cart,
.shop-product-wrap .slide-item .single_product .product-img .add-actions > ul li > a.hiraola-add_cart, .newsletter-form_wrap .subscribe-form .newsletter-btn, .modal-wrapper.modal, .modal-wrapper .modal-dialog .modal-content .modal-body .close, .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li, .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li .dropdown, .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li .dropdown > li, .header-top_area .ht-menu > ul > li .ht-dropdown, .header-bottom_area .main-menu_area > nav > ul > li .hm-dropdown, .header-bottom_area .main-menu_area > nav > ul > li > ul > li > a > i, .header-bottom_area .main-menu_area > nav > ul > li.megamenu-holder .hm-megamenu, .header-bottom_area .main-menu_area > nav > ul > li > a:before, .header-bottom_area .main-menu_area > nav > ul > li > a:after, #Instafeed > li > a, #Instafeed > li > a:before, #Instafeed > li > a:after, .shop-product-wrap > [class*="col-"], .sp-area .sp-nav .sp-content .qty-btn_area > ul li > a, .sp-gallery_area .sp-nav .sp-gallery .lg-image, .hiraola-sidebar-search-form .hiraola-search-btn i, .hiraola-blog-details .hiraola-tag-line a, .myaccount-tab-content, .login-form .check-box input[type="checkbox"] + label:before, .login-form .check-box input[type="checkbox"] + label:after, .hiraola-login_btn,
.hiraola-register_btn, .table-content table td.hiraola-cart_btn a, .coupon-all .coupon input.button,
.coupon-all .coupon2 input.button, .cart-page-total a, .coupon-accordion span, .coupon-accordion .coupon-content .coupon-info p.form-row input[type="submit"], .order-button-payment input, .compare-table .table tbody tr, .contact-main-page .contact-form-content .contact-form .form-group .alsita-contact-form_btn, .about-us-area .overview-content > h2:before, .error404-area .search-error-wrapper .hiraola-error_btn {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

/* ---Range Slider--- */
.price-filter {
  padding-top: 35px;
}

.price-filter .ui-widget-content {
  background-color: #e5e5e5;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  height: 10px;
  margin: 0 0 25px;
  width: 100%;
  border-top-left-radius: 25px;
  -moz-border-radius-topleft: 25px;
  border-top-right-radius: 25px;
  -moz-border-radius-topright: 25px;
  border-bottom-left-radius: 25px;
  -moz-border-radius-bottomleft: 25px;
  border-bottom-right-radius: 25px;
  -moz-border-radius-bottomright: 25px;
}

.price-filter .ui-slider-range {
  background: #333333;
  border: none;
  box-shadow: none;
  height: 100%;
}

.price-filter .ui-slider-handle {
  background: #cda557;
  border: none;
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  margin: 0;
  text-align: center;
  top: 50%;
  border-radius: 100%;
  box-shadow: 0px 0px 6.65px 0.35px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.price-filter .ui-slider-handle:last-child {
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}

.price-filter .ui-slider-handle:before {
  background: #cda557;
  content: '';
  display: inline-block;
  height: 5px;
  vertical-align: middle;
  width: 5px;
  border-radius: 100%;
}

.price-filter .price-slider-amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.price-filter .price-slider-amount .label-input label {
  color: #222;
  font-size: 14px;
  margin: 0;
  text-transform: capitalize;
}

.price-filter .price-slider-amount .label-input input {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  box-shadow: none;
  color: #222;
  font-size: 14px;
  height: inherit;
  padding-left: 5px;
  width: 99px;
}

/* ---Color List Area--- */
.color-list_area {
  border: 1px solid #e5e5e5;
  padding: 25px;
}

@media (max-width: 575px) {
  .color-list_area {
    padding: 15px;
  }
}

.color-list_area .color-list_heading {
  padding-bottom: 15px;
}

.color-list_area .color-list_heading > h4 {
  text-transform: uppercase;
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .color-list_area .color-list_heading > h4 {
    font-size: 14px;
  }
}

.color-list_area .sub-title {
  display: block;
  padding-bottom: 20px;
}

.color-list_area .color-list {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}

.color-list_area .color-list .single-color {
  border: 1px solid #e5e5e5;
  display: inline-block;
  margin-right: 5px;
  padding: 2px;
  width: 25px;
  height: 25px;
}

.color-list_area .color-list .single-color span {
  width: 100%;
  height: 100%;
}

.color-list_area .color-list .single-color span.bg-red_color {
  background-color: #ff0000;
  display: block;
}

.color-list_area .color-list .single-color span.burnt-orange_color {
  background-color: #ff832b;
  display: block;
}

.color-list_area .color-list .single-color span.brown_color {
  background-color: #a6311f;
  display: block;
}

.color-list_area .color-list .single-color span.raw-umber_color {
  background-color: #824900;
  display: block;
}

.color-list_area .color-list .single-color.active .color-text {
  color: #333333;
  display: block;
}

.color-list_area .color-list .color-text {
  position: absolute;
  right: -120px;
  width: auto !important;
  top: 0;
  display: none;
}

@media (max-width: 575px) {
  .color-list_area .color-list .color-text {
    right: -110px;
  }
}

/* ---Hiraola's Image Hover Effect--- */
.img-hover_effect {
  position: relative;
  display: block;
}

.img-hover_effect a {
  overflow: hidden;
  display: block;
}

.img-hover_effect a img {
  width: 100%;
  height: 100%;
}

.img-hover_effect a:before {
  background: #cda557;
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  right: 51%;
  top: 0;
  opacity: 0.6;
}

.img-hover_effect a:after {
  background: #cda557;
  bottom: 50%;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  opacity: 0.6;
}

.img-hover_effect:hover a:before {
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 900ms ease-in;
}

.img-hover_effect:hover a:after {
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: all 900ms ease-in;
}

/* ---Aista's Newsletters Popup--- */
.popup_wrapper {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
  height: 100%;
  opacity: 0;
  position: fixed;
  visibility: hidden;
  width: 100%;
  z-index: 9999999;
}

@media (max-width: 991px) {
  .popup_wrapper {
    display: none;
  }
}

.popup_wrapper .test {
  background: #ffffff;
  bottom: 0;
  height: 390px;
  left: 0;
  margin: auto;
  max-width: 1145px;
  padding: 50px 60px 50px;
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 1500px) {
  .popup_wrapper .test {
    max-width: 810px;
  }
}

.popup_wrapper .test .popup_off {
  background: #222222;
  color: #ffffff;
  top: -30px;
  right: 0;
  width: 30px;
  height: 30px;
  line-height: 29px;
  display: block;
  cursor: pointer;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
}

.popup_wrapper .test .popup_off > i {
  transform: rotate(0deg);
  display: block;
}

.popup_wrapper .test .popup_off:hover > i {
  transform: rotate(180deg);
}

.popup_wrapper .subscribe_area > h2 {
  color: #333333;
  font-weight: 700;
  font-size: 35px;
  text-transform: uppercase;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.popup_wrapper .subscribe_area > p {
  margin: 0 auto;
  max-width: 580px;
  padding-bottom: 25px;
}

.popup_wrapper .subscribe_area .subscribe-form-group {
  display: flex;
  justify-content: center;
}

.popup_wrapper .subscribe_area .subscribe-form-group form input {
  background: #ebebeb;
  border: 0 none;
  height: 40px;
  padding: 0 20px;
  width: 520px;
}

.popup_wrapper .subscribe_area .subscribe-form-group form button {
  background: #333333;
  color: #ffffff;
  padding: 0 40px;
  height: 40px;
  line-height: 40px;
  border: 0;
  display: block;
  margin: 30px auto 0;
  text-transform: uppercase;
}

.popup_wrapper .subscribe_area .subscribe-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}

.popup_wrapper .subscribe_area .subscribe-bottom input {
  margin-right: 5px;
}

.popup_wrapper .subscribe_area .subscribe-bottom label {
  margin-bottom: 0;
}

/*----------------------------------------*/
/*  04 - Utilities - Color
/*----------------------------------------*/
/* ---Backgroud Color | Only For HTML Markup---*/
.bg--white_smoke {
  background-color: #f4f4f4;
}

.bg--white {
  background-color: #ffffff;
}

.bg--night_rider {
  background-color: #595959;
}

.bg--silver {
  background-color: #bababa;
}

.bg--nero {
  background-color: #222222;
}

/* ---All Default Color Variation Included In This Area---↓ */
.template-color-1 {
  /* ---Aista's Newsletters Popup--- */
  /* ---Mobile Menu Wrapper--- */
  /* ---Offcanvas Navigation Area--- */
  /* ---Product Tab Menu Element Color--- */
  /* ---Hiraola's Tooltip--- */
  /* ---Shop Page--- */
  /* ---Preloader--- */
  /* ---Color List Area--- */
  /* ---Quick View Close Button Color--- */
  /* ---Single Product Page--- */
  /* ---Hiraola's Product Area Four--- */
  /* ---Hiraola's Product Area Five--- */
  /* ---Single Product Slider Area Border Color--- */
  /* ---Single Product Gallery Outline Color--- */
}

.template-color-1 a:hover {
  color: #cda557 !important;
}

.template-color-1 a.hiraola-banner_btn:hover {
  color: #ffffff !important;
}

.template-color-1 a.hiraola-btn_limerick:hover {
  color: #ffffff !important;
}

.template-color-1 a.hiraola-btn_dark:hover {
  background-color: #cda557;
  color: #ffffff !important;
}

.template-color-1 .popup_wrapper .test .popup_off:hover {
  background: #cda557;
}

.template-color-1 .popup_wrapper .subscribe_area .subscribe-form-group form button:hover {
  background: #cda557;
  color: #ffffff;
}

.template-color-1 .hb-menu > nav > ul > li:hover > a {
  color: #cda557 !important;
}

.template-color-1 .hb-menu > nav > ul > li > ul > li.active > a {
  color: #cda557 !important;
}

.template-color-1 .header-right_area > ul > li > a:hover {
  color: #ffffff !important;
}

.template-color-1 .header-top_area .ht-menu > ul > li:hover > a {
  color: #cda557;
}

.template-color-1 .header-top_area .ht-menu > ul > li .ht-dropdown > li.active a {
  color: #cda557;
}

.template-color-1 .header-top_area .ht-menu > ul > li .ht-dropdown > li:hover a {
  color: #cda557;
}

.template-color-1 .header-top_area .ht-right_area {
  display: flex;
  justify-content: flex-end;
}

.template-color-1 .header-top_area .ht-right_area .header-shipping_area {
  padding-right: 20px;
}

.template-color-1 .header-top_area .ht-right_area .header-shipping_area > ul > li {
  height: 40px;
  line-height: 40px;
}

.template-color-1 .header-top_area .ht-right_area .hiraola-social_link {
  padding-top: 0;
}

.template-color-1 .header-top_area .ht-right_area .hiraola-social_link > ul > li {
  padding-right: 0;
}

.template-color-1 .header-top_area .ht-right_area .hiraola-social_link > ul > li > a {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
}

.template-color-1 .header-top_area .ht-right_area .hiraola-social_link > ul > li:first-child > a {
  border-left: 1px solid #e5e5e5;
}

.template-color-1 .offcanvas-menu_wrapper .offcanvas-menu-inner .btn-close:hover {
  color: #cda557;
}

.template-color-1 .offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component_menu > li.active > a {
  color: #cda557;
}

.template-color-1 .offcanvas-minicart_wrapper .offcanvas-menu-inner .btn-close:hover {
  color: #cda557;
}

.template-color-1 .offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area {
  padding-bottom: 15px;
}

.template-color-1 .offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area .hiraola-btn:hover {
  background-color: #cda557;
}

.template-color-1 .offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > .search_btn:hover {
  color: #cda557;
}

.template-color-1 .mobile-menu_wrapper .btn-close:hover {
  background-color: #cda557;
  color: #ffffff !important;
}

.template-color-1 .offcanvas-navigation .mobile-menu li:hover > a {
  color: #cda557 !important;
}

.template-color-1 .offcanvas-navigation .mobile-menu li:hover > .menu-expand {
  color: #cda557 !important;
}

.template-color-1 .main-slider .slider-content .hiraola-btn:hover {
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
  color: #ffffff !important;
  background-color: #d5b473;
}

.template-color-1 .main-slider .slick-arrow {
  color: #cda557;
}

.template-color-1 .hiraola-slider_area-3 .main-slider .slider-content .hiraola-btn-ps_left .hiraola-btn {
  color: #ffffff;
}

.template-color-1 .hiraola-slider_area-3 .main-slider .slider-content .hiraola-btn-ps_left .hiraola-btn:hover {
  color: #ae307c !important;
}

.template-color-1 .hiraola-product_slider .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-1 .hiraola-product_slider-2 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-1 .hiraola-trending-product_slider .slide-item .single_product .product-img .add-actions > ul > li > a:hover {
  background-color: #cda557;
}

.template-color-1 .hiraola-product_slider .slick-arrow:hover,
.template-color-1 .hiraola-product_slider-2 .slick-arrow:hover,
.template-color-1 .hiraola-trending-product_slider .slick-arrow:hover {
  color: #cda557;
}

.template-color-1 .hiraola-product_slider .slide-item .single_product,
.template-color-1 .hiraola-product-tab_slider-2 .slide-item .single_product,
.template-color-1 .hiraola-product-tab_slider-3 .slide-item .single_product,
.template-color-1 .hiraola-product_slider-3 .slide-item .single_product,
.template-color-1 .shop-product-wrap .slide-item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-1 .hiraola-product_slider .slide-item .single_product:hover,
.template-color-1 .hiraola-product-tab_slider-2 .slide-item .single_product:hover,
.template-color-1 .hiraola-product-tab_slider-3 .slide-item .single_product:hover,
.template-color-1 .hiraola-product_slider-3 .slide-item .single_product:hover,
.template-color-1 .shop-product-wrap .slide-item .single_product:hover {
  border-color: #cda557;
}

.template-color-1 .hiraola-product_slider .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-1 .hiraola-product-tab_slider-2 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-1 .hiraola-product-tab_slider-3 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-1 .hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-1 .shop-product-wrap .slide-item .single_product .product-img .add-actions > ul > li > a:hover {
  background-color: #cda557;
}

.template-color-1 .hiraola-product_slider .list-slide_item .single_product,
.template-color-1 .hiraola-product-tab_slider-2 .list-slide_item .single_product,
.template-color-1 .hiraola-product-tab_slider-3 .list-slide_item .single_product,
.template-color-1 .hiraola-product_slider-3 .list-slide_item .single_product,
.template-color-1 .shop-product-wrap .list-slide_item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-1 .hiraola-product_slider .list-slide_item .single_product:hover,
.template-color-1 .hiraola-product-tab_slider-2 .list-slide_item .single_product:hover,
.template-color-1 .hiraola-product-tab_slider-3 .list-slide_item .single_product:hover,
.template-color-1 .hiraola-product_slider-3 .list-slide_item .single_product:hover,
.template-color-1 .shop-product-wrap .list-slide_item .single_product:hover {
  border-color: #cda557;
}

.template-color-1 .hiraola-product_slider .slick-arrow,
.template-color-1 .hiraola-product-tab_slider-2 .slick-arrow,
.template-color-1 .hiraola-product-tab_slider-3 .slick-arrow,
.template-color-1 .hiraola-product_slider-3 .slick-arrow,
.template-color-1 .shop-product-wrap .slick-arrow {
  color: #333333;
}

.template-color-1 .hiraola-product_slider .slick-arrow:hover,
.template-color-1 .hiraola-product-tab_slider-2 .slick-arrow:hover,
.template-color-1 .hiraola-product-tab_slider-3 .slick-arrow:hover,
.template-color-1 .hiraola-product_slider-3 .slick-arrow:hover,
.template-color-1 .shop-product-wrap .slick-arrow:hover {
  color: #cda557;
}

.template-color-1 .hiraola-testimonial_slider .slick-arrow:hover,
.template-color-1 .hiraola-brand_slider .slick-arrow:hover {
  color: #cda557;
}

.template-color-1 .product-tab > ul > li > a.active {
  color: #cda557;
}

.template-color-1 .rating-box > ul > li > i {
  color: #cda557;
}

.template-color-1 .widgets-essential_stuff > ul > li > i {
  color: #cda557;
}

.template-color-1 .newsletter-form_wrap .subscribe-form .newsletter-btn {
  background-color: #cda557;
}

.template-color-1 .hiraola-social_link > ul > li > a:hover {
  background-color: #cda557;
}

.template-color-1 .footer-bottom_area .footer-bottom_nav .copyright > span > a {
  color: #cda557;
}

.template-color-1 #scrollUp {
  background-color: #cda557;
  color: #ffffff;
}

.template-color-1 #scrollUp:hover {
  background-color: #333333;
  color: #ffffff !important;
}

.template-color-1 .tooltip-inner {
  background-color: #cda557;
}

.template-color-1 .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.template-color-1 .tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #cda557;
}

.template-color-1 .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.template-color-1 .bs-tooltip-bottom .arrow::before {
  border-bottom-color: #cda557;
}

.template-color-1 .shop-product-wrap.grid .slide-item .single_product > .product-img > .add-actions > ul > li > a:hover {
  background-color: #cda557;
}

.template-color-1 .loading .middle .lds-ellipsis div {
  background: #cda557;
}

.template-color-1 .color-list .single-color.active {
  border-color: #cda557;
}

.template-color-1 .modal-body .close:hover {
  color: #cda557;
}

.template-color-1 .sp-area .sp-nav .sp-content .sp-essential_stuff > ul > li > a {
  color: #cda557;
}

.template-color-1 .sp-area .sp-nav .sp-content .qty-btn_area > ul li > a:hover {
  border: 1px solid #cda557;
  color: #cda557;
}

.template-color-1 .sp-area .sp-nav .sp-content .qty-btn_area > ul li > a.qty-cart_btn {
  background-color: #333333;
  color: #ffffff;
  border: 0;
}

.template-color-1 .sp-area .sp-nav .sp-content .qty-btn_area > ul li > a.qty-cart_btn:hover {
  background-color: #cda557;
  color: #ffffff !important;
}

.template-color-1 .hiraola-product_area-4 .hiraola-product_slider-2 .slide-item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-1 .hiraola-product_area-4 .hiraola-product_slider-2 .slide-item .single_product:hover {
  border-color: #cda557;
}

.template-color-1 .hiraola-product_area-5 .hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions > ul > li > a:hover {
  background-color: #cda557;
}

.template-color-1 .hiraola-product_area-5 .hiraola-product_slider-3 .slick-arrow {
  color: #cda557;
}

.template-color-1 .sp-slider .slide-item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-1 .sp-slider .slide-item .single_product:hover {
  border-color: #cda557;
}

.template-color-1 .sp-slider .slick-arrow {
  color: #cda557;
}

.template-color-1 .sp-gallery_area .sp-nav .sp-gallery .lg-image {
  outline: 1px solid #e5e5e5;
}

.template-color-1 .sp-gallery_area .sp-nav .sp-gallery .lg-image:hover {
  outline: 1px solid #cda557;
}

.template-color-2 {
  /* ---Aista's Newsletters Popup--- */
  /* ---Mobile Menu Wrapper--- */
  /* ---Offcanvas Navigation Area--- */
  /* ---Product Tab Menu Element Color--- */
  /* ---Hiraola's Tooltip--- */
  /* ---Shop Page--- */
  /* ---Preloader--- */
  /* ---Color List Area--- */
  /* ---Quick View Close Button Color--- */
  /* ---Single Product Page--- */
  /* ---Hiraola's Product Area Four--- */
  /* ---Hiraola's Product Area Five--- */
  /* ---Single Product Slider Area Border Color--- */
  /* ---Single Product Gallery Outline Color--- */
}

.template-color-2 a:hover {
  color: #be8658 !important;
}

.template-color-2 a.hiraola-banner_btn:hover {
  color: #ffffff !important;
}

.template-color-2 a.hiraola-btn_limerick:hover {
  color: #ffffff !important;
}

.template-color-2 a.hiraola-btn_dark:hover {
  background-color: #be8658;
  color: #ffffff !important;
}

.template-color-2 .popup_wrapper .test .popup_off:hover {
  background: #be8658;
}

.template-color-2 .popup_wrapper .subscribe_area .subscribe-form-group form button:hover {
  background: #be8658;
  color: #ffffff;
}

.template-color-2 .hb-menu > nav > ul > li:hover > a {
  color: #be8658 !important;
}

.template-color-2 .hb-menu > nav > ul > li > ul > li.active > a {
  color: #be8658 !important;
}

.template-color-2 .header-right_area > ul > li > a:hover {
  color: #ffffff !important;
}

.template-color-2 .header-top_area .ht-menu > ul > li:hover > a {
  color: #be8658;
}

.template-color-2 .header-top_area .ht-menu > ul > li .ht-dropdown > li.active a {
  color: #be8658;
}

.template-color-2 .header-top_area .ht-menu > ul > li .ht-dropdown > li:hover a {
  color: #be8658;
}

.template-color-2 .header-top_area .ht-right_area {
  display: flex;
  justify-content: flex-end;
}

.template-color-2 .header-top_area .ht-right_area .header-shipping_area {
  padding-right: 20px;
}

.template-color-2 .header-top_area .ht-right_area .header-shipping_area > ul > li {
  height: 40px;
  line-height: 40px;
}

.template-color-2 .header-top_area .ht-right_area .hiraola-social_link {
  padding-top: 0;
}

.template-color-2 .header-top_area .ht-right_area .hiraola-social_link > ul > li {
  padding-right: 0;
}

.template-color-2 .header-top_area .ht-right_area .hiraola-social_link > ul > li > a {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
}

.template-color-2 .header-top_area .ht-right_area .hiraola-social_link > ul > li:first-child > a {
  border-left: 1px solid #e5e5e5;
}

.template-color-2 .offcanvas-menu_wrapper .offcanvas-menu-inner .btn-close:hover {
  color: #be8658;
}

.template-color-2 .offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component_menu > li.active > a {
  color: #be8658;
}

.template-color-2 .offcanvas-minicart_wrapper .offcanvas-menu-inner .btn-close:hover {
  color: #be8658;
}

.template-color-2 .offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area {
  padding-bottom: 15px;
}

.template-color-2 .offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area .hiraola-btn:hover {
  background-color: #be8658;
}

.template-color-2 .offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > .search_btn:hover {
  color: #be8658;
}

.template-color-2 .mobile-menu_wrapper .btn-close:hover {
  background-color: #be8658;
  color: #ffffff !important;
}

.template-color-2 .offcanvas-navigation .mobile-menu li:hover > a {
  color: #be8658 !important;
}

.template-color-2 .offcanvas-navigation .mobile-menu li:hover > .menu-expand {
  color: #be8658 !important;
}

.template-color-2 .main-slider .slider-content .hiraola-btn:hover {
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
  color: #ffffff !important;
  background-color: #d5b473;
}

.template-color-2 .main-slider .slick-arrow {
  color: #be8658;
}

.template-color-2 .hiraola-slider_area-3 .main-slider .slider-content .hiraola-btn-ps_left .hiraola-btn {
  color: #ffffff;
}

.template-color-2 .hiraola-slider_area-3 .main-slider .slider-content .hiraola-btn-ps_left .hiraola-btn:hover {
  color: #ae307c !important;
}

.template-color-2 .hiraola-product_slider .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-2 .hiraola-product_slider-2 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-2 .hiraola-trending-product_slider .slide-item .single_product .product-img .add-actions > ul > li > a:hover {
  background-color: #be8658;
}

.template-color-2 .hiraola-product_slider .slick-arrow:hover,
.template-color-2 .hiraola-product_slider-2 .slick-arrow:hover,
.template-color-2 .hiraola-trending-product_slider .slick-arrow:hover {
  color: #be8658;
}

.template-color-2 .hiraola-product_slider .slide-item .single_product,
.template-color-2 .hiraola-product-tab_slider-2 .slide-item .single_product,
.template-color-2 .hiraola-product-tab_slider-3 .slide-item .single_product,
.template-color-2 .hiraola-product_slider-3 .slide-item .single_product,
.template-color-2 .shop-product-wrap .slide-item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-2 .hiraola-product_slider .slide-item .single_product:hover,
.template-color-2 .hiraola-product-tab_slider-2 .slide-item .single_product:hover,
.template-color-2 .hiraola-product-tab_slider-3 .slide-item .single_product:hover,
.template-color-2 .hiraola-product_slider-3 .slide-item .single_product:hover,
.template-color-2 .shop-product-wrap .slide-item .single_product:hover {
  border-color: #be8658;
}

.template-color-2 .hiraola-product_slider .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-2 .hiraola-product-tab_slider-2 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-2 .hiraola-product-tab_slider-3 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-2 .hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-2 .shop-product-wrap .slide-item .single_product .product-img .add-actions > ul > li > a:hover {
  background-color: #be8658;
}

.template-color-2 .hiraola-product_slider .list-slide_item .single_product,
.template-color-2 .hiraola-product-tab_slider-2 .list-slide_item .single_product,
.template-color-2 .hiraola-product-tab_slider-3 .list-slide_item .single_product,
.template-color-2 .hiraola-product_slider-3 .list-slide_item .single_product,
.template-color-2 .shop-product-wrap .list-slide_item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-2 .hiraola-product_slider .list-slide_item .single_product:hover,
.template-color-2 .hiraola-product-tab_slider-2 .list-slide_item .single_product:hover,
.template-color-2 .hiraola-product-tab_slider-3 .list-slide_item .single_product:hover,
.template-color-2 .hiraola-product_slider-3 .list-slide_item .single_product:hover,
.template-color-2 .shop-product-wrap .list-slide_item .single_product:hover {
  border-color: #be8658;
}

.template-color-2 .hiraola-product_slider .slick-arrow,
.template-color-2 .hiraola-product-tab_slider-2 .slick-arrow,
.template-color-2 .hiraola-product-tab_slider-3 .slick-arrow,
.template-color-2 .hiraola-product_slider-3 .slick-arrow,
.template-color-2 .shop-product-wrap .slick-arrow {
  color: #333333;
}

.template-color-2 .hiraola-product_slider .slick-arrow:hover,
.template-color-2 .hiraola-product-tab_slider-2 .slick-arrow:hover,
.template-color-2 .hiraola-product-tab_slider-3 .slick-arrow:hover,
.template-color-2 .hiraola-product_slider-3 .slick-arrow:hover,
.template-color-2 .shop-product-wrap .slick-arrow:hover {
  color: #be8658;
}

.template-color-2 .hiraola-testimonial_slider .slick-arrow:hover,
.template-color-2 .hiraola-brand_slider .slick-arrow:hover {
  color: #be8658;
}

.template-color-2 .product-tab > ul > li > a.active {
  color: #be8658;
}

.template-color-2 .rating-box > ul > li > i {
  color: #be8658;
}

.template-color-2 .widgets-essential_stuff > ul > li > i {
  color: #be8658;
}

.template-color-2 .newsletter-form_wrap .subscribe-form .newsletter-btn {
  background-color: #be8658;
}

.template-color-2 .hiraola-social_link > ul > li > a:hover {
  background-color: #be8658;
}

.template-color-2 .footer-bottom_area .footer-bottom_nav .copyright > span > a {
  color: #be8658;
}

.template-color-2 #scrollUp {
  background-color: #be8658;
  color: #ffffff;
}

.template-color-2 #scrollUp:hover {
  background-color: #333333;
  color: #ffffff !important;
}

.template-color-2 .tooltip-inner {
  background-color: #be8658;
}

.template-color-2 .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.template-color-2 .tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #be8658;
}

.template-color-2 .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.template-color-2 .bs-tooltip-bottom .arrow::before {
  border-bottom-color: #be8658;
}

.template-color-2 .shop-product-wrap.grid .slide-item .single_product > .product-img > .add-actions > ul > li > a:hover {
  background-color: #be8658;
}

.template-color-2 .loading .middle .lds-ellipsis div {
  background: #be8658;
}

.template-color-2 .color-list .single-color.active {
  border-color: #be8658;
}

.template-color-2 .modal-body .close:hover {
  color: #be8658;
}

.template-color-2 .sp-area .sp-nav .sp-content .sp-essential_stuff > ul > li > a {
  color: #be8658;
}

.template-color-2 .sp-area .sp-nav .sp-content .qty-btn_area > ul li > a:hover {
  border: 1px solid #be8658;
  color: #cda557;
}

.template-color-2 .sp-area .sp-nav .sp-content .qty-btn_area > ul li > a.qty-cart_btn {
  background-color: #333333;
  color: #ffffff;
  border: 0;
}

.template-color-2 .sp-area .sp-nav .sp-content .qty-btn_area > ul li > a.qty-cart_btn:hover {
  background-color: #be8658;
  color: #ffffff !important;
}

.template-color-2 .hiraola-product_area-4 .hiraola-product_slider-2 .slide-item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-2 .hiraola-product_area-4 .hiraola-product_slider-2 .slide-item .single_product:hover {
  border-color: #be8658;
}

.template-color-2 .hiraola-product_area-5 .hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions > ul > li > a:hover {
  background-color: #be8658;
}

.template-color-2 .hiraola-product_area-5 .hiraola-product_slider-3 .slick-arrow {
  color: #be8658;
}

.template-color-2 .sp-slider .slide-item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-2 .sp-slider .slide-item .single_product:hover {
  border-color: #be8658;
}

.template-color-2 .sp-slider .slick-arrow {
  color: #be8658;
}

.template-color-2 .sp-gallery_area .sp-nav .sp-gallery .lg-image {
  outline: 1px solid #e5e5e5;
}

.template-color-2 .sp-gallery_area .sp-nav .sp-gallery .lg-image:hover {
  outline: 1px solid #be8658;
}

.template-color-3 {
  /* ---Aista's Newsletters Popup--- */
  /* ---Mobile Menu Wrapper--- */
  /* ---Offcanvas Navigation Area--- */
  /* ---Product Tab Menu Element Color--- */
  /* ---Hiraola's Tooltip--- */
  /* ---Shop Page--- */
  /* ---Preloader--- */
  /* ---Color List Area--- */
  /* ---Quick View Close Button Color--- */
  /* ---Single Product Page--- */
  /* ---Hiraola's Product Area Four--- */
  /* ---Hiraola's Product Area Five--- */
  /* ---Single Product Slider Area Border Color--- */
  /* ---Single Product Gallery Outline Color--- */
}

.template-color-3 a:hover {
  color: #ae307c !important;
}

.template-color-3 a.hiraola-banner_btn:hover {
  color: #ffffff !important;
}

.template-color-3 a.hiraola-btn_limerick:hover {
  color: #ffffff !important;
}

.template-color-3 a.hiraola-btn_dark:hover {
  background-color: #ae307c;
  color: #ffffff !important;
}

.template-color-3 .popup_wrapper .test .popup_off:hover {
  background: #ae307c;
}

.template-color-3 .popup_wrapper .subscribe_area .subscribe-form-group form button:hover {
  background: #ae307c;
  color: #ffffff;
}

.template-color-3 .hb-menu > nav > ul > li:hover > a {
  color: #ae307c !important;
}

.template-color-3 .hb-menu > nav > ul > li > ul > li.active > a {
  color: #ae307c !important;
}

.template-color-3 .header-right_area > ul > li > a:hover {
  color: #ffffff !important;
}

.template-color-3 .header-top_area .ht-menu > ul > li:hover > a {
  color: #ae307c;
}

.template-color-3 .header-top_area .ht-menu > ul > li .ht-dropdown > li.active a {
  color: #ae307c;
}

.template-color-3 .header-top_area .ht-menu > ul > li .ht-dropdown > li:hover a {
  color: #ae307c;
}

.template-color-3 .header-top_area .ht-right_area {
  display: flex;
  justify-content: flex-end;
}

.template-color-3 .header-top_area .ht-right_area .header-shipping_area {
  padding-right: 20px;
}

.template-color-3 .header-top_area .ht-right_area .header-shipping_area > ul > li {
  height: 40px;
  line-height: 40px;
}

.template-color-3 .header-top_area .ht-right_area .hiraola-social_link {
  padding-top: 0;
}

.template-color-3 .header-top_area .ht-right_area .hiraola-social_link > ul > li {
  padding-right: 0;
}

.template-color-3 .header-top_area .ht-right_area .hiraola-social_link > ul > li > a {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
}

.template-color-3 .header-top_area .ht-right_area .hiraola-social_link > ul > li:first-child > a {
  border-left: 1px solid #e5e5e5;
}

.template-color-3 .offcanvas-menu_wrapper .offcanvas-menu-inner .btn-close:hover {
  color: #ae307c;
}

.template-color-3 .offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component_menu > li.active > a {
  color: #ae307c;
}

.template-color-3 .offcanvas-minicart_wrapper .offcanvas-menu-inner .btn-close:hover {
  color: #ae307c;
}

.template-color-3 .offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area {
  padding-bottom: 15px;
}

.template-color-3 .offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area .hiraola-btn:hover {
  background-color: #ae307c;
}

.template-color-3 .offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > .search_btn:hover {
  color: #ae307c;
}

.template-color-3 .mobile-menu_wrapper .btn-close:hover {
  background-color: #ae307c;
  color: #ffffff !important;
}

.template-color-3 .offcanvas-navigation .mobile-menu li:hover > a {
  color: #ae307c !important;
}

.template-color-3 .offcanvas-navigation .mobile-menu li:hover > .menu-expand {
  color: #ae307c !important;
}

.template-color-3 .main-slider .slider-content .hiraola-btn:hover {
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
  color: #ffffff !important;
  background-color: #d5b473;
}

.template-color-3 .main-slider .slick-arrow {
  color: #ae307c;
}

.template-color-3 .hiraola-slider_area-3 .main-slider .slider-content .hiraola-btn-ps_left .hiraola-btn {
  color: #ffffff;
}

.template-color-3 .hiraola-slider_area-3 .main-slider .slider-content .hiraola-btn-ps_left .hiraola-btn:hover {
  color: #ae307c !important;
}

.template-color-3 .hiraola-product_slider .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-3 .hiraola-product_slider-2 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-3 .hiraola-trending-product_slider .slide-item .single_product .product-img .add-actions > ul > li > a:hover {
  background-color: #ae307c;
}

.template-color-3 .hiraola-product_slider .slick-arrow:hover,
.template-color-3 .hiraola-product_slider-2 .slick-arrow:hover,
.template-color-3 .hiraola-trending-product_slider .slick-arrow:hover {
  color: #ae307c;
}

.template-color-3 .hiraola-product_slider .slide-item .single_product,
.template-color-3 .hiraola-product-tab_slider-2 .slide-item .single_product,
.template-color-3 .hiraola-product-tab_slider-3 .slide-item .single_product,
.template-color-3 .hiraola-product_slider-3 .slide-item .single_product,
.template-color-3 .shop-product-wrap .slide-item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-3 .hiraola-product_slider .slide-item .single_product:hover,
.template-color-3 .hiraola-product-tab_slider-2 .slide-item .single_product:hover,
.template-color-3 .hiraola-product-tab_slider-3 .slide-item .single_product:hover,
.template-color-3 .hiraola-product_slider-3 .slide-item .single_product:hover,
.template-color-3 .shop-product-wrap .slide-item .single_product:hover {
  border-color: #ae307c;
}

.template-color-3 .hiraola-product_slider .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-3 .hiraola-product-tab_slider-2 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-3 .hiraola-product-tab_slider-3 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-3 .hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-3 .shop-product-wrap .slide-item .single_product .product-img .add-actions > ul > li > a:hover {
  background-color: #ae307c;
}

.template-color-3 .hiraola-product_slider .list-slide_item .single_product,
.template-color-3 .hiraola-product-tab_slider-2 .list-slide_item .single_product,
.template-color-3 .hiraola-product-tab_slider-3 .list-slide_item .single_product,
.template-color-3 .hiraola-product_slider-3 .list-slide_item .single_product,
.template-color-3 .shop-product-wrap .list-slide_item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-3 .hiraola-product_slider .list-slide_item .single_product:hover,
.template-color-3 .hiraola-product-tab_slider-2 .list-slide_item .single_product:hover,
.template-color-3 .hiraola-product-tab_slider-3 .list-slide_item .single_product:hover,
.template-color-3 .hiraola-product_slider-3 .list-slide_item .single_product:hover,
.template-color-3 .shop-product-wrap .list-slide_item .single_product:hover {
  border-color: #ae307c;
}

.template-color-3 .hiraola-product_slider .slick-arrow,
.template-color-3 .hiraola-product-tab_slider-2 .slick-arrow,
.template-color-3 .hiraola-product-tab_slider-3 .slick-arrow,
.template-color-3 .hiraola-product_slider-3 .slick-arrow,
.template-color-3 .shop-product-wrap .slick-arrow {
  color: #333333;
}

.template-color-3 .hiraola-product_slider .slick-arrow:hover,
.template-color-3 .hiraola-product-tab_slider-2 .slick-arrow:hover,
.template-color-3 .hiraola-product-tab_slider-3 .slick-arrow:hover,
.template-color-3 .hiraola-product_slider-3 .slick-arrow:hover,
.template-color-3 .shop-product-wrap .slick-arrow:hover {
  color: #ae307c;
}

.template-color-3 .hiraola-testimonial_slider .slick-arrow:hover,
.template-color-3 .hiraola-brand_slider .slick-arrow:hover {
  color: #ae307c;
}

.template-color-3 .product-tab > ul > li > a.active {
  color: #ae307c;
}

.template-color-3 .rating-box > ul > li > i {
  color: #ae307c;
}

.template-color-3 .widgets-essential_stuff > ul > li > i {
  color: #ae307c;
}

.template-color-3 .newsletter-form_wrap .subscribe-form .newsletter-btn {
  background-color: #ae307c;
}

.template-color-3 .hiraola-social_link > ul > li > a:hover {
  background-color: #ae307c;
}

.template-color-3 .footer-bottom_area .footer-bottom_nav .copyright > span > a {
  color: #ae307c;
}

.template-color-3 #scrollUp {
  background-color: #ae307c;
  color: #ffffff;
}

.template-color-3 #scrollUp:hover {
  background-color: #333333;
  color: #ffffff !important;
}

.template-color-3 .tooltip-inner {
  background-color: #ae307c;
}

.template-color-3 .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.template-color-3 .tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #ae307c;
}

.template-color-3 .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.template-color-3 .bs-tooltip-bottom .arrow::before {
  border-bottom-color: #ae307c;
}

.template-color-3 .shop-product-wrap.grid .slide-item .single_product > .product-img > .add-actions > ul > li > a:hover {
  background-color: #ae307c;
}

.template-color-3 .loading .middle .lds-ellipsis div {
  background: #ae307c;
}

.template-color-3 .color-list .single-color.active {
  border-color: #ae307c;
}

.template-color-3 .modal-body .close:hover {
  color: #ae307c;
}

.template-color-3 .sp-area .sp-nav .sp-content .sp-essential_stuff > ul > li > a {
  color: #ae307c;
}

.template-color-3 .sp-area .sp-nav .sp-content .qty-btn_area > ul li > a:hover {
  border: 1px solid #ae307c;
  color: #cda557;
}

.template-color-3 .sp-area .sp-nav .sp-content .qty-btn_area > ul li > a.qty-cart_btn {
  background-color: #333333;
  color: #ffffff;
  border: 0;
}

.template-color-3 .sp-area .sp-nav .sp-content .qty-btn_area > ul li > a.qty-cart_btn:hover {
  background-color: #ae307c;
  color: #ffffff !important;
}

.template-color-3 .hiraola-product_area-4 .hiraola-product_slider-2 .slide-item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-3 .hiraola-product_area-4 .hiraola-product_slider-2 .slide-item .single_product:hover {
  border-color: #ae307c;
}

.template-color-3 .hiraola-product_area-5 .hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions > ul > li > a:hover {
  background-color: #ae307c;
}

.template-color-3 .hiraola-product_area-5 .hiraola-product_slider-3 .slick-arrow {
  color: #ae307c;
}

.template-color-3 .sp-slider .slide-item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-3 .sp-slider .slide-item .single_product:hover {
  border-color: #ae307c;
}

.template-color-3 .sp-slider .slick-arrow {
  color: #ae307c;
}

.template-color-3 .sp-gallery_area .sp-nav .sp-gallery .lg-image {
  outline: 1px solid #e5e5e5;
}

.template-color-3 .sp-gallery_area .sp-nav .sp-gallery .lg-image:hover {
  outline: 1px solid #ae307c;
}

.template-color-4 {
  /* ---Aista's Newsletters Popup--- */
  /* ---Mobile Menu Wrapper--- */
  /* ---Offcanvas Navigation Area--- */
  /* ---Product Tab Menu Element Color--- */
  /* ---Hiraola's Tooltip--- */
  /* ---Shop Page--- */
  /* ---Preloader--- */
  /* ---Color List Area--- */
  /* ---Quick View Close Button Color--- */
  /* ---Single Product Page--- */
  /* ---Hiraola's Product Area Four--- */
  /* ---Hiraola's Product Area Five--- */
  /* ---Single Product Slider Area Border Color--- */
  /* ---Single Product Gallery Outline Color--- */
}

.template-color-4 a:hover {
  color: #58d173 !important;
}

.template-color-4 a.hiraola-banner_btn:hover {
  color: #ffffff !important;
}

.template-color-4 a.hiraola-btn_limerick:hover {
  color: #ffffff !important;
}

.template-color-4 a.hiraola-btn_dark:hover {
  background-color: #58d173;
  color: #ffffff !important;
}

.template-color-4 .popup_wrapper .test .popup_off:hover {
  background: #58d173;
}

.template-color-4 .popup_wrapper .subscribe_area .subscribe-form-group form button:hover {
  background: #58d173;
  color: #ffffff;
}

.template-color-4 .hb-menu > nav > ul > li:hover > a {
  color: #58d173 !important;
}

.template-color-4 .hb-menu > nav > ul > li > ul > li.active > a {
  color: #58d173 !important;
}

.template-color-4 .header-right_area > ul > li > a:hover {
  color: #ffffff !important;
}

.template-color-4 .header-top_area .ht-menu > ul > li:hover > a {
  color: #58d173;
}

.template-color-4 .header-top_area .ht-menu > ul > li .ht-dropdown > li.active a {
  color: #58d173;
}

.template-color-4 .header-top_area .ht-menu > ul > li .ht-dropdown > li:hover a {
  color: #58d173;
}

.template-color-4 .header-top_area .ht-right_area {
  display: flex;
  justify-content: flex-end;
}

.template-color-4 .header-top_area .ht-right_area .header-shipping_area {
  padding-right: 20px;
}

.template-color-4 .header-top_area .ht-right_area .header-shipping_area > ul > li {
  height: 40px;
  line-height: 40px;
}

.template-color-4 .header-top_area .ht-right_area .hiraola-social_link {
  padding-top: 0;
}

.template-color-4 .header-top_area .ht-right_area .hiraola-social_link > ul > li {
  padding-right: 0;
}

.template-color-4 .header-top_area .ht-right_area .hiraola-social_link > ul > li > a {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
}

.template-color-4 .header-top_area .ht-right_area .hiraola-social_link > ul > li:first-child > a {
  border-left: 1px solid #e5e5e5;
}

.template-color-4 .offcanvas-menu_wrapper .offcanvas-menu-inner .btn-close:hover {
  color: #58d173;
}

.template-color-4 .offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component_menu > li.active > a {
  color: #58d173;
}

.template-color-4 .offcanvas-minicart_wrapper .offcanvas-menu-inner .btn-close:hover {
  color: #58d173;
}

.template-color-4 .offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area {
  padding-bottom: 15px;
}

.template-color-4 .offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area .hiraola-btn:hover {
  background-color: #58d173;
}

.template-color-4 .offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > .search_btn:hover {
  color: #58d173;
}

.template-color-4 .mobile-menu_wrapper .btn-close:hover {
  background-color: #58d173;
  color: #ffffff !important;
}

.template-color-4 .offcanvas-navigation .mobile-menu li:hover > a {
  color: #58d173 !important;
}

.template-color-4 .offcanvas-navigation .mobile-menu li:hover > .menu-expand {
  color: #58d173 !important;
}

.template-color-4 .main-slider .slider-content .hiraola-btn:hover {
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
  color: #ffffff !important;
  background-color: #d5b473;
}

.template-color-4 .main-slider .slick-arrow {
  color: #58d173;
}

.template-color-4 .hiraola-slider_area-3 .main-slider .slider-content .hiraola-btn-ps_left .hiraola-btn {
  color: #ffffff;
}

.template-color-4 .hiraola-slider_area-3 .main-slider .slider-content .hiraola-btn-ps_left .hiraola-btn:hover {
  color: #ae307c !important;
}

.template-color-4 .hiraola-product_slider .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-4 .hiraola-product_slider-2 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-4 .hiraola-trending-product_slider .slide-item .single_product .product-img .add-actions > ul > li > a:hover {
  background-color: #58d173;
}

.template-color-4 .hiraola-product_slider .slick-arrow:hover,
.template-color-4 .hiraola-product_slider-2 .slick-arrow:hover,
.template-color-4 .hiraola-trending-product_slider .slick-arrow:hover {
  color: #58d173;
}

.template-color-4 .hiraola-product_slider .slide-item .single_product,
.template-color-4 .hiraola-product-tab_slider-2 .slide-item .single_product,
.template-color-4 .hiraola-product-tab_slider-3 .slide-item .single_product,
.template-color-4 .hiraola-product_slider-3 .slide-item .single_product,
.template-color-4 .shop-product-wrap .slide-item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-4 .hiraola-product_slider .slide-item .single_product:hover,
.template-color-4 .hiraola-product-tab_slider-2 .slide-item .single_product:hover,
.template-color-4 .hiraola-product-tab_slider-3 .slide-item .single_product:hover,
.template-color-4 .hiraola-product_slider-3 .slide-item .single_product:hover,
.template-color-4 .shop-product-wrap .slide-item .single_product:hover {
  border-color: #58d173;
}

.template-color-4 .hiraola-product_slider .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-4 .hiraola-product-tab_slider-2 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-4 .hiraola-product-tab_slider-3 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-4 .hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions > ul > li > a:hover,
.template-color-4 .shop-product-wrap .slide-item .single_product .product-img .add-actions > ul > li > a:hover {
  background-color: #58d173;
}

.template-color-4 .hiraola-product_slider .list-slide_item .single_product,
.template-color-4 .hiraola-product-tab_slider-2 .list-slide_item .single_product,
.template-color-4 .hiraola-product-tab_slider-3 .list-slide_item .single_product,
.template-color-4 .hiraola-product_slider-3 .list-slide_item .single_product,
.template-color-4 .shop-product-wrap .list-slide_item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-4 .hiraola-product_slider .list-slide_item .single_product:hover,
.template-color-4 .hiraola-product-tab_slider-2 .list-slide_item .single_product:hover,
.template-color-4 .hiraola-product-tab_slider-3 .list-slide_item .single_product:hover,
.template-color-4 .hiraola-product_slider-3 .list-slide_item .single_product:hover,
.template-color-4 .shop-product-wrap .list-slide_item .single_product:hover {
  border-color: #58d173;
}

.template-color-4 .hiraola-product_slider .slick-arrow,
.template-color-4 .hiraola-product-tab_slider-2 .slick-arrow,
.template-color-4 .hiraola-product-tab_slider-3 .slick-arrow,
.template-color-4 .hiraola-product_slider-3 .slick-arrow,
.template-color-4 .shop-product-wrap .slick-arrow {
  color: #333333;
}

.template-color-4 .hiraola-product_slider .slick-arrow:hover,
.template-color-4 .hiraola-product-tab_slider-2 .slick-arrow:hover,
.template-color-4 .hiraola-product-tab_slider-3 .slick-arrow:hover,
.template-color-4 .hiraola-product_slider-3 .slick-arrow:hover,
.template-color-4 .shop-product-wrap .slick-arrow:hover {
  color: #58d173;
}

.template-color-4 .hiraola-testimonial_slider .slick-arrow:hover,
.template-color-4 .hiraola-brand_slider .slick-arrow:hover {
  color: #58d173;
}

.template-color-4 .product-tab > ul > li > a.active {
  color: #58d173;
}

.template-color-4 .rating-box > ul > li > i {
  color: #58d173;
}

.template-color-4 .widgets-essential_stuff > ul > li > i {
  color: #58d173;
}

.template-color-4 .newsletter-form_wrap .subscribe-form .newsletter-btn {
  background-color: #58d173;
}

.template-color-4 .hiraola-social_link > ul > li > a:hover {
  background-color: #58d173;
}

.template-color-4 .footer-bottom_area .footer-bottom_nav .copyright > span > a {
  color: #58d173;
}

.template-color-4 #scrollUp {
  background-color: #58d173;
  color: #ffffff;
}

.template-color-4 #scrollUp:hover {
  background-color: #333333;
  color: #ffffff !important;
}

.template-color-4 .tooltip-inner {
  background-color: #58d173;
}

.template-color-4 .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.template-color-4 .tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #58d173;
}

.template-color-4 .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.template-color-4 .bs-tooltip-bottom .arrow::before {
  border-bottom-color: #58d173;
}

.template-color-4 .shop-product-wrap.grid .slide-item .single_product > .product-img > .add-actions > ul > li > a:hover {
  background-color: #58d173;
}

.template-color-4 .loading .middle .lds-ellipsis div {
  background: #58d173;
}

.template-color-4 .color-list .single-color.active {
  border-color: #58d173;
}

.template-color-4 .modal-body .close:hover {
  color: #58d173;
}

.template-color-4 .sp-area .sp-nav .sp-content .sp-essential_stuff > ul > li > a {
  color: #58d173;
}

.template-color-4 .sp-area .sp-nav .sp-content .qty-btn_area > ul li > a:hover {
  border: 1px solid #58d173;
  color: #cda557;
}

.template-color-4 .sp-area .sp-nav .sp-content .qty-btn_area > ul li > a.qty-cart_btn {
  background-color: #333333;
  color: #ffffff;
  border: 0;
}

.template-color-4 .sp-area .sp-nav .sp-content .qty-btn_area > ul li > a.qty-cart_btn:hover {
  background-color: #58d173;
  color: #ffffff !important;
}

.template-color-4 .hiraola-product_area-4 .hiraola-product_slider-2 .slide-item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-4 .hiraola-product_area-4 .hiraola-product_slider-2 .slide-item .single_product:hover {
  border-color: #58d173;
}

.template-color-4 .hiraola-product_area-5 .hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions > ul > li > a:hover {
  background-color: #58d173;
}

.template-color-4 .hiraola-product_area-5 .hiraola-product_slider-3 .slick-arrow {
  color: #58d173;
}

.template-color-4 .sp-slider .slide-item .single_product {
  border: 1px solid #e5e5e5;
}

.template-color-4 .sp-slider .slide-item .single_product:hover {
  border-color: #58d173;
}

.template-color-4 .sp-slider .slick-arrow {
  color: #58d173;
}

.template-color-4 .sp-gallery_area .sp-nav .sp-gallery .lg-image {
  outline: 1px solid #e5e5e5;
}

.template-color-4 .sp-gallery_area .sp-nav .sp-gallery .lg-image:hover {
  outline: 1px solid #58d173;
}

/*----------------------------------------*/
/*  10 - Components - Button
/*----------------------------------------*/
button.li-btn {
  border: none;
  background: #cda557;
  color: #ffffff;
  font-size: 18px;
  width: 45px;
  border-radius: 0 2px 2px 0;
  height: 45px;
  line-height: 45px;
  position: absolute;
  right: -1px;
  top: -1px;
  cursor: pointer;
}

button.li-btn:hover {
  background: #333333;
  color: #ffffff;
}

/* --Hiraola's Search Button-- */
.hiraola-search_btn {
  background: transparent;
  border: 0;
  position: absolute;
  right: 10px;
  top: 15px;
  color: #888888;
}

.hiraola-search_btn:hover {
  color: #cda557;
}

/* ---Hiraola's Button Position--- */
.hiraola-btn-ps_center {
  display: flex;
  justify-content: center;
}

.hiraola-btn-ps_center.hiraola-btn-ps_left {
  justify-content: flex-start;
}

.hiraola-btn-ps_center.hiraola-btn-ps_right {
  justify-content: flex-end;
}

/* --Hiraola's Button-- */
.hiraola-btn,
.hiraola-banner_btn,
.hiraola-btn-bondi_blue,
.hiraola-btn_fullwidth,
.hiraola-compare_btn,
.hiraola-filter_btn,
.hiraola-btn_dark,
.hiraola-btn_limerick {
  background-color: #cda557;
  color: #ffffff;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0);
  width: 195px;
  height: 45px;
  line-height: 45px;
  display: block;
  text-align: center;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .hiraola-btn,
  .hiraola-banner_btn,
  .hiraola-btn-bondi_blue,
  .hiraola-btn_fullwidth,
  .hiraola-compare_btn,
  .hiraola-filter_btn,
  .hiraola-btn_dark,
  .hiraola-btn_limerick {
    width: 140px;
    height: 50px;
    line-height: 55px;
    font-size: 14px;
  }
}

/* ---Hiraola's Button With Color Variation--- */
.hiraola-btn-bondi_blue {
  background-color: #cda557;
  color: #ffffff;
}

.hiraola-btn-bondi_blue:hover {
  background-color: #595959;
  color: #ffffff !important;
}

.hiraola-btn_dark {
  background: #595959;
  color: #ffffff;
  width: 150px;
  height: 40px;
  line-height: 38px;
  font-weight: 600;
  border: 0;
}

.hiraola-btn_limerick {
  background-color: #cda557;
  color: #ffffff;
  width: 120px;
  height: 40px;
  line-height: 40px;
}

.hiraola-btn_limerick:hover {
  background-color: #333333;
}

/* ---Hiraola's Button With Various Sizes--- */
.hiraola-btn_fullwidth {
  background-color: #666666;
  width: 100%;
  color: #ffffff;
  text-transform: uppercase;
}

.hiraola-btn_sm {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  font-weight: 400;
}

/* ---Hiraola's Other Button--- */
.hiraola-banner_btn {
  box-shadow: 0px 1px 6.79px 0.21px rgba(0, 0, 0, 0.13);
  height: 40px;
  line-height: 40px;
}

.hiraola-compare_btn {
  background: #cda557;
  color: #ffffff;
  height: 40px;
  line-height: 43px;
  font-size: 14px;
}

.hiraola-compare_btn:hover {
  background-color: #595959;
}

.fb-filter-btn_area {
  padding-top: 30px;
}

.fb-filter-btn_area .hiraola-filter_btn {
  background-color: #595959;
  color: #ffffff;
  display: block;
  width: 120px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0;
}

.fb-filter-btn_area .hiraola-filter_btn:before {
  content: "\f00d";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  padding-right: 10px;
}

.fb-filter-btn_area .hiraola-filter_btn:hover {
  background-color: #cda557;
}

/*----------------------------------------*/
/*  11 - Components - Slider
/*----------------------------------------*/
/* ---Slider With Category Menu--- */
.slider-with-category_menu {
  padding-top: 30px;
}

.slider-with-category_menu .container-fluid {
  padding: 0 3%;
}

.slider-with-category_menu .banner-item {
  height: 100%;
  border: 1px solid #e5e5e5;
}

.slider-with-category_menu .banner-item a {
  display: block;
  height: 100%;
}

/* --Slider Area One--- */
.hiraola-slider_area .main-slider .slider-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5%;
}

.hiraola-slider_area .main-slider .slider-content > h5 {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0;
  padding-bottom: 12px;
}

.hiraola-slider_area .main-slider .slider-content > h5 > span {
  color: #cda557;
}

.hiraola-slider_area .main-slider .slider-content > h2 {
  text-transform: capitalize;
  margin-bottom: 0;
  padding-bottom: 10px;
  font-size: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hiraola-slider_area .main-slider .slider-content > h2 {
    font-size: 35px;
  }
}

@media (max-width: 767px) {
  .hiraola-slider_area .main-slider .slider-content > h2 {
    font-size: 30px;
  }
}

.hiraola-slider_area .main-slider .slider-content > h3 {
  text-transform: capitalize;
  margin-bottom: 0;
  padding-bottom: 20px;
  font-size: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hiraola-slider_area .main-slider .slider-content > h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .hiraola-slider_area .main-slider .slider-content > h3 {
    font-size: 28px;
  }
}

.hiraola-slider_area .main-slider .slider-content > h4 {
  margin-bottom: 0;
  padding-bottom: 45px;
  font-size: 20px;
}

.hiraola-slider_area .main-slider .slider-content > h4 > span {
  font-size: 30px;
  color: #cda557;
}

.hiraola-slider_area .main-slider .slick-arrow {
  display: none !important;
}

/* ---Slider Area Two--- */
.hiraola-slider_area-2 .main-slider .slider-content {
  max-width: 52%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: auto;
  transform: translateY(-50%);
  z-index: 8;
  text-align: left;
}

@media (max-width: 479px) {
  .hiraola-slider_area-2 .main-slider .slider-content {
    max-width: 100%;
  }
}

.hiraola-slider_area-2 .main-slider .slider-content > h5 {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0;
  padding-bottom: 7px;
}

.hiraola-slider_area-2 .main-slider .slider-content > h5 > span {
  color: #cda557;
}

@media (max-width: 767px) {
  .hiraola-slider_area-2 .main-slider .slider-content > h5 {
    padding-bottom: 10px;
  }
}

.hiraola-slider_area-2 .main-slider .slider-content > h2 {
  text-transform: capitalize;
  margin-bottom: 0;
  padding-bottom: 5px;
  font-size: 60px;
}

@media (max-width: 767px) {
  .hiraola-slider_area-2 .main-slider .slider-content > h2 {
    padding-bottom: 10px;
    font-size: 30px;
  }
}

.hiraola-slider_area-2 .main-slider .slider-content > h3 {
  text-transform: capitalize;
  margin-bottom: 0;
  padding-bottom: 20px;
  font-size: 50px;
}

@media (max-width: 767px) {
  .hiraola-slider_area-2 .main-slider .slider-content > h3 {
    font-size: 30px;
  }
}

.hiraola-slider_area-2 .main-slider .slider-content > h4 {
  margin-bottom: 0;
  padding-bottom: 45px;
  font-size: 20px;
}

.hiraola-slider_area-2 .main-slider .slider-content > h4 > span {
  font-size: 30px;
  color: #cda557;
}

.hiraola-slider_area-2 .main-slider .slider-content .hiraola-btn-ps_center {
  display: flex;
  justify-content: flex-start;
}

/* --Slider Background Image-- */
.bg-1,
.bg-2,
.bg-3 {
  background-image: url("../images/slider/1.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 520px;
}

@media (max-width: 479px) {
  .bg-1,
  .bg-2,
  .bg-3 {
    min-height: 380px;
  }
}

.bg-2 {
  background-image: url("../images/slider/2.jpg");
}

.bg-3 {
  background-image: url("../images/slider/3.jpg");
}

.bg-4,
.bg-5 {
  background-image: url("../images/slider/4.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 825px;
}

@media (max-width: 1199px) {
  .bg-4,
  .bg-5 {
    min-height: 520px;
  }
}

@media (max-width: 575px) {
  .bg-4,
  .bg-5 {
    min-height: 410px;
  }
}

.bg-5 {
  background-image: url("../images/slider/5.jpg");
}

/* ---Hiraola's Slider Progress Bar--- */
.slider-progress {
  -webkit-animation: initial;
  animation: initial;
  background: rgba(0, 0, 0, 0.3);
  height: 5px;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  z-index: 4;
}

.slick-current .slider-progress {
  -webkit-animation: timebar 8s ease-in-out 0s 1 backwards;
  animation: timebar 8s ease-in-out 0s 1 backwards;
}

@-webkit-keyframes timebar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes timebar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* Slider Pagination  */
.main-slider .slick-arrow,
.hiraola-product-tab_slider-2 .slick-arrow,
.hiraola-product_slider-3 .slick-arrow,
.sp-img_slider .slick-arrow,
.sp-slider .slick-arrow,
.sp-img_slider-3 .slick-arrow {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 9.8px 0.2px rgba(0, 0, 0, 0.05);
  font-size: 24px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  color: #cda557;
  text-align: center;
  position: absolute;
  z-index: 8;
  cursor: pointer;
  border-radius: 100%;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  left: 20px;
}

@media (max-width: 767px) {
  .main-slider .slick-arrow,
  .hiraola-product-tab_slider-2 .slick-arrow,
  .hiraola-product_slider-3 .slick-arrow,
  .sp-img_slider .slick-arrow,
  .sp-slider .slick-arrow,
  .sp-img_slider-3 .slick-arrow {
    display: none !important;
  }
}

.main-slider .slick-arrow:hover,
.hiraola-product-tab_slider-2 .slick-arrow:hover,
.hiraola-product_slider-3 .slick-arrow:hover,
.sp-img_slider .slick-arrow:hover,
.sp-slider .slick-arrow:hover,
.sp-img_slider-3 .slick-arrow:hover {
  background-color: #f0f0f0;
}

.main-slider .slick-next,
.hiraola-product-tab_slider-2 .slick-next,
.hiraola-product_slider-3 .slick-next,
.sp-img_slider .slick-next,
.sp-slider .slick-next,
.sp-img_slider-3 .slick-next {
  left: auto;
  right: 20px;
}

.main-slider:hover .slick-arrow,
.hiraola-product-tab_slider-2:hover .slick-arrow,
.hiraola-product_slider-3:hover .slick-arrow,
.sp-img_slider:hover .slick-arrow,
.sp-slider:hover .slick-arrow,
.sp-img_slider-3:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.hiraola-product_slider .slick-arrow,
.hiraola-product-tab_slider-3 .slick-arrow {
  font-size: 24px;
  display: block;
  text-align: center;
  position: absolute;
  z-index: 8;
  cursor: pointer;
  top: -80px;
  left: auto;
  right: 30px;
}

.hiraola-product_slider .slick-arrow:hover,
.hiraola-product-tab_slider-3 .slick-arrow:hover {
  color: #cda557;
}

.hiraola-product_slider .slick-arrow.slick-next,
.hiraola-product-tab_slider-3 .slick-arrow.slick-next {
  right: 0;
}

@media (max-width: 767px) {
  .hiraola-product_slider .slick-arrow,
  .hiraola-product-tab_slider-3 .slick-arrow {
    display: none !important;
  }
}

/* ---Single Product Image Slider--- */
.sp-img_slider .slick-arrow {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 18px;
  left: -10px;
  right: auto;
}

.sp-img_slider .slick-arrow.slick-next {
  right: -10px;
  left: auto;
}

/* ---Single Porduct Image Slider Two--- */
.sp-img_slider-2 {
  border: 1px solid #e5e5e5;
}

.sp-img_slider-nav {
  padding: 0 30px;
  margin-top: 30px;
}

.sp-img_slider-nav .slick-list {
  margin: 0 -15px;
}

.sp-img_slider-nav .slick-list .single-slide {
  border: 1px solid #e5e5e5;
  margin: 0 15px;
}

/* ---Single Porduct Image Slider Three--- */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sp-img_slider-3 {
    padding: 60px 0;
  }
}

@media (max-width: 767px) {
  .sp-img_slider-3 {
    flex-basis: 30% !important;
    width: 30% !important;
  }
}

@media (max-width: 479px) {
  .sp-img_slider-3 {
    flex-basis: 40% !important;
    width: 40% !important;
  }
}

.sp-img_slider-3 .slick-arrow {
  top: 0;
  bottom: auto;
  width: 35px;
  height: 35px;
  line-height: 35px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 991px) {
  .sp-img_slider-3 .slick-arrow.slick-prev {
    top: 30px;
  }
}

.sp-img_slider-3 .slick-arrow.slick-next {
  top: auto;
  bottom: 0;
}

@media (max-width: 991px) {
  .sp-img_slider-3 .slick-arrow.slick-next {
    bottom: 60px;
  }
}

@media (max-width: 991px) {
  .sp-img_slider-3 .slick-list {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sp-img_slider-3 .slick-list .slick-slide {
    margin-left: 30px;
    margin-right: 30px;
  }
}

/* ---Slider Dots--- */
.main-slider .slick-dots {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 30px;
  z-index: 8;
}

.main-slider .slick-dots li {
  display: inline-block;
  margin: 0 3px;
  cursor: pointer;
  font-size: 0;
  width: 15px;
  height: 15px;
  background: transparent;
  border: 2px solid #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.main-slider .slick-dots li button {
  border: none;
}

.main-slider .slick-dots li.slick-active {
  background-color: #ffffff;
}

.main-slider .slick-dots li:hover {
  background-color: #ffffff;
}

/*----------------------------------------*/
/*  12 - Components - Animation
/*----------------------------------------*/
/* Hiraola's Animation Style One */
.slick-active.animation-style-01 .slider-content > span {
  display: block;
  -webkit-animation: 1200ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 1200ms ease-in-out 0s normal none 1 running zoomInUp;
}

.slick-active.animation-style-01 .slider-content > h2 {
  -webkit-animation: 1400ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 1400ms ease-in-out 0s normal none 1 running zoomInUp;
}

.slick-active.animation-style-01 .slider-content > h3 {
  -webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
}

.slick-active.animation-style-01 .slider-content > h4 {
  -webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
}

.slick-active.animation-style-01 .slider-content > h5 {
  -webkit-animation: 1800ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 1800ms ease-in-out 0s normal none 1 running zoomInUp;
}

.slick-active.animation-style-01 .slider-content > .slide-btn {
  -webkit-animation: 2000ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 2000ms ease-in-out 0s normal none 1 running zoomInUp;
}

.slick-active.animation-style-02 .slider-content > span {
  display: block;
  -webkit-animation: 1200ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 1200ms ease-in-out 0s normal none 1 running zoomInRight;
}

.slick-active.animation-style-02 .slider-content > h2 {
  -webkit-animation: 1400ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 1400ms ease-in-out 0s normal none 1 running zoomInRight;
}

.slick-active.animation-style-02 .slider-content > h3 {
  -webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 1600ms ease-in-out 0s normal none 1 running zoomInRight;
}

.slick-active.animation-style-02 .slider-content > h4 {
  -webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 1600ms ease-in-out 0s normal none 1 running zoomInRight;
}

.slick-active.animation-style-02 .slider-content > h5 {
  -webkit-animation: 1800ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 1800ms ease-in-out 0s normal none 1 running zoomInRight;
}

.slick-active.animation-style-02 .slider-content > .slide-btn {
  -webkit-animation: 2000ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 2000ms ease-in-out 0s normal none 1 running zoomInRight;
}

/*Top Up Keyframes*/
@-webkit-keyframes alisSlideInUp {
  0% {
    -webkit-transform: translateY(250px);
    transform: translateY(250px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes alisSlideInUp {
  0% {
    -webkit-transform: translateY(250px);
    transform: translateY(250px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 4s linear infinite alternate both;
  animation: slide-in-right 4s linear infinite alternate both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-1-7 17:0:13
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(340px);
    transform: translateX(340px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(340px);
    transform: translateX(340px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

/*----------------------------------------*/
/*  13 - Components - Navigation
/*----------------------------------------*/
/* ---Mobile Menu Wrapper--- */
.mobile-menu_wrapper .offcanvas-menu-inner,
.offcanvas-minicart_wrapper .offcanvas-menu-inner,
.offcanvas-search_wrapper .offcanvas-menu-inner {
  position: fixed;
  top: 0;
  right: -285px;
  width: 285px;
  height: 100%;
  background: #ffffff;
  z-index: 9999;
  visibility: hidden;
}

@media (max-width: 575px) {
  .mobile-menu_wrapper .offcanvas-menu-inner,
  .offcanvas-minicart_wrapper .offcanvas-menu-inner,
  .offcanvas-search_wrapper .offcanvas-menu-inner {
    width: 270px;
  }
}

.mobile-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_search,
.offcanvas-minicart_wrapper .offcanvas-menu-inner .offcanvas-inner_search,
.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-inner_search {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: calc(100% - 0px);
  padding: 10px;
  background-color: #e5e5e5;
}

.mobile-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_search .hm-searchbox,
.offcanvas-minicart_wrapper .offcanvas-menu-inner .offcanvas-inner_search .hm-searchbox,
.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-inner_search .hm-searchbox {
  position: relative;
}

.mobile-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_search .hm-searchbox > input,
.offcanvas-minicart_wrapper .offcanvas-menu-inner .offcanvas-inner_search .hm-searchbox > input,
.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-inner_search .hm-searchbox > input {
  background-color: #e5e5e5;
  border: 0;
  height: 40px;
  line-height: 40px;
  width: 100%;
  padding: 0 52px 0 15px;
}

.mobile-menu_wrapper .offcanvas-menu-inner .offcanvas-inner_search .hm-searchbox > .search_btn,
.offcanvas-minicart_wrapper .offcanvas-menu-inner .offcanvas-inner_search .hm-searchbox > .search_btn,
.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-inner_search .hm-searchbox > .search_btn {
  background: transparent;
  color: #595959;
  position: absolute;
  top: 10px;
  right: 20px;
  border: 0;
  font-size: 24px;
}

.mobile-menu_wrapper.open .offcanvas-menu-inner,
.offcanvas-minicart_wrapper.open .offcanvas-menu-inner,
.offcanvas-search_wrapper.open .offcanvas-menu-inner {
  left: auto;
  right: 0;
  visibility: visible;
  padding: 105px 0 0;
}

/* ---Offcanvas Close Button--- */
.btn-close {
  position: absolute;
  top: 0;
  left: -60px;
  background: #333333;
  width: 60px;
  height: 60px;
  line-height: 63px;
  text-align: center;
  color: #ffffff;
  z-index: 10;
  font-size: 24px;
}

@media (max-width: 479px) {
  .btn-close {
    left: -50px;
    width: 50px;
  }
}

.btn-close:hover > i {
  transform: rotate(90deg);
}

.btn-close > i {
  transform: rotate(0);
  display: block;
}

/* ---Offcanvas Navigation Area--- */
.offcanvas-navigation .mobile-menu {
  overflow-y: auto;
  min-height: 165px;
}

.offcanvas-navigation .mobile-menu > li {
  height: 100%;
}

.offcanvas-navigation .mobile-menu > li > a span {
  position: relative;
  font-weight: 600;
}

.offcanvas-navigation .mobile-menu li {
  position: relative;
}

.offcanvas-navigation .mobile-menu li > .menu-expand {
  position: absolute;
  right: 0;
  top: 2px;
  width: 50px;
  height: 100%;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  margin-right: 4px;
}

.offcanvas-navigation .mobile-menu li a {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  padding: 10px 20px;
}

.offcanvas-navigation .mobile-menu li .sub-menu {
  padding-left: 10px;
}

.offcanvas-navigation .mobile-menu li .sub-menu li a {
  text-transform: capitalize;
  font-size: 13px;
}

.offcanvas-navigation .mobile-menu li.menu-open > .menu-expand i:before {
  content: '\f068';
}

/* ---Header User Setting Area--- */
.user-setting_area {
  padding-top: 25px;
}

.user-setting_area > ul > li > a > span {
  text-transform: capitalize;
}

/* ---Offcanvas Menu Wrapper--- */
.offcanvas-menu_wrapper {
  text-align: center;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner {
  padding: 25px 30px 30px;
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  transform: scaleY(0);
  transform-origin: 0 0 0;
  width: 375px;
  height: 100%;
  background: #ffffff;
  z-index: 9999;
  visibility: hidden;
  min-height: 600px;
  overflow-y: auto;
  /* ---Offcanvas Component--- */
  /* ---Offcanvas Inner Social Link--- */
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .btn-close {
  top: auto;
  left: auto;
  width: auto;
  height: auto;
  line-height: 1;
  background: transparent;
  color: #595959;
  z-index: 10;
  font-size: 24px;
  position: relative;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .btn-close:hover {
  background: transparent;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component {
  text-align: left;
  padding-top: 20px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component.first-child {
  padding-top: 25px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component .offcanvas-component_title {
  font-size: 16px;
  font-weight: 900;
  display: block;
  padding-bottom: 15px;
  text-transform: uppercase;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component .offcanvas-component_menu > li {
  display: inline-block;
  position: relative;
  padding-right: 40px;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component .offcanvas-component_menu > li:last-child {
  padding-right: 0;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component .offcanvas-component_menu > li:after {
  content: "";
  width: 18px;
  height: 1px;
  background: #e5e5e5;
  display: block;
  top: 50%;
  right: 10px;
  position: absolute;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-component .offcanvas-component_menu > li:last-child:after {
  background: transparent;
}

.offcanvas-menu_wrapper .offcanvas-menu-inner .offcanvas-inner-social_link {
  position: absolute;
  bottom: 30px;
}

.offcanvas-menu_wrapper.open .offcanvas-menu-inner {
  top: 0;
  visibility: visible;
  transform: scaleY(1);
}

.offcanvas-menu_wrapper .offcanvas-inner_logo {
  padding: 25px 0;
}

.offcanvas-menu_wrapper .short-desc {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 25px;
}

.offcanvas-menu_wrapper .short-desc > p {
  margin-bottom: 0;
}

/* ---Offcanvas Minicart Area--- */
.offcanvas-minicart_wrapper .offcanvas-menu-inner {
  padding: 60px !important;
  width: 450px;
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 479px) {
  .offcanvas-minicart_wrapper .offcanvas-menu-inner {
    width: 100%;
    padding: 60px 15px !important;
  }
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .btn-close {
  background: transparent;
  color: #595959;
  top: 0;
  right: 0;
  left: auto;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-heading > h4 {
  margin-bottom: 0;
  padding-bottom: 25px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list {
  max-height: 310px;
  position: relative;
  overflow: auto;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li {
  padding-bottom: 30px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li:last-child {
  padding-bottom: 0;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product {
  display: flex;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product > a.product-item_remove {
  position: absolute;
  right: 15px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_img {
  -webkit-flex-basis: 70px;
  -moz-flex-basis: 70px;
  -ms-flex-preferred-size: 70px;
  flex-basis: 70px;
  max-width: 70px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content {
  -webkit-flex-basis: calc(100% - 70px);
  -moz-flex-basis: calc(100% - 70px);
  -ms-flex-preferred-size: calc(100% - 70px);
  flex-basis: calc(100% - 70px);
  max-width: calc(100% - 70px);
  padding-left: 20px;
  padding-right: 10px;
}

@media (max-width: 479px) {
  .offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content a.product-item_title {
    font-size: 14px;
  }
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content .product-item_quantity {
  display: block;
  padding-top: 10px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-item_total {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  padding: 25px 0;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area {
  padding-bottom: 15px;
}

.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area .hiraola-btn {
  height: 60px;
  line-height: 60px;
  color: #ffffff !important;
}

/* ---Offcanvas Search Area--- */
.offcanvas-search_wrapper .offcanvas-menu-inner {
  background-color: #222222;
  opacity: 0.97;
  width: 100%;
  right: 0;
  transform: scale(0);
  visibility: visible;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .btn-close {
  background: transparent;
  color: #595959;
  top: 15px;
  right: 15px;
  left: auto;
  font-size: 45px;
  color: #ffffff;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search {
  background-color: #e5e5e5;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  width: calc(100% - 25%);
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox {
  position: relative;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > input {
  background-color: #333333;
  border: 0;
  height: 100px;
  line-height: 100px;
  width: 100%;
  padding: 0 100px 0 25px;
  color: #ffffff;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > input::placeholder {
  color: #ffffff;
}

.offcanvas-search_wrapper .offcanvas-menu-inner .offcanvas-search .hm-searchbox > .search_btn {
  position: absolute;
  font-size: 48px;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  border: 0;
  background: transparent;
  color: #ffffff;
}

.offcanvas-search_wrapper.open .offcanvas-menu-inner {
  left: auto;
  right: 0;
  padding: 0;
  transform: scale(1);
  visibility: visible;
}

/* ---Category Menu--- */
.category-menu {
  position: relative;
}

@media (max-width: 991px) {
  .category-menu {
    width: 100%;
  }
}

.category-menu .category-heading {
  background: #cda557;
  position: relative;
  cursor: pointer;
  padding: 19px 0;
}

.category-menu .category-heading > h2 {
  margin: 0;
  color: #ffffff;
  padding: 0;
  text-transform: uppercase;
  font-size: 16px;
}

@media (max-width: 1199px) {
  .category-menu .category-heading > h2 {
    font-size: 14px;
  }
}

.category-menu .category-heading > h2:after {
  content: "\f03c";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  position: absolute;
  top: 18px;
  left: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-menu .category-heading > h2:after {
    left: 10px;
  }
}

.category-menu .category-heading > h2 > span {
  color: #ffffff;
  padding-left: 50px;
  font-weight: 600;
}

@media (max-width: 1199px) {
  .category-menu .category-heading > h2 > span {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-menu .category-heading > h2 > span {
    padding-left: 30px;
  }
}

.category-menu .category-menu-list {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  padding: 0 20px;
  position: absolute;
  left: 0;
  top: calc(100% - 1px);
  display: block;
  width: 100%;
  z-index: 3;
}

.category-menu .category-menu-list > ul > li {
  position: relative;
}

.category-menu .category-menu-list > ul > li > a {
  display: block;
  text-transform: capitalize;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  padding: 11.2px 0;
}

.category-menu .category-menu-list > ul > li:hover .cat-mega-menu {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  visibility: visible;
}

.category-menu .category-menu-list > ul > li:hover > a {
  color: #cda557;
}

.category-menu .category-menu-list > ul > li:last-child > a:before {
  display: none;
}

.category-menu .category-menu-list > ul > li.right-menu > a::after {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  font-size: 12px;
  position: absolute;
  top: 15px;
  right: 0;
}

.category-menu .category-menu-list > ul > li.right-menu:hover > a::after {
  transform: rotate(-90deg);
}

.category-menu .category-menu-list > ul > li.rx-child {
  display: none;
}

.category-menu .category-menu-list > ul > li.rx-parent {
  cursor: pointer;
  border-top: 1px solid #e5e5e5;
}

.category-menu .category-menu-list > ul > li.rx-parent a.rx-show {
  display: none;
}

.category-menu .category-menu-list > ul > li.rx-parent.rx-change a.rx-default {
  display: none;
}

.category-menu .category-menu-list > ul > li.rx-parent.rx-change a.rx-show {
  display: block;
}

.cat-mega-menu {
  position: absolute;
  top: 0;
  left: 108%;
  background: #ffffff;
  z-index: 999;
  width: 220px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  visibility: hidden;
  width: 640px;
  padding: 30px;
  overflow: hidden;
  border-top: 2px solid #cda557;
}

@media (max-width: 991px) {
  .cat-mega-menu {
    position: static;
    z-index: 9;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    border: 0;
    width: 100%;
    -webkit-transition: none;
    transition: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cat-mega-menu {
    left: 114%;
  }
}

.cat-mega-menu > li.right-menu {
  width: 33.333%;
  float: left;
}

.cat-mega-menu > li.cat-mega-title > a {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 15px;
  display: block;
  line-height: 20px;
  position: relative;
}

.cat-mega-menu > li.cat-mega-title > a:hover {
  color: #ffffff;
}

.cat-mega-menu > li > ul > li > a {
  font-size: 14px;
  display: block;
  line-height: 30px;
  font-weight: 400;
  color: #666;
  text-transform: capitalize;
}

.cat-mega-menu > li > ul > li > a:hover {
  color: #58d173;
}

.cat-mega-menu.cat-mega-menu-2 {
  width: 840px;
}

@media (max-width: 991px) {
  .cat-mega-menu.cat-mega-menu-2 {
    width: auto;
  }
}

.cat-mega-menu.cat-mega-menu-2 > li.right-menu {
  width: 25%;
}

@media (max-width: 991px) {
  .cat-mega-menu.cat-mega-menu-2 > li.right-menu {
    width: 100%;
  }
}

.cat-mega-menu.cat-mega-menu-3 {
  width: 440px;
}

@media (max-width: 991px) {
  .cat-mega-menu.cat-mega-menu-3 {
    width: auto;
  }
}

.cat-mega-menu.cat-mega-menu-3 > li.right-menu {
  width: 50%;
}

@media (max-width: 991px) {
  .cat-mega-menu.cat-mega-menu-3 > li.right-menu {
    width: 100%;
  }
}

/* ---Category Menu In Mobile Device--- */
@media (max-width: 991px) {
  .category-menu-list > ul > li.right-menu > a:after {
    display: none;
  }
  .category-menu-list > ul > li i {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    height: 50px;
    width: 70px;
    line-height: 50px;
    z-index: 9;
    display: block;
  }
  .category-menu-list > ul > li i:before {
    background-color: #606060;
    width: 8px;
    height: 2px;
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -3px;
    margin-top: -1px;
    top: 50%;
  }
  .category-menu-list > ul > li i:after {
    background-color: #606060;
    width: 2px;
    height: 8px;
    content: "";
    position: absolute;
    left: 50%;
    margin-top: -4px;
    margin-left: 0;
    top: 50%;
    transition: all 0.3s ease 0s;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  .category-menu-list > ul > li i.menu-expand.active:after {
    background-color: transparent;
  }
  .category-menu-list > ul > li i.expand:after {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  li.right-menu .cat-mega-menu > li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    padding: 0;
    position: relative;
  }
  .cat-mega-menu > li.cat-mega-title > a:after {
    display: none;
  }
  .cat-mega-menu > li.cat-mega-title > a,
  .cat-mega-menu > li > ul > li > a,
  .cat-dropdown > li > a {
    padding: 10px 25px 10px 35px;
    font-size: 13px;
    color: #444444;
    font-weight: normal;
    position: relative;
    margin: 0;
    display: block;
    text-transform: inherit;
  }
  .cat-mega-menu > li.cat-mega-title:last-child > a,
  .cat-mega-menu > li > ul > li:last-child > a,
  .cat-dropdown > li:last-child > a {
    border-bottom: 0;
  }
}

/*----------------------------------------*/
/*  14 - Components - Banner
/*----------------------------------------*/
.hiraola-banner_area {
  padding-top: 30px;
}

.hiraola-banner_area .container-fluid {
  padding: 0 3%;
}

@media (max-width: 991px) {
  .hiraola-banner_area .container-fluid [class*="col-"]:nth-child(-n + 2) {
    padding-bottom: 30px;
  }
}

.static-banner_area {
  padding-top: 80px;
}

.static-banner_area .static-banner-image {
  background-image: url("../images/banner/static-banner.png");
  background-size: cover;
  min-height: 345px;
  background-repeat: no-repeat;
}

.static-banner_area .static-banner-content {
  padding-left: 75px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 575px) {
  .static-banner_area .static-banner-content {
    padding-left: 30px;
  }
}

.static-banner_area .static-banner-content > p {
  font-size: 14px;
  margin-bottom: 0;
  padding-bottom: 10px;
  z-index: 99;
}

.static-banner_area .static-banner-content > p span {
  color: #ea3a3c;
}

.static-banner_area .static-banner-content > p.schedule {
  margin-bottom: 0;
  padding-bottom: 35px;
}

.static-banner_area .static-banner-content > p.schedule span {
  font-size: 24px;
  color: #ea3a3c;
  font-weight: 500;
}

.static-banner_area .static-banner-content > h2,
.static-banner_area .static-banner-content h3 {
  font-weight: 400;
  font-size: 36px;
  letter-spacing: -.025em;
  margin-bottom: 0;
  padding-bottom: 10px;
}

@media (max-width: 575px) {
  .static-banner_area .static-banner-content > h2,
  .static-banner_area .static-banner-content h3 {
    font-size: 24px;
  }
}

.static-banner_area .static-banner-content h3 {
  padding-bottom: 20px;
}

.static-banner_area .static-banner-content .hiraola-btn-ps_left .hiraola-btn:hover {
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
  color: #ffffff !important;
}

.hiraola-banner_area-2 {
  padding-top: 80px;
}

@media (max-width: 991px) {
  .hiraola-banner_area-2 [class*="col"]:first-child {
    padding-bottom: 30px;
  }
}

.hiraola-banner_area-3 {
  padding-top: 80px;
}

@media (max-width: 991px) {
  .hiraola-banner_area-3 [class*="col"]:nth-child(-n + 2) {
    padding-bottom: 30px;
  }
}

/*----------------------------------------*/
/*  15 - Components - Product
/*----------------------------------------*/
.hiraola-product_area {
  padding-top: 75px;
  margin: 0 auto;
  text-align: center;
}

.hiraola-product_area .hiraola-section_title {
  margin-bottom: 30px;
}

.hiraola-product_area.section-space_add {
  padding-bottom: 80px;
}

/* ---Hiraola's Product Slider--- */
.hiraola-product_slider .slide-item .single_product,
.hiraola-product_slider-3 .slide-item .single_product,
.hiraola-trending-product_slider .slide-item .single_product,
.hiraola-product-tab_slider-2 .slide-item .single_product,
.hiraola-product-tab_slider-3 .slide-item .single_product,
.shop-product-wrap .slide-item .single_product {
  background-color: #ffffff;
}

.hiraola-product_slider .slide-item .single_product .product-img,
.hiraola-product_slider-3 .slide-item .single_product .product-img,
.hiraola-trending-product_slider .slide-item .single_product .product-img,
.hiraola-product-tab_slider-2 .slide-item .single_product .product-img,
.hiraola-product-tab_slider-3 .slide-item .single_product .product-img,
.shop-product-wrap .slide-item .single_product .product-img {
  position: relative;
  overflow: visible;
}

.hiraola-product_slider .slide-item .single_product .product-img > a,
.hiraola-product_slider-3 .slide-item .single_product .product-img > a,
.hiraola-trending-product_slider .slide-item .single_product .product-img > a,
.hiraola-product-tab_slider-2 .slide-item .single_product .product-img > a,
.hiraola-product-tab_slider-3 .slide-item .single_product .product-img > a,
.shop-product-wrap .slide-item .single_product .product-img > a {
  display: block;
}

.hiraola-product_slider .slide-item .single_product .product-img > a img,
.hiraola-product_slider-3 .slide-item .single_product .product-img > a img,
.hiraola-trending-product_slider .slide-item .single_product .product-img > a img,
.hiraola-product-tab_slider-2 .slide-item .single_product .product-img > a img,
.hiraola-product-tab_slider-3 .slide-item .single_product .product-img > a img,
.shop-product-wrap .slide-item .single_product .product-img > a img {
  width: 100%;
}

.hiraola-product_slider .slide-item .single_product .product-img > a .secondary-img,
.hiraola-product_slider-3 .slide-item .single_product .product-img > a .secondary-img,
.hiraola-trending-product_slider .slide-item .single_product .product-img > a .secondary-img,
.hiraola-product-tab_slider-2 .slide-item .single_product .product-img > a .secondary-img,
.hiraola-product-tab_slider-3 .slide-item .single_product .product-img > a .secondary-img,
.shop-product-wrap .slide-item .single_product .product-img > a .secondary-img {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 100%;
}

.hiraola-product_slider .slide-item .single_product .product-img:hover .secondary-img,
.hiraola-product_slider-3 .slide-item .single_product .product-img:hover .secondary-img,
.hiraola-trending-product_slider .slide-item .single_product .product-img:hover .secondary-img,
.hiraola-product-tab_slider-2 .slide-item .single_product .product-img:hover .secondary-img,
.hiraola-product-tab_slider-3 .slide-item .single_product .product-img:hover .secondary-img,
.shop-product-wrap .slide-item .single_product .product-img:hover .secondary-img {
  opacity: 1;
}

.hiraola-product_slider .slide-item .single_product .product-img .add-actions,
.hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions,
.hiraola-trending-product_slider .slide-item .single_product .product-img .add-actions,
.hiraola-product-tab_slider-2 .slide-item .single_product .product-img .add-actions,
.hiraola-product-tab_slider-3 .slide-item .single_product .product-img .add-actions,
.shop-product-wrap .slide-item .single_product .product-img .add-actions {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.hiraola-product_slider .slide-item .single_product .product-img .add-actions > ul li,
.hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions > ul li,
.hiraola-trending-product_slider .slide-item .single_product .product-img .add-actions > ul li,
.hiraola-product-tab_slider-2 .slide-item .single_product .product-img .add-actions > ul li,
.hiraola-product-tab_slider-3 .slide-item .single_product .product-img .add-actions > ul li,
.shop-product-wrap .slide-item .single_product .product-img .add-actions > ul li {
  position: relative;
  margin-bottom: 5px;
  display: inline-block;
}

.hiraola-product_slider .slide-item .single_product .product-img .add-actions > ul li > a,
.hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions > ul li > a,
.hiraola-trending-product_slider .slide-item .single_product .product-img .add-actions > ul li > a,
.hiraola-product-tab_slider-2 .slide-item .single_product .product-img .add-actions > ul li > a,
.hiraola-product-tab_slider-3 .slide-item .single_product .product-img .add-actions > ul li > a,
.shop-product-wrap .slide-item .single_product .product-img .add-actions > ul li > a {
  background-color: #333333;
  color: #ffffff;
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  transform: scaleX(0);
}

.hiraola-product_slider .slide-item .single_product .product-img .add-actions > ul li > a > i,
.hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions > ul li > a > i,
.hiraola-trending-product_slider .slide-item .single_product .product-img .add-actions > ul li > a > i,
.hiraola-product-tab_slider-2 .slide-item .single_product .product-img .add-actions > ul li > a > i,
.hiraola-product-tab_slider-3 .slide-item .single_product .product-img .add-actions > ul li > a > i,
.shop-product-wrap .slide-item .single_product .product-img .add-actions > ul li > a > i {
  font-size: 15px;
}

.hiraola-product_slider .slide-item .single_product .product-img .add-actions > ul li > a:hover,
.hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions > ul li > a:hover,
.hiraola-trending-product_slider .slide-item .single_product .product-img .add-actions > ul li > a:hover,
.hiraola-product-tab_slider-2 .slide-item .single_product .product-img .add-actions > ul li > a:hover,
.hiraola-product-tab_slider-3 .slide-item .single_product .product-img .add-actions > ul li > a:hover,
.shop-product-wrap .slide-item .single_product .product-img .add-actions > ul li > a:hover {
  color: #ffffff !important;
}

.hiraola-product_slider .slide-item .single_product .product-img .add-actions > ul li > a.hiraola-add_compare,
.hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions > ul li > a.hiraola-add_compare,
.hiraola-trending-product_slider .slide-item .single_product .product-img .add-actions > ul li > a.hiraola-add_compare,
.hiraola-product-tab_slider-2 .slide-item .single_product .product-img .add-actions > ul li > a.hiraola-add_compare,
.hiraola-product-tab_slider-3 .slide-item .single_product .product-img .add-actions > ul li > a.hiraola-add_compare,
.shop-product-wrap .slide-item .single_product .product-img .add-actions > ul li > a.hiraola-add_compare {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.hiraola-product_slider .slide-item .single_product .product-img .add-actions > ul li > a.quick-view-btn,
.hiraola-product_slider-3 .slide-item .single_product .product-img .add-actions > ul li > a.quick-view-btn,
.hiraola-trending-product_slider .slide-item .single_product .product-img .add-actions > ul li > a.quick-view-btn,
.hiraola-product-tab_slider-2 .slide-item .single_product .product-img .add-actions > ul li > a.quick-view-btn,
.hiraola-product-tab_slider-3 .slide-item .single_product .product-img .add-actions > ul li > a.quick-view-btn,
.shop-product-wrap .slide-item .single_product .product-img .add-actions > ul li > a.quick-view-btn {
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.hiraola-product_slider .slide-item .single_product:hover > .product-img .add-actions,
.hiraola-product_slider-3 .slide-item .single_product:hover > .product-img .add-actions,
.hiraola-trending-product_slider .slide-item .single_product:hover > .product-img .add-actions,
.hiraola-product-tab_slider-2 .slide-item .single_product:hover > .product-img .add-actions,
.hiraola-product-tab_slider-3 .slide-item .single_product:hover > .product-img .add-actions,
.shop-product-wrap .slide-item .single_product:hover > .product-img .add-actions {
  opacity: 1;
}

.hiraola-product_slider .slide-item .single_product:hover > .product-img .add-actions > ul > li > a,
.hiraola-product_slider-3 .slide-item .single_product:hover > .product-img .add-actions > ul > li > a,
.hiraola-trending-product_slider .slide-item .single_product:hover > .product-img .add-actions > ul > li > a,
.hiraola-product-tab_slider-2 .slide-item .single_product:hover > .product-img .add-actions > ul > li > a,
.hiraola-product-tab_slider-3 .slide-item .single_product:hover > .product-img .add-actions > ul > li > a,
.shop-product-wrap .slide-item .single_product:hover > .product-img .add-actions > ul > li > a {
  transform: scaleX(1);
}

.hiraola-product_slider .slide-item .single_product .hiraola-product_content,
.hiraola-product_slider-3 .slide-item .single_product .hiraola-product_content,
.hiraola-trending-product_slider .slide-item .single_product .hiraola-product_content,
.hiraola-product-tab_slider-2 .slide-item .single_product .hiraola-product_content,
.hiraola-product-tab_slider-3 .slide-item .single_product .hiraola-product_content,
.shop-product-wrap .slide-item .single_product .hiraola-product_content {
  text-align: left;
  padding: 0 20px 20px;
}

.hiraola-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info h6,
.hiraola-product_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info h6,
.hiraola-trending-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info h6,
.hiraola-product-tab_slider-2 .slide-item .single_product .hiraola-product_content .product-desc_info h6,
.hiraola-product-tab_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info h6,
.shop-product-wrap .slide-item .single_product .hiraola-product_content .product-desc_info h6 {
  margin-bottom: 0;
  padding-bottom: 5px;
  display: block;
}

.hiraola-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box,
.hiraola-product_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box,
.hiraola-trending-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box,
.hiraola-product-tab_slider-2 .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box,
.hiraola-product-tab_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box,
.shop-product-wrap .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box {
  padding-bottom: 3px;
}

.hiraola-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box > ul > li,
.hiraola-product_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box > ul > li,
.hiraola-trending-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box > ul > li,
.hiraola-product-tab_slider-2 .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box > ul > li,
.hiraola-product-tab_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box > ul > li,
.shop-product-wrap .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box > ul > li {
  display: inline-block;
}

.hiraola-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box > ul > li > i,
.hiraola-product_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box > ul > li > i,
.hiraola-trending-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box > ul > li > i,
.hiraola-product-tab_slider-2 .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box > ul > li > i,
.hiraola-product-tab_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box > ul > li > i,
.shop-product-wrap .slide-item .single_product .hiraola-product_content .product-desc_info .rating-box > ul > li > i {
  font-size: 12px;
}

.hiraola-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .price-box,
.hiraola-product_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .price-box,
.hiraola-trending-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .price-box,
.hiraola-product-tab_slider-2 .slide-item .single_product .hiraola-product_content .product-desc_info .price-box,
.hiraola-product-tab_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .price-box,
.shop-product-wrap .slide-item .single_product .hiraola-product_content .product-desc_info .price-box {
  display: inline-block;
}

.hiraola-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .price-box .new-price,
.hiraola-product_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .price-box .new-price,
.hiraola-trending-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .price-box .new-price,
.hiraola-product-tab_slider-2 .slide-item .single_product .hiraola-product_content .product-desc_info .price-box .new-price,
.hiraola-product-tab_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .price-box .new-price,
.shop-product-wrap .slide-item .single_product .hiraola-product_content .product-desc_info .price-box .new-price {
  font-size: 16px;
}

.hiraola-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .price-box .old-price,
.hiraola-product_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .price-box .old-price,
.hiraola-trending-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .price-box .old-price,
.hiraola-product-tab_slider-2 .slide-item .single_product .hiraola-product_content .product-desc_info .price-box .old-price,
.hiraola-product-tab_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .price-box .old-price,
.shop-product-wrap .slide-item .single_product .hiraola-product_content .product-desc_info .price-box .old-price {
  color: #bababa;
  text-decoration: line-through;
  font-size: 14px;
  margin-left: 10px;
}

.hiraola-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .additional-add_action,
.hiraola-product_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .additional-add_action,
.hiraola-trending-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .additional-add_action,
.hiraola-product-tab_slider-2 .slide-item .single_product .hiraola-product_content .product-desc_info .additional-add_action,
.hiraola-product-tab_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .additional-add_action,
.shop-product-wrap .slide-item .single_product .hiraola-product_content .product-desc_info .additional-add_action {
  display: inline-block;
  float: right;
}

.hiraola-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .additional-add_action > ul > li > a > i,
.hiraola-product_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .additional-add_action > ul > li > a > i,
.hiraola-trending-product_slider .slide-item .single_product .hiraola-product_content .product-desc_info .additional-add_action > ul > li > a > i,
.hiraola-product-tab_slider-2 .slide-item .single_product .hiraola-product_content .product-desc_info .additional-add_action > ul > li > a > i,
.hiraola-product-tab_slider-3 .slide-item .single_product .hiraola-product_content .product-desc_info .additional-add_action > ul > li > a > i,
.shop-product-wrap .slide-item .single_product .hiraola-product_content .product-desc_info .additional-add_action > ul > li > a > i {
  font-size: 18px;
}

/* ---Hiraola's Countdown--- */
.hiraola-countdown .count {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  display: inline-block;
  position: relative;
  width: 90px;
  padding: 10px 0;
  margin-right: -3px;
  text-align: center;
}

@media (max-width: 479px) {
  .hiraola-countdown .count {
    width: 70px;
  }
}

.hiraola-countdown .count:last-child {
  border-right: 1px solid #e5e5e5;
}

.hiraola-countdown .count span.count-amount {
  display: block;
  font-weight: 700;
  color: #333333;
  font-size: 24px;
}

@media (max-width: 767px) {
  .hiraola-countdown .count span.count-amount {
    font-size: 20px;
  }
}

.hiraola-countdown .count span.count-period {
  display: block;
}

/*----------------------------------------*/
/*  16 - Components - Shipping
/*----------------------------------------*/
.hiraola-shipping_area {
  background-color: #f4f4f4;
}

.hiraola-shipping_area .shipping-nav {
  border-bottom: 1px solid #e5e5e5;
  padding: 80px 0 75px;
}

.hiraola-shipping_area .shipping-nav .shipping-item {
  text-align: center;
}

.hiraola-shipping_area .shipping-nav .shipping-item .shipping-icon {
  padding-bottom: 10px;
  font-size: 42px;
}

.hiraola-shipping_area .shipping-nav .shipping-item .shipping-content > h6 {
  margin-bottom: 0;
  padding-bottom: 10px;
  font-size: 15px;
}

.hiraola-shipping_area .shipping-nav .shipping-item .shipping-content > p {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hiraola-shipping_area .shipping-nav [class*="col"]:nth-child(-n + 2) .shipping-item {
    padding-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .hiraola-shipping_area .shipping-nav [class*="col"]:nth-child(-n + 3) .shipping-item {
    padding-bottom: 25px;
  }
}

.hiraola-shipping_area.hiraola-shipping_area-2 {
  background-color: #ffffff;
}

.hiraola-shipping_area.hiraola-shipping_area-2 .shipping-nav {
  padding: 60px 0 55px;
}

/*----------------------------------------*/
/*  18 - Components - Form
/*----------------------------------------*/
/* ---Hiraola's Newsletter Form--- */
.newsletter-form_wrap .subscribe-form {
  position: relative;
}

.newsletter-form_wrap .subscribe-form .newsletter-input {
  border: 1px solid #e5e5e5;
  height: 50px;
  width: 100%;
  padding: 0 70px 0 20px;
}

.newsletter-form_wrap .subscribe-form .newsletter-btn {
  color: #ffffff;
  position: absolute;
  font-size: 21px;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.newsletter-form_wrap .subscribe-form .newsletter-btn:hover {
  background-color: #333333;
}

/*----------------------------------------*/
/*  19 - Components - Social Link
/*----------------------------------------*/
.hiraola-social_link {
  padding-top: 25px;
}

.hiraola-social_link > ul > li {
  display: inline-block;
  padding-right: 10px;
}

.hiraola-social_link > ul > li:last-child {
  padding-right: 0;
}

.hiraola-social_link > ul > li > a {
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-size: 16px;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

@media (max-width: 479px) {
  .hiraola-social_link > ul > li > a {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}

.hiraola-social_link > ul > li > a:hover {
  color: #ffffff !important;
}

/*----------------------------------------*/
/*  17 - Components - Product Tab
/*----------------------------------------*/
/* ---Hiraola's Product Tab Area--- */
.hiraola-product-tab_area-2,
.hiraola-product-tab_area-3,
.hiraola-product-tab_area-4 {
  background-color: #ffffff;
  padding-top: 75px;
}

.hiraola-product-tab_area-2 .hiraola-section_title-3 > h4,
.hiraola-product-tab_area-3 .hiraola-section_title-3 > h4,
.hiraola-product-tab_area-4 .hiraola-section_title-3 > h4 {
  padding-bottom: 20px;
}

.hiraola-product-tab_area-2 .product-tab,
.hiraola-product-tab_area-3 .product-tab,
.hiraola-product-tab_area-4 .product-tab {
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-bottom: 25px;
}

@media (max-width: 767px) {
  .hiraola-product-tab_area-2 .product-tab,
  .hiraola-product-tab_area-3 .product-tab,
  .hiraola-product-tab_area-4 .product-tab {
    display: block;
  }
}

@media (max-width: 767px) {
  .hiraola-product-tab_area-2 .product-tab .hiraola-tab_title,
  .hiraola-product-tab_area-3 .product-tab .hiraola-tab_title,
  .hiraola-product-tab_area-4 .product-tab .hiraola-tab_title {
    padding-bottom: 20px;
  }
}

.hiraola-product-tab_area-2 .product-tab .hiraola-tab_title > h4,
.hiraola-product-tab_area-3 .product-tab .hiraola-tab_title > h4,
.hiraola-product-tab_area-4 .product-tab .hiraola-tab_title > h4 {
  text-transform: uppercase;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .hiraola-product-tab_area-2 .product-tab .hiraola-tab_title > h4,
  .hiraola-product-tab_area-3 .product-tab .hiraola-tab_title > h4,
  .hiraola-product-tab_area-4 .product-tab .hiraola-tab_title > h4 {
    font-size: 20px;
  }
}

.hiraola-product-tab_area-2 .product-tab > ul.product-menu > li > a,
.hiraola-product-tab_area-3 .product-tab > ul.product-menu > li > a,
.hiraola-product-tab_area-4 .product-tab > ul.product-menu > li > a {
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 30px;
  position: relative;
}

@media (max-width: 991px) {
  .hiraola-product-tab_area-2 .product-tab > ul.product-menu > li > a,
  .hiraola-product-tab_area-3 .product-tab > ul.product-menu > li > a,
  .hiraola-product-tab_area-4 .product-tab > ul.product-menu > li > a {
    padding: 0 15px;
  }
}

@media (max-width: 575px) {
  .hiraola-product-tab_area-2 .product-tab > ul.product-menu > li > a,
  .hiraola-product-tab_area-3 .product-tab > ul.product-menu > li > a,
  .hiraola-product-tab_area-4 .product-tab > ul.product-menu > li > a {
    padding: 0 15px 0 0;
    font-size: 14px;
  }
}

.hiraola-product-tab_area-2 .product-tab > ul.product-menu > li > a:after,
.hiraola-product-tab_area-3 .product-tab > ul.product-menu > li > a:after,
.hiraola-product-tab_area-4 .product-tab > ul.product-menu > li > a:after {
  background-color: #cccccc;
  content: "";
  position: absolute;
  top: 50%;
  right: -9px;
  height: 2px;
  width: 18px;
}

@media (max-width: 991px) {
  .hiraola-product-tab_area-2 .product-tab > ul.product-menu > li > a:after,
  .hiraola-product-tab_area-3 .product-tab > ul.product-menu > li > a:after,
  .hiraola-product-tab_area-4 .product-tab > ul.product-menu > li > a:after {
    width: 10px;
    right: -5px;
  }
}

@media (max-width: 575px) {
  .hiraola-product-tab_area-2 .product-tab > ul.product-menu > li > a:after,
  .hiraola-product-tab_area-3 .product-tab > ul.product-menu > li > a:after,
  .hiraola-product-tab_area-4 .product-tab > ul.product-menu > li > a:after {
    display: none;
  }
}

.hiraola-product-tab_area-2 .product-tab > ul.product-menu > li:last-child > a,
.hiraola-product-tab_area-3 .product-tab > ul.product-menu > li:last-child > a,
.hiraola-product-tab_area-4 .product-tab > ul.product-menu > li:last-child > a {
  padding-right: 0;
}

.hiraola-product-tab_area-2 .product-tab > ul.product-menu > li:last-child > a:after,
.hiraola-product-tab_area-3 .product-tab > ul.product-menu > li:last-child > a:after,
.hiraola-product-tab_area-4 .product-tab > ul.product-menu > li:last-child > a:after {
  display: none;
}

@media (max-width: 767px) {
  .hiraola-product-tab_area-2 .product-tab > ul.product-menu > li:first-child > a,
  .hiraola-product-tab_area-3 .product-tab > ul.product-menu > li:first-child > a,
  .hiraola-product-tab_area-4 .product-tab > ul.product-menu > li:first-child > a {
    padding-left: 0;
  }
}

.hiraola-product-tab_area-2.hiraola-product-tab_area-3 .product-tab,
.hiraola-product-tab_area-3.hiraola-product-tab_area-3 .product-tab,
.hiraola-product-tab_area-4.hiraola-product-tab_area-3 .product-tab {
  display: flex;
  justify-content: flex-start !important;
}

.hiraola-product-tab_area-2.hiraola-product-tab_area-3 .product-tab .product-menu > li:first-child > a,
.hiraola-product-tab_area-3.hiraola-product-tab_area-3 .product-tab .product-menu > li:first-child > a,
.hiraola-product-tab_area-4.hiraola-product-tab_area-3 .product-tab .product-menu > li:first-child > a {
  padding-left: 0;
}

.hiraola-product-tab_area-2.hiraola-product-tab_area-4,
.hiraola-product-tab_area-3.hiraola-product-tab_area-4,
.hiraola-product-tab_area-4.hiraola-product-tab_area-4 {
  padding-bottom: 80px;
}

/* ---Hiraola's Product Tab Area Three--- */
.sp-product-tab_area {
  background-color: #ffffff;
  padding: 0 0 60px;
}

.sp-product-tab_area .short-desc {
  margin: 0 auto;
  text-align: center;
}

.sp-product-tab_area .short-desc > p {
  margin-bottom: 0;
  padding-bottom: 15px;
}

/*----------------------------------------*/
/*  20 - Components - Modal
/*----------------------------------------*/
/* ---Modal--- */
.modal-wrapper {
  display: block !important;
}

.modal-wrapper.modal {
  visibility: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  z-index: -9;
}

.modal-wrapper.show {
  background-color: rgba(0, 0, 0, 0.9);
  visibility: visible;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  z-index: 1050;
}

.modal-wrapper .modal-dialog {
  max-width: 86%;
}

@media (max-width: 575px) {
  .modal-wrapper .modal-dialog {
    max-width: 100%;
    left: 0;
  }
}

.modal-wrapper .modal-dialog .modal-content {
  padding: 30px 30px 25px;
  height: 755px;
  overflow-y: auto;
}

@media (max-width: 1500px) {
  .modal-wrapper .modal-dialog .modal-content {
    height: 505px;
    overflow-y: auto;
    border-radius: 0;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body {
  padding: 0;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .close {
  font-size: 30px;
  font-weight: 400;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  position: absolute;
  right: 15px;
  top: 0;
  z-index: 99;
}

@media (max-width: 1199px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .close {
    font-size: 25px;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area {
  border-bottom: 1px solid #e5e5e5;
  padding: 0 0 30px !important;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-img_slider-2 {
  border: 1px solid #e5e5e5;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-img_slider-nav {
  padding: 0 60px;
  margin-top: 30px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-img_slider-nav .slick-list {
  margin-left: -15px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-img_slider-nav .slick-list .single-slide {
  border: 1px solid #e5e5e5;
  margin-left: 15px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-img_slider-nav .slick-list .single-slide img {
  width: 100%;
}

@media (max-width: 767px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content {
    padding-top: 25px;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .sp-heading > h5 {
  margin-bottom: 0;
  padding-bottom: 20px;
}

@media (max-width: 1199px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .sp-heading > h5 {
    font-size: 18px;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .rating-box {
  padding-bottom: 15px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .rating-box > ul > li {
  display: inline-block;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .price-box {
  padding-bottom: 10px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .price-box .new-price {
  font-size: 24px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .price-box .old-price {
  color: #bababa;
  text-decoration: line-through;
  font-size: 14px;
  margin-left: 10px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .essential_stuff {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .list-item {
  padding-top: 15px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .list-item.last-child {
  padding: 10px 0 20px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .quantity {
  padding-top: 15px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-group_btn {
  padding-top: 22px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-group_btn > ul > li {
  display: inline-block;
  margin-left: 5px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-group_btn > ul > li:first-child {
  margin-left: 0;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-group_btn > ul > li > a {
  border: 2px solid #e5e5e5;
  width: 50px;
  height: 50px;
  line-height: 47px;
  display: block;
  text-align: center;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-group_btn > ul > li > a.add-to_cart {
  background-color: #333333;
  border: 2px solid #333333;
  color: #ffffff;
  width: 140px;
}

@media (max-width: 575px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-group_btn > ul > li > a.add-to_cart {
    width: 110px;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-group_btn > ul > li > a.add-to_cart:hover {
  background-color: #cda557;
  color: #ffffff !important;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-group_btn > ul > li > a > i {
  font-size: 18px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-group_btn > ul > li > a:hover {
  border-color: #cda557;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-tag-line {
  display: flex;
  padding-top: 20px;
  align-items: center;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-tag-line h6 {
  margin-bottom: 0;
  padding-right: 5px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-tag-line > span {
  padding-right: 5px;
}

@media (max-width: 575px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-tag-line > a {
    font-size: 14px;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn {
  padding-top: 17px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li {
  display: inline-block;
  opacity: 0.8;
  position: relative;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li > a {
  background: #3b579d;
  color: #ffffff;
  padding: 2px 5px;
  border-radius: 2px;
  font-size: 14px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li > a:hover {
  color: #ffffff !important;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li > a > i {
  padding-right: 5px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li:nth-child(2) > a {
  background-color: #3acaff;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li:nth-child(3) > a {
  background-color: #fe6d4c;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li:hover {
  opacity: 1;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li .dropdown {
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.2);
  width: 165px;
  padding: 4px;
  transform-origin: 0 0 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  bottom: 25px;
  left: 0;
  z-index: 99;
}

@media (max-width: 575px) {
  .modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li .dropdown {
    left: auto;
    right: 0;
  }
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li .dropdown > li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li .dropdown > li:hover {
  background-color: #3b579d;
  color: #ffffff;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li .dropdown > li > i {
  text-align: center;
  flex-basis: 30px;
  width: 30px;
}

.modal-wrapper .modal-dialog .modal-content .modal-body .modal-inner-area .sp-content .hiraola-social_btn > ul > li:hover > .dropdown {
  opacity: 1;
  visibility: visible;
}

/*----------------------------------------*/
/*  22 - Components - Breadcrumb
/*----------------------------------------*/
.breadcrumb-area {
  background-image: url("../images/breadcrumb/1.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 225px;
  position: relative;
}

@media (max-width: 575px) {
  .breadcrumb-area {
    background-position: right;
  }
}

.breadcrumb-area .breadcrumb-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@media (max-width: 575px) {
  .breadcrumb-area .breadcrumb-content {
    width: 100%;
  }
}

.breadcrumb-area .breadcrumb-content > h2 {
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  font-size: 36px;
  margin-bottom: 0;
  padding-bottom: 20px;
}

@media (max-width: 1199px) {
  .breadcrumb-area .breadcrumb-content > h2 {
    font-size: 20px;
  }
}

.breadcrumb-area .breadcrumb-content ul > li {
  color: #ffffff;
  display: inline-block;
  padding-left: 20px;
  position: relative;
}

.breadcrumb-area .breadcrumb-content ul > li:first-child {
  padding-left: 0;
}

.breadcrumb-area .breadcrumb-content ul > li:before {
  content: "\f054";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  position: absolute;
  right: -15px;
  top: 2px;
  font-size: 10px;
  color: #ffffff;
}

.breadcrumb-area .breadcrumb-content ul > li:last-child:before {
  display: none;
}

.breadcrumb-area .breadcrumb-content ul > li > a {
  color: #ffffff;
}

/*----------------------------------------*/
/*  25 - Section - Header
/*----------------------------------------*/
/* ---Header Top Area--- */
.header-top_area {
  border-bottom: 1px solid #e5e5e5;
}

@media (max-width: 991px) {
  .header-top_area {
    display: none;
  }
}

.header-top_area .ht-menu > ul {
  display: flex;
  justify-content: flex-start;
}

.header-top_area .ht-menu > ul > li {
  border-right: 1px solid #e5e5e5;
  display: inline-block;
  position: relative;
}

@media (max-width: 991px) {
  .header-top_area .ht-menu > ul > li {
    border: 0;
  }
}

.header-top_area .ht-menu > ul > li:hover .ht-dropdown {
  transform: scaleY(1);
}

.header-top_area .ht-menu > ul > li:hover > a {
  color: #ae307c;
}

.header-top_area .ht-menu > ul > li .ht-dropdown {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 0 0 0;
  position: absolute;
  top: 100%;
  left: auto;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  width: 150px;
  padding: 10px;
  right: 0;
  z-index: 99;
}

.header-top_area .ht-menu > ul > li .ht-dropdown > li.active a {
  color: #ae307c;
}

.header-top_area .ht-menu > ul > li .ht-dropdown > li:hover a {
  color: #ae307c;
}

.header-top_area .ht-menu > ul > li .ht-dropdown > li a {
  border-bottom: 1px solid #e5e5e5;
  display: block;
  padding: 10px 5px;
  line-height: 25px;
  font-size: 12px;
}

.header-top_area .ht-menu > ul > li .ht-dropdown > li a img {
  margin-right: 5px;
}

.header-top_area .ht-menu > ul > li .ht-dropdown > li:first-child > a {
  padding-top: 0;
}

.header-top_area .ht-menu > ul > li .ht-dropdown > li:last-child > a {
  border-bottom: 0;
  padding-bottom: 0;
}

.header-top_area .ht-menu > ul > li > a {
  color: #666666;
  padding: 8px 15px;
  display: block;
  text-transform: uppercase;
  font-size: 12px;
}

.header-top_area .ht-menu > ul > li > a > i {
  padding-left: 5px;
  font-size: 11px;
}

.header-top_area .ht-left_area .header-shipping_area > ul > li {
  height: 40px;
  line-height: 35px;
}

.header-top_area .ht-right_area {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .header-top_area .ht-right_area {
    justify-content: center !important;
    padding-bottom: 15px;
  }
}

.header-top_area .ht-right_area .hiraola-social_link {
  padding-top: 0;
}

.header-top_area .ht-right_area .hiraola-social_link > ul > li {
  padding-right: 0;
}

.header-top_area .ht-right_area .hiraola-social_link > ul > li > a {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
}

@media (max-width: 991px) {
  .header-top_area .ht-right_area .hiraola-social_link > ul > li > a {
    border: 0;
  }
}

.header-top_area .ht-right_area .hiraola-social_link > ul > li:first-child > a {
  border-left: 1px solid #e5e5e5;
}

@media (max-width: 991px) {
  .header-top_area .ht-right_area .hiraola-social_link > ul > li:first-child > a {
    border-left: 0 !important;
  }
}

/* ---Header Middle Area--- */
.header-middle_area {
  padding: 30px;
}

.header-middle_area .hm-form_area {
  display: flex;
  justify-content: flex-end;
}

.header-middle_area .hm-searchbox {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  display: flex;
  position: relative;
  border-radius: 5px;
  min-width: 680px;
  height: 45px;
}

.header-middle_area .hm-searchbox .nice-select.select-search-category {
  width: auto;
  line-height: 43px;
  height: 43px;
  margin: 0;
  border: 0;
  padding: 0 28px 0 25px;
  font-size: 13px;
  border-radius: 15px 0 0 15px;
  position: relative;
}

.header-middle_area .select-search-category ul.list {
  height: 440px;
  overflow-y: auto;
  overscroll-behavior-x: none;
}

.header-middle_area .hm-searchbox .current::after {
  content: "";
  position: absolute;
  display: block;
  height: 22px;
  width: 1px;
  background: #e5e5e5;
  top: 10px;
  right: -10px;
  font-size: 13px;
}

.header-middle_area .hm-searchbox .nice-select.select-search-category::before {
  right: 30px;
  top: 25px;
}

.header-middle_area .hm-searchbox input {
  font-size: 13px;
  height: 45px;
  background: transparent;
  border: none;
  width: 100%;
  padding: 0 60px 0 33px;
}

.header-middle_area .header-right_area {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 575px) {
  .header-middle_area .header-right_area {
    justify-content: center;
  }
}

.header-middle_area .header-right_area > ul > li {
  display: inline-block;
}

.header-middle_area .header-right_area > ul > li > a {
  color: #333333;
  padding: 0 15px;
  display: block;
  font-size: 24px;
}

@media (max-width: 1199px) {
  .header-middle_area .header-right_area > ul > li > a {
    padding: 30px 10px;
  }
}

.header-middle_area .header-right_area > ul > li > a > i {
  vertical-align: middle;
  padding-right: 5px;
}

.header-middle_area .header-right_area > ul > li > a > span {
  font-size: 16px;
}

.header-middle_area .header-right_area > ul > li:last-child > a {
  padding-right: 0;
}

/* ---Header Bottom Area--- */
.header-bottom_area {
  background-color: #cda557;
}

.header-bottom_area > .container {
  position: relative;
}

.header-bottom_area .header-logo {
  padding-top: 15px;
}

@media (max-width: 991px) {
  .header-bottom_area .header-logo {
    padding: 15px 0 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-bottom_area .header-logo {
    padding: 15px 0 0;
  }
}

@media (max-width: 575px) {
  .header-bottom_area .header-logo {
    text-align: center;
    padding: 30px 0 0;
  }
}

.header-bottom_area .main-menu_area {
  display: flex;
  justify-content: flex-start;
}

.header-bottom_area .main-menu_area > nav > ul > li {
  display: inline-block;
  padding-right: 30px;
}

.header-bottom_area .main-menu_area > nav > ul > li:last-child {
  padding-right: 0;
}

.header-bottom_area .main-menu_area > nav > ul > li .hm-dropdown {
  position: absolute;
  background: #ffffff;
  width: 230px;
  padding: 20px 0 24px;
  transform-origin: 0 0 0;
  transform: scaleY(0);
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 9;
}

.header-bottom_area .main-menu_area > nav > ul > li .hm-dropdown > li > a {
  padding: 10px 20px;
  display: block;
}

.header-bottom_area .main-menu_area > nav > ul > li .hm-dropdown > li.active > a {
  color: #cda557;
}

.header-bottom_area .main-menu_area > nav > ul > li:hover .hm-dropdown {
  transform: scaleY(1);
}

.header-bottom_area .main-menu_area > nav > ul > li > ul > li {
  position: relative;
}

.header-bottom_area .main-menu_area > nav > ul > li > ul > li > ul.hm-sub_dropdown {
  top: 30px;
  left: 100%;
  opacity: 0;
  visibility: hidden;
}

.header-bottom_area .main-menu_area > nav > ul > li > ul > li > a {
  display: block;
}

.header-bottom_area .main-menu_area > nav > ul > li > ul > li > a > i {
  position: absolute;
  top: 14px;
  right: 25px;
  display: block;
  font-size: 12px;
  transform: rotate(0);
}

.header-bottom_area .main-menu_area > nav > ul > li > ul > li:hover > .hm-sub_dropdown {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.header-bottom_area .main-menu_area > nav > ul > li > ul > li:hover > a > i {
  transform: rotate(-90deg);
}

.header-bottom_area .main-menu_area > nav > ul > li.megamenu-holder {
  position: static;
}

.header-bottom_area .main-menu_area > nav > ul > li.megamenu-holder .hm-megamenu {
  background: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px;
  transform-origin: 0 0 0;
  transform: scaleY(0);
  position: absolute;
  width: calc(100% - 30px);
  left: 15px;
  right: auto;
  top: 100%;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 99;
}

.header-bottom_area .main-menu_area > nav > ul > li.megamenu-holder:hover .hm-megamenu {
  transform: scaleY(1);
}

.header-bottom_area .main-menu_area > nav > ul > li.megamenu-holder > ul > li {
  width: 33.33%;
}

.header-bottom_area .main-menu_area > nav > ul > li.megamenu-holder > ul > li.menu-item_img {
  background-image: url("../images/menu/bg/1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: -30px;
}

.header-bottom_area .main-menu_area > nav > ul > li.megamenu-holder > ul > li > span.megamenu-title {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  padding-bottom: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-bottom_area .main-menu_area > nav > ul > li.megamenu-holder > ul > li > span.megamenu-title {
    font-size: 15px;
  }
}

.header-bottom_area .main-menu_area > nav > ul > li.megamenu-holder > ul > li > ul > li > a {
  display: block;
  line-height: 35px;
  font-size: 16px;
}

.header-bottom_area .main-menu_area > nav > ul > li > a {
  font-weight: 700;
  color: #ffffff;
  display: block;
  padding: 18px 0;
  text-transform: uppercase;
  position: relative;
}

.header-bottom_area .main-menu_area > nav > ul > li > a:before {
  content: "";
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: inherit;
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
  height: 13px;
  width: 2px;
  opacity: 0;
  visibility: hidden;
}

.header-bottom_area .main-menu_area > nav > ul > li > a:after {
  content: "";
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: inherit;
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  height: 13px;
  width: 2px;
  opacity: 0;
  visibility: hidden;
}

.header-bottom_area .main-menu_area > nav > ul > li:hover > a {
  color: #ffffff !important;
}

.header-bottom_area .main-menu_area > nav > ul > li:hover > a:before {
  opacity: 1;
  visibility: visible;
  left: -10px;
}

.header-bottom_area .main-menu_area > nav > ul > li:hover > a:after {
  opacity: 1;
  visibility: visible;
  right: -10px;
}

.header-bottom_area .header-right_area {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 575px) {
  .header-bottom_area .header-right_area {
    justify-content: center;
  }
}

.header-bottom_area .header-right_area > ul > li {
  display: inline-block;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
}

@media (max-width: 575px) {
  .header-bottom_area .header-right_area > ul > li {
    border: 0;
  }
}

.header-bottom_area .header-right_area > ul > li > a {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  display: block;
  color: #ffffff;
  font-size: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-bottom_area .header-right_area > ul > li > a {
    width: 60px;
  }
}

@media (max-width: 991px) {
  .header-bottom_area .header-right_area > ul > li > a {
    height: 80px;
    line-height: 80px;
  }
}

.header-bottom_area .header-right_area > ul > li > a > i {
  font-size: 26px;
}

.header-bottom_area .header-right_area > ul > li:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.07);
}

@media (max-width: 575px) {
  .header-bottom_area .header-right_area > ul > li:first-child {
    border: 0;
  }
}

.header-bottom_area .header-right_area.header-right_area-2 > ul > li {
  border-right: 1px solid rgba(0, 0, 0, 0.07);
}

@media (max-width: 767px) {
  .header-bottom_area .header-right_area.header-right_area-2 > ul > li {
    border-right: 0;
  }
}

.header-bottom_area .header-right_area.header-right_area-2 > ul > li:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.07);
}

@media (max-width: 767px) {
  .header-bottom_area .header-right_area.header-right_area-2 > ul > li:first-child {
    border-left: 0;
  }
}

.header-bottom_area .header-right_area.header-right_area-2 > ul > li > a {
  color: #333333;
}

.header-bottom_area.header-bottom_area-2 {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

@media (max-width: 1199px) {
  .header-bottom_area.header-bottom_area-2 {
    background-color: #ffffff !important;
    position: relative;
  }
}

.header-bottom_area.header-bottom_area-2 .container-fliud {
  padding: 0 10%;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-bottom_area.header-bottom_area-2 .container-fliud .header-logo {
    padding-top: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-bottom_area.header-bottom_area-2 .container-fliud .header-logo {
    padding: 20px 0 0;
  }
}

.header-bottom_area.header-bottom_area-2 .container-fliud .main-menu_area {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-bottom_area.header-bottom_area-2 .container-fliud .main-menu_area > nav > ul > li {
    padding-right: 16px;
  }
}

.header-bottom_area.header-bottom_area-2 .container-fliud .main-menu_area > nav > ul > li > a {
  padding: 30px 0;
  color: #000;
}

.header-bottom_area.header-bottom_area-2 .container-fliud .main-menu_area > nav > ul > li > a:before {
  color: #000;
}

.header-bottom_area.header-bottom_area-2 .container-fliud .main-menu_area > nav > ul > li > a:after {
  color: #000;
}

.header-bottom_area.header-bottom_area-2 .container-fliud .main-menu_area > nav > ul > li:hover > a {
  color: #cda557 !important;
}

.header-bottom_area.header-bottom_area-2 .container-fliud .main-menu_area > nav > ul > li:hover > a:before {
  color: #cda557;
}

.header-bottom_area.header-bottom_area-2 .container-fliud .main-menu_area > nav > ul > li:hover > a:after {
  color: #cda557;
}

.header-bottom_area.header-bottom_area-2 .container-fliud .main-menu_area > nav > ul > li.megamenu-holder > ul {
  left: 10%;
  width: calc(100% - 20%);
}

.header-bottom_area.header-bottom_area-2 .container-fliud .header-right_area > ul > li > a {
  width: 70px;
  height: 85px;
  line-height: 85px;
  color: #000;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-bottom_area.header-bottom_area-2 .container-fliud .header-right_area > ul > li > a {
    width: 50px;
  }
}

@media (max-width: 479px) {
  .header-bottom_area.header-bottom_area-2 .container-fliud .header-right_area > ul > li > a {
    width: 50px;
  }
}

.header-bottom_area.header-bottom_area-2 .container-fliud .header-right_area > ul > li > a:hover {
  color: #cda557 !important;
}

/* ---Header Sticky--- */
.header-main_area .header-sticky.sticky {
  background: #cda557;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: all 300ms ease-in 0s;
  transition: all 300ms ease-in 0s;
  z-index: 1049;
}

@media (max-width: 575px) {
  .header-main_area .header-sticky.sticky {
    position: static;
  }
}

.header-main_area .header-sticky.sticky.sticky {
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
  animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
}

.header-main_area .header-sticky.sticky.header-sticky:hover {
  background: #cda557;
}

.header-main_area.header-main_area-2 .header-sticky.sticky {
  background-color: #ffffff;
}

.header-main_area.header-main_area-2 .header-sticky.sticky.header-sticky:hover {
  background: #ffffff;
}

/*----------------------------------------*/
/*  25 - Section - Footer
/*----------------------------------------*/
.hiraola-footer_area {
  background-color: #f7f7f7;
}

.hiraola-footer_area .footer-top_area {
  padding: 80px 0 80px;
}

@media (max-width: 767px) {
  .hiraola-footer_area .footer-top_area {
    padding: 60px 0 45px;
  }
}

.hiraola-footer_area .footer-top_area .footer-widgets_info .footer-widgets_logo {
  padding-bottom: 25px;
}

.hiraola-footer_area .footer-top_area .footer-widgets_info .widget-short_desc > p {
  margin-bottom: 0;
}

.hiraola-footer_area .footer-top_area .footer-widgets_info .widgets-essential_stuff > ul > li {
  padding-bottom: 15px;
}

.hiraola-footer_area .footer-top_area .footer-widgets_info .widgets-essential_stuff > ul > li:last-child {
  padding-bottom: 0;
}

.hiraola-footer_area .footer-top_area .footer-widgets_info .widgets-essential_stuff > ul > li > i {
  font-size: 21px;
  padding-right: 10px;
  vertical-align: middle;
}

.hiraola-footer_area .footer-top_area .footer-widgets_title h6 {
  text-transform: uppercase;
  padding-bottom: 20px;
  font-size: 16px;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .hiraola-footer_area .footer-top_area .footer-widgets_title h6 {
    padding-top: 25px;
  }
}

.hiraola-footer_area .footer-top_area .short-desc > p {
  margin-bottom: 0;
  padding-bottom: 20px;
}

.hiraola-footer_area .footer-top_area .footer-widgets > ul > li {
  padding-bottom: 10px;
}

.hiraola-footer_area .footer-top_area .footer-widgets > ul > li:last-child {
  padding-bottom: 0;
}

/* ---Instafeed--- */
.instagram-container {
  position: relative;
  overflow: hidden;
}

#Instafeed > li {
  list-style: none;
  float: left;
  width: 33.33%;
  padding: 0 7.5px;
  margin-bottom: 15px;
}

#Instafeed > li:last-child {
  margin-bottom: 0;
}

#Instafeed > li > a {
  display: block;
  position: relative;
}

#Instafeed > li > a:before {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  content: "";
  background: rgba(0, 0, 0, 0.5);
  -webkit-transform: scale3d(1.2, 1.2, 1.2);
  transform: scale3d(1.2, 1.2, 1.2);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

#Instafeed > li > a:after {
  content: "";
  background-image: url("../images/insta-icon/1.png");
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  margin-left: -12px;
  margin-right: -12px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  z-index: 20;
}

#Instafeed > li > a img {
  width: 100%;
}

#Instafeed > li:hover > a:before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

#Instafeed > li:hover > a:after {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-animation: zoomIn 500ms ease-in-out;
  animation: zoomIn 500ms ease-in-out;
}

/* ---Footer Bottom--- */
.footer-bottom_area .footer-bottom_nav {
  border-top: 1px solid #e5e5e5;
  padding: 30px 0 35px;
  /*Footer Static Bottom*/
}

.footer-bottom_area .footer-bottom_nav .footer-links {
  padding: 0 40px 20px;
}

.footer-bottom_area .footer-bottom_nav .footer-links > ul {
  text-align: center;
}

.footer-bottom_area .footer-bottom_nav .footer-links > ul > li {
  display: inline-block;
  position: relative;
}

.footer-bottom_area .footer-bottom_nav .footer-links > ul > li > a {
  color: #888;
  line-height: 24px;
  letter-spacing: 0.3px;
}

.footer-bottom_area .footer-bottom_nav .footer-links > ul > li::after {
  content: "/";
  color: #888;
}

.footer-bottom_area .footer-bottom_nav .footer-links > ul > li > a:hover {
  color: #242424;
  text-decoration: underline;
}

.footer-bottom_area .footer-bottom_nav .payment {
  text-align: center;
}

.footer-bottom_area .footer-bottom_nav .copyright {
  padding-top: 25px;
  text-align: center;
}

/*----------------------------------------*/
/*  29 - Pages - Shop
/*----------------------------------------*/
.hiraola-content_wrapper {
  padding-top: 75px;
}

.hiraola-sidebar-catagories_area .hiraola-sidebar_categories {
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  padding: 25px;
  margin-bottom: 60px;
}

.hiraola-sidebar-catagories_area .hiraola-sidebar_categories .hiraola-categories_title {
  border-bottom: 2px solid #e5e5e5;
  position: relative;
}

.hiraola-sidebar-catagories_area .hiraola-sidebar_categories .hiraola-categories_title:before {
  content: "";
  background-color: #cda557;
  width: 70px;
  height: 2px;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.hiraola-sidebar-catagories_area .hiraola-sidebar_categories .hiraola-categories_title h5 {
  text-transform: uppercase;
  margin-bottom: 0;
  padding-bottom: 25px;
}

.hiraola-sidebar-catagories_area .hiraola-sidebar_categories .hiraola-categories_title h5:after {
  height: 2px;
  bottom: -2px;
}

.hiraola-sidebar-catagories_area .hiraola-sidebar_categories .sidebar-checkbox_list > li {
  padding-bottom: 15px;
}

.hiraola-sidebar-catagories_area .hiraola-sidebar_categories .sidebar-checkbox_list > li:first-child {
  padding-top: 20px;
}

.hiraola-sidebar-catagories_area .hiraola-sidebar_categories.category-module {
  padding: 0;
}

.hiraola-sidebar-catagories_area .hiraola-sidebar_categories.category-module .category-module_heading {
  background-color: #cda557;
  padding: 15px;
}

.hiraola-sidebar-catagories_area .hiraola-sidebar_categories.category-module .category-module_heading > h5 {
  color: #ffffff;
  text-transform: uppercase;
}

.hiraola-sidebar-catagories_area .hiraola-sidebar_categories.category-module .module-body {
  padding: 20px 25px 25px;
}

.hiraola-sidebar-catagories_area .hiraola-sidebar_categories.category-module .module-body > .module-list_item > li > a {
  padding-bottom: 15px;
  display: block;
}

.hiraola-sidebar-catagories_area .hiraola-sidebar_categories.category-module .module-body > .module-list_item > li .module-sub-list_item > li {
  padding-left: 20px;
}

.hiraola-sidebar-catagories_area .hiraola-sidebar_categories.category-module .module-body > .module-list_item > li .module-sub-list_item > li > a {
  padding-bottom: 15px;
  display: block;
}

.sidebar-banner_area {
  padding-bottom: 60px;
}

.sidebar-banner_area .banner-item {
  border: 1px solid #e5e5e5;
}

/* ---Shop Paginatoin Area--- */
.hiraola-content_wrapper .hiraola-paginatoin-area {
  padding: 0 0 75px;
}

@media (max-width: 575px) {
  .hiraola-content_wrapper .hiraola-paginatoin-area .hiraola-pagination-box {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .hiraola-content_wrapper .hiraola-paginatoin-area .product-select-box {
    justify-content: center;
  }
}

.hiraola-content_wrapper .hiraola-paginatoin-area .product-select-box .product-short > p {
  padding-left: 0;
}

/* ---Shop Toolbar--- */
.shop-toolbar {
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

@media (max-width: 575px) {
  .shop-toolbar {
    display: block;
  }
}

.shop-toolbar .product-view-mode > a {
  color: #333333;
  display: inline-block;
  cursor: pointer;
  padding-right: 10px;
  font-size: 18px;
}

.shop-toolbar .product-view-mode > a.active {
  color: #cda557;
}

.shop-toolbar .product-item-selection_area {
  display: flex;
}

@media (max-width: 575px) {
  .shop-toolbar .product-item-selection_area {
    padding-top: 30px;
    display: block;
    text-align: center;
  }
}

.shop-toolbar .product-item-selection_area .product-short {
  display: flex;
  align-items: center;
}

@media (max-width: 575px) {
  .shop-toolbar .product-item-selection_area .product-short {
    justify-content: center;
  }
}

.shop-toolbar .product-item-selection_area .product-short > label {
  margin-bottom: 0;
  padding-right: 25px;
}

@media (max-width: 991px) {
  .shop-toolbar .product-item-selection_area .product-short > label {
    padding-right: 10px;
  }
}

.shop-toolbar .product-item-selection_area .product-short .nice-select {
  width: 320px;
  height: 30px;
  line-height: 25px;
}

@media (max-width: 479px) {
  .shop-toolbar .product-item-selection_area .product-short .nice-select {
    width: 180px;
  }
}

.shop-toolbar .product-item-selection_area .product-short .nice-select .list {
  width: 100%;
}

.shop-toolbar .product-item-selection_area .product-showing {
  display: flex;
  align-items: center;
  padding-left: 25px;
}

@media (max-width: 575px) {
  .shop-toolbar .product-item-selection_area .product-showing {
    justify-content: center;
    padding: 30px 0 0 0;
  }
}

.shop-toolbar .product-item-selection_area .product-showing > label {
  margin-bottom: 0;
  padding-right: 25px;
}

@media (max-width: 991px) {
  .shop-toolbar .product-item-selection_area .product-showing > label {
    padding-right: 10px;
  }
}

.shop-toolbar .product-item-selection_area .product-showing .myniceselect.nice-select span {
  height: 40px;
  line-height: 40px;
}

@media (max-width: 767px) {
  .shop-toolbar .product-item-selection_area .product-showing .myniceselect.nice-select span {
    padding: 0 35px 0 20px;
  }
}

@media (max-width: 767px) {
  .shop-toolbar .product-item-selection_area .product-showing .myniceselect.nice-select .list {
    width: auto;
    right: 0;
    left: auto;
  }
}

.shop-product-wrap {
  padding: 0 0 30px;
}

.shop-product-wrap.grid .list-slide_item {
  display: none;
}

.shop-product-wrap.grid .slide-item {
  display: block;
}

.shop-product-wrap.grid .slide-item .single_product {
  margin-top: 30px;
  overflow: hidden;
}

.shop-product-wrap.grid .slide-item .single_product .product-img {
  overflow: visible;
}

.shop-product-wrap.grid .slide-item .single_product .product-img img {
  width: 100%;
}

.shop-product-wrap.grid.gridview-2 > [class*="col-"] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 575px) {
  .shop-product-wrap.grid.gridview-2 > [class*="col-"] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.shop-product-wrap.grid.gridview-3 > [class*="col-"] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 33.33333%;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.333333%;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-product-wrap.grid.gridview-3 > [class*="col-"] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 480px) and (max-width: 575px) {
  .shop-product-wrap.grid.gridview-3 > [class*="col-"] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 479px) {
  .shop-product-wrap.grid.gridview-3 > [class*="col-"] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.shop-product-wrap.grid.gridview-4 > [class*="col-"] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.shop-product-wrap.grid.gridview-5 > [class*="col-"] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.shop-product-wrap.listview > [class*="col-"] {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 100%;
  -moz-box-flex: 1;
  -moz-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  width: 100%;
  max-width: 100%;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item {
  display: block;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product {
  background-color: #ffffff;
  margin-top: 30px;
  padding: 25px;
  overflow: hidden;
  display: flex;
}

@media (max-width: 767px) {
  .shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product {
    display: block;
    padding: 15px 15px 30px;
  }
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .product-img {
  position: relative;
  flex-basis: 33.333%;
  width: 33.333%;
}

@media (max-width: 767px) {
  .shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .product-img {
    flex-basis: 100%;
    width: 100%;
  }
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .product-img > a {
  display: block;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .product-img > a img {
  width: 100%;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .product-img > a .secondary-img {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 100%;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .product-img:hover .secondary-img {
  opacity: 1;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .hiraola-product_content {
  flex-basis: 66.666%;
  width: 66.666%;
  padding-left: 30px;
}

@media (max-width: 767px) {
  .shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .hiraola-product_content {
    flex-basis: 100%;
    width: 100%;
    padding-left: 0;
  }
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .hiraola-product_content .product-desc_info > h6 {
  margin-bottom: 0;
  padding-bottom: 10px;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .hiraola-product_content .product-desc_info .rating-box {
  padding-bottom: 5px;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .hiraola-product_content .product-desc_info .rating-box > ul > li {
  display: inline-block;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .hiraola-product_content .product-desc_info .price-box {
  padding-bottom: 5px;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .hiraola-product_content .product-desc_info .product-short_desc > p {
  margin-bottom: 0;
  padding-bottom: 23px;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .hiraola-product_content .add-actions > ul > li {
  display: inline-block;
  padding-left: 5px;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .hiraola-product_content .add-actions > ul > li > a {
  border: 1px solid #e5e5e5;
  padding: 10px 20px;
  display: block;
}

@media (max-width: 479px) {
  .shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .hiraola-product_content .add-actions > ul > li > a {
    padding: 10px;
  }
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .hiraola-product_content .add-actions > ul > li > a:hover {
  background-color: #cda557;
  color: #ffffff !important;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .hiraola-product_content .add-actions > ul > li:first-child .hiraola-add_cart {
  background-color: #333333;
  color: #ffffff;
}

.shop-product-wrap.listview > [class*="col-"] .list-slide_item .single_product .hiraola-product_content .add-actions > ul > li:first-child .hiraola-add_cart:hover {
  background-color: #cda557;
  color: #ffffff !important;
}

.shop-product-wrap.listview > [class*="col-"] .slide-item {
  display: none;
}

/*----------------------------------------*/
/*  30 - Pages - Single Product
/*----------------------------------------*/
/* ---Single Product Page--- */
.sp-area {
  padding: 60px 0 0;
}

.sp-area .sp-nav {
  background-color: #ffffff;
  padding-top: 25px;
}

.sp-area .sp-nav .zoompro-border {
  border: 1px solid #e5e5e5;
}

.sp-area .sp-nav .sp-img_slider {
  padding: 0 30px;
  margin-top: 30px;
}

.sp-area .sp-nav .sp-img_slider .slick-list {
  margin: 0 -15px;
}

.sp-area .sp-nav .sp-img_slider .slick-list .slick-slide {
  border: 1px solid #e5e5e5;
  margin: 0 15px;
}

.sp-area .sp-nav .sp-img_slider .slick-list .slick-slide img {
  width: 100%;
}

@media (max-width: 991px) {
  .sp-area .sp-nav .sp-content {
    padding-top: 30px;
  }
}

.sp-area .sp-nav .sp-content .sp-heading > h5 {
  margin-bottom: 0;
  padding-bottom: 20px;
}

@media (max-width: 575px) {
  .sp-area .sp-nav .sp-content .sp-heading > h5 {
    font-size: 18px;
  }
}

.sp-area .sp-nav .sp-content .rating-box {
  padding: 15px 0 20px;
}

.sp-area .sp-nav .sp-content .rating-box > ul > li {
  display: inline-block;
}

.sp-area .sp-nav .sp-content .rating-box > ul > li > i {
  font-size: 14px;
}

.sp-area .sp-nav .sp-content .sp-essential_stuff > ul > li {
  font-weight: 700;
}

.sp-area .sp-nav .sp-content .sp-essential_stuff > ul > li > a {
  font-weight: 400;
  padding-left: 5px;
}

.sp-area .sp-nav .sp-content .sp-essential_stuff > ul > li > a > span {
  color: #595959 !important;
}

.sp-area .sp-nav .sp-content .price-box {
  padding-bottom: 10px;
}

.sp-area .sp-nav .sp-content .price-box .old-price {
  color: #bababa;
  text-decoration: line-through;
  font-size: 14px;
  margin-left: 10px;
}

.sp-area .sp-nav .sp-content .price-box .new-price {
  font-size: 16px;
}

.sp-area .sp-nav .sp-content .short-desc > p {
  margin-bottom: 0;
  padding-bottom: 20px;
}

.sp-area .sp-nav .sp-content .hiraola-countdown {
  padding: 30px 0 0;
}

.sp-area .sp-nav .sp-content .product-size_box {
  display: flex;
  align-items: center;
  padding-top: 25px;
}

.sp-area .sp-nav .sp-content .product-size_box > span {
  padding-right: 15px;
}

.sp-area .sp-nav .sp-content .product-size_box .myniceselect.nice-select > span {
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
}

.sp-area .sp-nav .sp-content .color-list_area {
  margin-top: 25px;
}

.sp-area .sp-nav .sp-content .quantity {
  padding-top: 30px;
}

.sp-area .sp-nav .sp-content .quantity .cart-plus-minus {
  display: inline-block;
  margin-left: 15px;
}

.sp-area .sp-nav .sp-content .qty-btn_area {
  padding-top: 30px;
}

.sp-area .sp-nav .sp-content .qty-btn_area > ul li {
  display: inline-block;
  padding-left: 5px;
}

.sp-area .sp-nav .sp-content .qty-btn_area > ul li:first-child {
  padding-left: 0;
}

.sp-area .sp-nav .sp-content .qty-btn_area > ul li > a {
  border: 1px solid #e5e5e5;
  display: block;
  padding: 10px 15px;
}

.sp-area .sp-nav .sp-content .qty-btn_area > ul li > a > i {
  display: block;
}

.sp-area .sp-nav .sp-content .hiraola-tag-line {
  display: flex;
  align-items: center;
  padding-top: 23px;
}

.sp-area .sp-nav .sp-content .hiraola-tag-line > h6 {
  font-size: 18px;
  margin-bottom: 0;
}

.sp-area .sp-nav .sp-content .hiraola-tag-line > a {
  padding-left: 10px;
  display: block;
}

@media (max-width: 575px) {
  .sp-area .sp-nav .sp-content .hiraola-tag-line > a {
    font-size: 14px;
  }
}

.sp-area .sp-nav .sp-content .hiraola-social_link {
  padding-top: 25px;
  display: flex;
  align-items: center;
}

/* ---Single Product Group Page--- */
.sp-group_area .sp-nav .sp-content .choose-group-product {
  padding-top: 30px;
}

.sp-group_area .sp-nav .sp-content .choose-group-product .cart-table table {
  margin-bottom: 0;
}

.sp-group_area .sp-nav .sp-content .choose-group-product .cart-table table tbody tr td {
  vertical-align: middle;
  text-align: center;
}

/* ---Single Produc Affiliate Page--- */
.sp-affiliate_area .sp-content .qty-btn_area {
  display: inline-block;
  padding-top: 25px !important;
}

.sp-affiliate_area .sp-content .qty-btn_area a.qty-buy_btn {
  background-color: #333333;
  color: #ffffff;
  padding: 15px 30px;
  display: block;
}

.sp-affiliate_area .sp-content .qty-btn_area a.qty-buy_btn:hover {
  background-color: #cda557;
  color: #ffffff !important;
}

/* ---Single Product Slider Area--- */
.sp-slider_area {
  padding-top: 60px;
}

.sp-slider_area .sp-nav {
  padding: 30px 30px 0;
}

.sp-slider_area .sp-nav .sp-slider .slide-item .single_product .product-img {
  position: relative;
  overflow: hidden;
}

.sp-slider_area .sp-nav .sp-slider .slide-item .single_product .product-img > a {
  display: block;
}

.sp-slider_area .sp-nav .sp-slider .slide-item .single_product .product-img > a .secondary-img {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 100%;
}

.sp-slider_area .sp-nav .sp-slider .slide-item .single_product .product-img:hover .secondary-img {
  opacity: 1;
}

.sp-slider_area .sp-nav .sp-content {
  padding-top: 25px !important;
  float: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.sp-slider_area .sp-nav .sp-content .product-size_box {
  justify-content: center;
}

.sp-slider_area .sp-nav .sp-content .hiraola-tag-line {
  justify-content: center;
}

@media (max-width: 575px) {
  .sp-slider_area .sp-nav .sp-content .hiraola-tag-line {
    margin: 0 -30px;
  }
}

.sp-slider_area .sp-nav .sp-content .hiraola-social_link {
  justify-content: center;
}

@media (max-width: 479px) {
  .sp-slider_area .sp-nav .sp-content .hiraola-social_link > ul > li > a {
    width: 35px;
  }
}

/* ---Single Product Gallery--- */
.sp-gallery_area .sp-nav {
  padding-top: 30px;
}

.sp-gallery_area .sp-nav .sp-gallery {
  padding-left: 30px;
}

@media (max-width: 991px) {
  .sp-gallery_area .sp-nav .sp-gallery {
    padding: 0 30px;
  }
}

.sp-gallery_area .sp-nav .sp-gallery .lg-image {
  padding: 5px;
}

@media (max-width: 991px) {
  .sp-gallery_area .sp-nav .sp-gallery .lg-image {
    margin-bottom: 30px;
  }
}

.sp-gallery_area .sp-nav .sp-gallery > .row:first-child {
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .sp-gallery_area .sp-nav .sp-gallery > .row:first-child {
    margin-bottom: 0;
  }
}

@media (max-width: 991px) {
  .sp-gallery_area .sp-nav .sp-content {
    padding: 0 30px 0 30px;
  }
}

/* ---Single Product Gallery Right--- */
.sp-gallery-right_area .sp-nav {
  padding: 30px;
}

@media (max-width: 991px) {
  .sp-gallery-right_area .sp-nav {
    padding: 30px 0;
  }
}

/* ---Single Product Tab Style--- */
.sp-tab-style_left .sp-img_area {
  display: flex;
  flex-direction: row-reverse;
}

.sp-tab-style_left .sp-img_area .sp-large_img {
  flex-basis: 80%;
  width: 80%;
}

.sp-tab-style_left .sp-img_area .sp-img_slider-3 {
  flex-basis: 20%;
  width: 20%;
}

.sp-tab-style_left.sp-tab-style_right .sp-img_area {
  flex-direction: unset !important;
}

/* ---Single Product Sticky--- */
.sp-sticky_area .sp-sticky_gallery {
  padding-left: 0 !important;
}

.sp-sticky_area .sp-sticky_gallery > .row:first-child {
  margin-bottom: 0 !important;
}

.sp-sticky_area .sp-sticky_gallery > .row [class*="col-"] .lg-image {
  margin-bottom: 30px;
}

.sp-sticky_area .sp-sticky_gallery > .row:last-child [class*="col-"]:last-child .lg-image {
  margin-bottom: 0;
}

.sp-product-tab_area {
  background-color: #ffffff;
  padding-bottom: 0;
}

.sp-product-tab_area .sp-product-tab_nav {
  background-color: #ffffff;
  padding-top: 30px;
}

.sp-product-tab_area .sp-product-tab_nav.decrease {
  padding-top: 0;
}

.sp-product-tab_area .sp-product-tab_nav .product-tab {
  background-color: #f4f4f4;
  padding: 25px 0 25px;
}

.sp-product-tab_area .sp-product-tab_nav .product-tab .product-menu {
  padding-bottom: 0 !important;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content {
  border: 1px solid #e5e5e5;
  padding: 30px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .product-description {
  margin: -5px 0 -5px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .product-description > ul {
  max-height: 300px;
  overflow: auto;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .product-description > ul > li {
  padding-top: 15px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .product-description > ul > li:first-child {
  padding-top: 0;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .product-description > ul > li strong {
  display: block;
  font-size: 18px;
  padding-bottom: 10px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .table-striped {
  margin-bottom: 25px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .table-striped > tbody tr:nth-child(2) td > p {
  margin-bottom: 0;
  padding-bottom: 20px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .table-striped > tbody tr:nth-child(2) td .rating-box > ul > li {
  display: inline-block;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .table-striped > tbody tr td {
  padding: 20px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal h2 {
  margin-bottom: 0;
  padding-bottom: 15px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group {
  margin-bottom: 0;
  padding-bottom: 15px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group.second-child {
  padding-bottom: 10px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group.last-child {
  padding-bottom: 0;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .review-input,
.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .review-textarea {
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 30px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .review-input.review-textarea,
.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .review-textarea.review-textarea {
  height: 120px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group label {
  margin-bottom: 0;
  padding-bottom: 18px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .help-block {
  padding-top: 18px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .hiraola-btn-ps_right {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 479px) {
  .sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .hiraola-btn-ps_right {
    justify-content: flex-start;
    padding-top: 20px;
  }
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .your-opinion span .br-wrapper .br-widget a {
  font-size: 16px;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .your-opinion span .br-wrapper .br-widget a.br-active:after {
  color: #cda557;
}

.sp-product-tab_area .sp-product-tab_nav .tab-content .form-horizontal .form-group .your-opinion span .br-wrapper .br-widget a.br-selected:after {
  color: #cda557;
}

/* ---Single Product Slider Area--- */
.sp-product-slider_area {
  padding: 40px 0 60px;
}

/*----------------------------------------*/
/*  31 - Pages - Blog
/*----------------------------------------*/
/* ---Latest Blog--- */
.hiraola-blog_area {
  padding: 35px 0 55px;
  background-color: #ffffff;
}

.hiraola-blog_area .section_title-2 {
  margin-bottom: 30px;
}

.hiraola-blog_area .blog-item .blog-img a img {
  width: 100%;
  display: block;
  overflow: hidden;
}

@media (max-width: 767px) {
  .hiraola-blog_area .blog-item .blog-img.banner-item {
    margin-bottom: 0;
  }
}

.hiraola-blog_area .blog-item .blog-content .blog-heading {
  padding-top: 25px;
}

.hiraola-blog_area .blog-item .blog-content .blog-heading > h5 {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .hiraola-blog_area .blog-item .blog-content .blog-heading > h5 {
    font-size: 16px;
  }
}

.hiraola-blog_area .blog-item .blog-content .blog-meta {
  padding-top: 15px;
}

.hiraola-blog_area .blog-item .blog-content .blog-meta .blog-time_schedule {
  display: inline-block;
}

.hiraola-blog_area .blog-item .blog-content .blog-meta .meta-author {
  display: inline-block;
  padding-left: 5px;
}

.hiraola-blog_area .blog-item .blog-content .blog-meta .meta-author:first-child {
  padding-left: 0;
}

.hiraola-blog_area .blog-item .blog-content .blog-short_desc {
  padding-top: 10px;
}

.hiraola-blog_area .blog-item .blog-content .blog-short_desc > p {
  margin-bottom: 0;
}

.hiraola-blog_area .blog-item .blog-content .hiraola-read-more_area {
  padding-top: 10px;
}

.hiraola-blog_area .blog-item .blog-content .hiraola-read-more_area .hiraola-read_more {
  text-decoration: underline;
}

@media (max-width: 991px) {
  .hiraola-blog_area .hiraola-paginatoin-area {
    padding-bottom: 57px;
  }
}

.hiraola-blog_area .hiraola-paginatoin-area .myniceselect.nice-select span {
  height: 40px;
  line-height: 40px;
  display: inline-block;
  padding: 0 30px;
}

/* ---Latest Blog Two--- */
.hiraola-blog_area-2 {
  padding: 80px 0 0;
  background-color: #ffffff;
}

.hiraola-blog_area-2 .blog-item .blog-img {
  position: relative;
}

.hiraola-blog_area-2 .blog-item .blog-img .blog-meta-2 {
  background-color: #ffffff;
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px;
  text-align: center;
}

.hiraola-blog_area-2 .blog-item .blog-img .blog-meta-2 .blog-time_schedule > .day {
  width: 100%;
  display: block;
  font-size: 28px;
  font-weight: 700;
}

.hiraola-blog_area-2 .blog-item .blog-content {
  background-color: #ffffff;
  padding: 25px;
}

.hiraola-blog_area-2 .blog-item .blog-content .blog-heading {
  padding-top: 0;
}

/* ---Spaces Between Blog Slider item--- */
.hiraola-blog_slider .slick-slide {
  margin-left: 30px;
}

.hiraola-blog_slider .slick-list {
  margin-left: -30px;
}

/* ---Blog Page Style--- */
/* --Search Form--- */
.hiraola-sidebar-search-form .hiraola-search-field {
  width: calc(100% - 50px);
  border: 1px solid #e5e5e5;
  padding: 0 10px;
  font-size: 14px;
  height: 50px;
  float: left;
  text-transform: capitalize;
}

.hiraola-sidebar-search-form .hiraola-search-btn {
  width: 50px;
  height: 50px;
  margin-left: -4px;
  text-align: center;
  cursor: pointer;
  background-color: #595959;
  border: 1px solid #595959;
}

.hiraola-sidebar-search-form .hiraola-search-btn:hover {
  background: #cda557;
  border-color: #cda557;
}

.hiraola-sidebar-search-form .hiraola-search-btn i {
  font-size: 20px;
  line-height: 50px;
  color: #ffffff;
}

/* --Blog Sidebar Other Related Stuff--- */
@media (max-width: 991px) {
  .hiraola-blog-sidebar-wrapper {
    padding-bottom: 55px;
  }
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar {
  padding-top: 30px;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar:first-child {
  padding-top: 0;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar:last-child {
  padding-top: 0;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar .hiraola-blog-sidebar-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 55px;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar .hiraola-blog-sidebar-title:before {
  width: 55px;
  height: 2px;
  bottom: -26px;
  left: 0;
  background-color: #cda557;
  content: "";
  position: absolute;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar .hiraola-blog-archive li {
  margin-bottom: 10px;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar .hiraola-blog-archive li a {
  font-size: 14px;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar .hiraola-blog-archive li:last-child {
  margin-bottom: 0;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar .hiraola-recent-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar .hiraola-recent-post .hiraola-recent-post-thumb {
  width: 65px;
  border: 1px solid #e5e5e5;
  height: 100%;
  margin-bottom: 30px;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar .hiraola-recent-post .hiraola-recent-post-des {
  padding-left: 10px;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar .hiraola-recent-post .hiraola-recent-post-des span {
  display: block;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar .hiraola-recent-post .hiraola-recent-post-des span a:hover {
  color: #ea3a3c;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar .hiraola-recent-post .hiraola-recent-post-des .hiraola-post-date {
  font-size: 13px;
  font-weight: 400;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar .hiraola-blog-tags li {
  display: inline-block;
  margin-bottom: 5px;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar .hiraola-blog-tags li a {
  font-size: 14px;
  border: 1px solid #e5e5e5;
  padding: 5px 15px;
  display: block;
  line-height: 20px;
}

.hiraola-blog-sidebar-wrapper .hiraola-blog-sidebar .hiraola-blog-tags li a:hover {
  color: #ffffff !important;
  background-color: #cda557;
  border-color: #cda557;
}

/* ---Blog Grid View--- */
.blog-grid-view_area {
  padding-bottom: 80px;
}

@media (max-width: 991px) {
  .blog-grid-view_area {
    padding-bottom: 75px;
  }
}

@media (max-width: 991px) {
  .blog-grid-view_area .hiraola-blog-sidebar-wrapper {
    padding-bottom: 0;
  }
}

.blog-grid-view_area .blog-item_wrap [class*="col-"]:nth-child(-n + 4) .blog-item {
  padding-bottom: 30px;
}

@media (max-width: 991px) {
  .blog-grid-view_area .blog-item_wrap [class*="col-"]:nth-child(-n + 5) .blog-item {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .blog-grid-view_area .hiraola-paginatoin-area .hiraola-pagination-box {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .blog-grid-view_area .hiraola-paginatoin-area .product-select-box {
    justify-content: center;
  }
}

/* ---Blog List view Item--- */
.blog-list-view_area {
  padding-bottom: 80px;
}

@media (max-width: 991px) {
  .blog-list-view_area .hiraola-blog-sidebar-wrapper {
    padding: 80px 0 0;
  }
}

.blog-list-view_area .blog-item_wrap [class*="col-"]:nth-child(-n + 5) .blog-item {
  padding-bottom: 30px;
}

.blog-list-view_area .blog-item_wrap .blog-item .blog-content {
  height: 100%;
  border: 1px solid #e5e5e5;
}

@media (max-width: 991px) {
  .blog-list-view_area .hiraola-paginatoin-area {
    padding-bottom: 0;
  }
}

@media (max-width: 575px) {
  .blog-list-view_area .hiraola-paginatoin-area .hiraola-pagination-box {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .blog-list-view_area .hiraola-paginatoin-area .product-select-box {
    justify-content: center;
  }
}

/* ---Blog Column Three & Four--- */
.blog-column-three_area,
.blog-column-two_area {
  padding-bottom: 75px;
}

.blog-column-three_area .blog-item_wrap [class*="col-"]:nth-child(4) .blog-item,
.blog-column-two_area .blog-item_wrap [class*="col-"]:nth-child(4) .blog-item {
  padding-bottom: 0;
}

@media (max-width: 991px) {
  .blog-column-three_area .hiraola-paginatoin-area,
  .blog-column-two_area .hiraola-paginatoin-area {
    padding-bottom: 0;
  }
}

@media (max-width: 575px) {
  .blog-column-three_area .hiraola-paginatoin-area .hiraola-pagination-box,
  .blog-column-two_area .hiraola-paginatoin-area .hiraola-pagination-box {
    text-align: center;
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .blog-column-three_area .hiraola-paginatoin-area .product-select-box,
  .blog-column-two_area .hiraola-paginatoin-area .product-select-box {
    justify-content: center;
  }
}

.blog-column-three_area.blog-column-three_area .blog-item_wrap [class*="col-"]:nth-child(3) .blog-item,
.blog-column-two_area.blog-column-three_area .blog-item_wrap [class*="col-"]:nth-child(3) .blog-item {
  padding-bottom: 30px;
}

@media (max-width: 991px) {
  .blog-column-three_area.blog-column-three_area .blog-item_wrap [class*="col-"] .blog-item,
  .blog-column-two_area.blog-column-three_area .blog-item_wrap [class*="col-"] .blog-item {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-column-three_area.blog-column-three_area .blog-item_wrap [class*="col-"] .blog-item .blog-content .blog-heading h5,
  .blog-column-two_area.blog-column-three_area .blog-item_wrap [class*="col-"] .blog-item .blog-content .blog-heading h5 {
    font-size: 17px;
  }
}

@media (max-width: 991px) {
  .blog-column-three_area.blog-column-three_area .blog-item_wrap [class*="col-"]:last-child .blog-item,
  .blog-column-two_area.blog-column-three_area .blog-item_wrap [class*="col-"]:last-child .blog-item {
    padding-bottom: 0;
  }
}

.blog-list-fullwidth_area .blog-item_wrap .blog-item .blog-content {
  height: 100%;
  display: block;
}

/* ---Alista's Single Blog Slider Dots--- */
.hiraola-single-blog_slider .slick-dots {
  top: 0;
  right: 0;
  left: auto;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  padding: 0;
}

.hiraola-single-blog_slider .slick-dots > li {
  display: inline-block;
  margin: 0 3px;
  cursor: pointer;
  font-size: 0;
  width: 10px;
  height: 10px;
  background: #516a7e;
  border: 0;
  border-radius: 100%;
  transition: all 300ms ease-in;
}

.hiraola-single-blog_slider .slick-dots > li.slick-active {
  background-color: #344b5d;
}

.hiraola-single-blog_slider .slick-dots > li:hover {
  background-color: #344b5d;
}

/* ---Alista's Blog Details--- */
.hiraola-blog-details .blog-item .blog-content {
  background-color: transparent;
  padding: 25px 0;
}

.hiraola-blog-details .hiraola-blog-blockquote {
  padding: 0 0 0 40px;
}

.hiraola-blog-details .hiraola-blog-blockquote blockquote {
  border: 1px solid #e5e5e5;
  padding: 40px;
  position: relative;
  margin: 30px 0 20px;
  font-style: italic;
  background: #f4f4f4;
}

.hiraola-blog-details .hiraola-blog-blockquote blockquote p:before {
  content: "";
  background: #cda557;
  position: absolute;
  width: 5px;
  height: 100%;
  top: 0;
  left: 0;
}

.hiraola-blog-details .hiraola-tag-line {
  padding: 25px 0 25px;
  margin-top: 25px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.hiraola-blog-details .hiraola-tag-line h4 {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  display: inline-block;
  padding-right: 5px;
}

.hiraola-blog-details .hiraola-tag-line a {
  font-style: italic;
  text-transform: capitalize;
  font-size: 16px;
}

.hiraola-blog-details .hiraola-tag-line a:hover {
  color: #cda557;
}

.hiraola-blog-details .hiraola-social_link {
  padding-top: 30px;
  text-align: center;
}

@media (max-width: 479px) {
  .hiraola-blog-details .hiraola-social_link {
    text-align: left;
  }
}

.hiraola-blog-details .hiraola-comment-section {
  padding-top: 28px;
}

.hiraola-blog-details .hiraola-comment-section h3 {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 25px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.hiraola-blog-details .hiraola-comment-section ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 30px 23px;
  margin-bottom: 30px;
  border: 1px solid #e5e5e5;
}

@media (max-width: 479px) {
  .hiraola-blog-details .hiraola-comment-section ul li {
    display: block;
  }
}

.hiraola-blog-details .hiraola-comment-section ul li .author-avatar {
  -ms-flex-preferred-size: 70px;
  flex-basis: 70px;
}

@media (max-width: 479px) {
  .hiraola-blog-details .hiraola-comment-section ul li .author-avatar {
    text-align: center;
    padding-bottom: 20px;
  }
}

.hiraola-blog-details .hiraola-comment-section ul li .comment-body {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  padding-left: 30px;
}

@media (max-width: 479px) {
  .hiraola-blog-details .hiraola-comment-section ul li .comment-body {
    padding-left: 0;
  }
}

.hiraola-blog-details .hiraola-comment-section ul li .comment-body h5 {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 13px;
  margin-bottom: 0;
  text-transform: capitalize;
}

@media (max-width: 479px) {
  .hiraola-blog-details .hiraola-comment-section ul li .comment-body h5 {
    padding-bottom: 18px;
    overflow: hidden;
    line-height: 26px;
  }
}

.hiraola-blog-details .hiraola-comment-section ul li .comment-body p {
  font-size: 14px;
  max-width: 80%;
  margin-bottom: 0;
}

@media (max-width: 479px) {
  .hiraola-blog-details .hiraola-comment-section ul li .comment-body p {
    max-width: 100%;
  }
}

.hiraola-blog-details .hiraola-comment-section ul li .comment-body .comment-post-date {
  padding-bottom: 10px;
}

@media (max-width: 479px) {
  .hiraola-blog-details .hiraola-comment-section ul li .comment-body .comment-post-date {
    padding-bottom: 15px;
  }
}

.hiraola-blog-details .hiraola-comment-section ul li .comment-body .reply-btn {
  float: right;
}

.hiraola-blog-details .hiraola-comment-section ul li .comment-body .reply-btn a {
  color: #cda557;
  font-size: 12px;
  display: inline-block;
  width: 60px;
  height: 25px;
  line-height: 21px;
  text-align: center;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  text-transform: capitalize;
  border: 1px solid #cda557;
}

.hiraola-blog-details .hiraola-comment-section ul li .comment-body .reply-btn a:hover {
  color: #ffffff !important;
  background-color: #cda557;
}

.hiraola-blog-details .hiraola-comment-section ul .comment-children {
  margin-left: 40px;
}

.hiraola-blog-details .hiraola-blog-comment-wrapper {
  padding-bottom: 80px;
}

.hiraola-blog-details .hiraola-blog-comment-wrapper h3 {
  font-size: 18px;
  font-weight: 600;
  padding: 0 0 26px;
  text-transform: uppercase;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 0;
}

.hiraola-blog-details .hiraola-blog-comment-wrapper p {
  padding: 20px 0 0;
  font-size: 14px;
}

.hiraola-blog-details .hiraola-blog-comment-wrapper form .comment-post-box label {
  font-weight: 600;
  margin-bottom: 25px;
  display: block;
  text-transform: capitalize;
}

.hiraola-blog-details .hiraola-blog-comment-wrapper form .comment-post-box .coment-field {
  background: #f4f4f4;
  border: none;
  padding: 8px 10px;
  width: 100%;
}

.hiraola-blog-details .hiraola-blog-comment-wrapper form .comment-post-box textarea {
  height: 130px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
  background: #f4f4f4;
  border: none;
  font-size: 14px;
}

.hiraola-blog-details .hiraola-blog-comment-wrapper form .comment-post-box .comment-btn_wrap {
  padding-top: 30px;
}

.hiraola-blog-details .hiraola-blog-comment-wrapper form .comment-post-box .comment-btn_wrap .hiraola-post-btn_area .hiraola-post_btn {
  background-color: #cda557;
  color: #ffffff;
  width: 140px;
  height: 40px;
  line-height: 37px;
  display: block;
  text-align: center;
  border: 0;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.hiraola-blog-details .hiraola-blog-comment-wrapper form .comment-post-box .comment-btn_wrap .hiraola-post-btn_area .hiraola-post_btn:hover {
  background-color: #333333;
  color: #ffffff !important;
}

@media (max-width: 767px) {
  .hiraola-blog-details .hiraola-blog-comment-wrapper form .comment-post-box [class*="col-"]:nth-child(-n + 3) .coment-field {
    margin-bottom: 30px;
  }
}

/*----------------------------------------*/
/*  32 - Pages - My Account
/*----------------------------------------*/
.account-page-area {
  padding: 80px 0;
}

.account-page-area .myaccount-tab-trigger {
  display: block;
}

@media (max-width: 991px) {
  .account-page-area .myaccount-tab-trigger {
    padding-bottom: 30px;
  }
}

.account-page-area .myaccount-tab-trigger li {
  display: block;
}

.account-page-area .myaccount-tab-trigger li:first-child {
  margin-top: -1px;
}

.account-page-area .myaccount-tab-trigger li a {
  border: 1px solid #444444;
  display: block;
  background: #595959;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 20px;
}

.account-page-area .myaccount-tab-trigger li a.active {
  background: #cda557;
  border-color: #cda557;
  color: #ffffff;
}

.account-page-area .myaccount-tab-trigger li a.active:hover {
  color: #ffffff !important;
}

.account-page-area .myaccount-tab-trigger li a:hover {
  color: #cda557;
}

.myaccount-tab-content {
  border: 1px solid #e5e5e5;
  padding: 30px;
}

.myaccount-dashboard p {
  margin-bottom: 20px;
}

.myaccount-dashboard p:last-child {
  margin-bottom: 0;
}

.myaccount-orders .table {
  margin-bottom: 0;
}

.myaccount-orders .table .account-order-id:hover {
  color: #cda557;
}

.myaccount-orders .table.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.myaccount-orders .table td,
.myaccount-orders .table th {
  vertical-align: middle;
  text-align: center;
}

@media (max-width: 767px) {
  .myaccount-orders .table td,
  .myaccount-orders .table th {
    min-width: 180px;
  }
}

.myaccount-orders .small-title {
  margin-bottom: 15px;
}

/* ---My Account Form Style--- */
.hiraola-form,
.hiraola-form-boxed {
  padding: 30px;
  border-radius: 0;
  border: 1px solid #e5e5e5;
}

.hiraola-form .hiraola-form-inner,
.hiraola-form-boxed .hiraola-form-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: -20px;
}

@media (max-width: 479px) {
  .hiraola-form .hiraola-form-inner,
  .hiraola-form-boxed .hiraola-form-inner {
    display: block;
  }
}

.hiraola-form .hiraola-form-inner .single-input,
.hiraola-form-boxed .hiraola-form-inner .single-input {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 20px;
}

@media (max-width: 479px) {
  .hiraola-form .hiraola-form-inner .single-input,
  .hiraola-form-boxed .hiraola-form-inner .single-input {
    max-width: 100% !important;
  }
}

.hiraola-form .hiraola-form-inner .single-input.single-input-half,
.hiraola-form-boxed .hiraola-form-inner .single-input.single-input-half {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 15px);
  flex: 0 0 calc(50% - 15px);
  max-width: calc(50% - 15px);
}

.hiraola-form .hiraola-form-inner .single-input label,
.hiraola-form-boxed .hiraola-form-inner .single-input label {
  margin-bottom: 8px;
  display: block;
}

.hiraola-form .hiraola-form-inner .single-input input,
.hiraola-form .hiraola-form-inner .single-input select,
.hiraola-form .hiraola-form-inner .single-input textarea,
.hiraola-form-boxed .hiraola-form-inner .single-input input,
.hiraola-form-boxed .hiraola-form-inner .single-input select,
.hiraola-form-boxed .hiraola-form-inner .single-input textarea {
  padding: 0 15px;
  border: 1px solid #e5e5e5;
  height: 40px;
  width: 100%;
}

.hiraola-form .hiraola-form-inner .single-input input:first-child,
.hiraola-form-boxed .hiraola-form-inner .single-input input:first-child {
  margin-top: 20px;
}

.hiraola-form .hiraola-form-inner .single-input .checkbox-input,
.hiraola-form-boxed .hiraola-form-inner .single-input .checkbox-input {
  display: inline-block;
}

.hiraola-form .hiraola-form-inner .single-input .checkbox-input label,
.hiraola-form-boxed .hiraola-form-inner .single-input .checkbox-input label {
  display: inline-block;
}

.hiraola-form .hiraola-form-inner a,
.hiraola-form-boxed .hiraola-form-inner a {
  font-size: 13px;
}

.hiraola-form .hiraola-form-inner a:hover,
.hiraola-form-boxed .hiraola-form-inner a:hover {
  color: #cda557;
}

/*----------------------------------------*/
/*  33 - Pages - Login | Register
/*----------------------------------------*/
/* ---JB's Login Register Area--- */
.hiraola-login-register_area {
  padding: 80px 0;
}

@media (max-width: 991px) {
  [class*="col-"]:first-child form .login-form {
    margin-bottom: 30px;
  }
}

.login-form {
  background-color: #ffffff;
  padding: 30px;
  -webkit-box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.1);
}

.login-form .login-title {
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.login-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.login-form input {
  width: 100%;
  background-color: transparent;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  color: #888888;
  margin-bottom: 15px;
}

.login-form input[type="checkbox"] {
  width: auto;
}

.login-form .check-box {
  float: left;
  margin-right: 70px;
}

.login-form .check-box:last-child {
  margin-right: 0;
}

.login-form .check-box input[type="checkbox"] {
  display: none;
}

.login-form .check-box input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #888888;
  margin: 0;
}

.login-form .check-box input[type="checkbox"] + label:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #e5e5e5;
  content: "";
}

.login-form .check-box input[type="checkbox"] + label:after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "\f00c";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  opacity: 0;
  width: 20px;
  text-align: center;
}

.login-form .check-box input[type="checkbox"]:checked + label:before {
  border: 2px solid #888888;
}

.login-form .check-box input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

@media (max-width: 767px) {
  .forgotton-password_info {
    padding-top: 15px;
  }
}

/*-- Place Order --*/
.hiraola-login_btn,
.hiraola-register_btn {
  background-color: #595959;
  color: #ffffff;
  display: block;
  margin-top: 30px;
  width: 140px;
  border-radius: 0;
  height: 40px;
  line-height: 40px;
  border: 0;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .hiraola-login_btn,
  .hiraola-register_btn {
    margin-top: 25px;
  }
}

.hiraola-login_btn:hover,
.hiraola-register_btn:hover {
  background: #cda557;
}

.hiraola-register_btn {
  margin-top: 15px;
}

/*----------------------------------------*/
/*  34 - Pages - Wishlist
/*----------------------------------------*/
.hiraola-wishlist_area {
  padding: 80px 0;
}

.table-content table {
  background: #ffffff none repeat scroll 0 0;
  border-color: #e5e5e5;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}

.table-content table th,
.table-content table td {
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}

.table-content table th {
  border-top: medium none;
  font-size: 15px;
  text-transform: capitalize;
  padding: 20px 10px;
  text-align: center;
  font-weight: 500;
  vertical-align: middle;
}

.table-content table td {
  border-top: medium none;
  padding: 18px 10px;
  vertical-align: middle;
  font-size: 13px;
}

@media (max-width: 767px) {
  .table-content table td {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .table-content table td:nth-child(3) {
    padding: 32.1px 10px;
    display: block;
    width: 180px;
  }
}

.table-content table td.hiraola-product_remove {
  font-size: 20px;
}

.table-content table td.hiraola-product_remove > a:hover i {
  color: #cda557;
}

.table-content table td.hiraola-product_remove > a > i {
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.table-content table td.hiraola-product-name {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.table-content table td.hiraola-product-price {
  font-size: 16px;
  font-weight: 700;
}

.table-content table td.hiraola-product-stock-status span.in-stock, .table-content table td.hiraola-product-stock-status span.out-stock {
  color: #cda557;
  font-size: 12px;
  text-transform: capitalize;
}

.table-content table td.hiraola-product-stock-status span.out-stock {
  color: #ea3a3c;
}

.table-content table td.hiraola-cart_btn a {
  font-size: 14px;
  text-transform: uppercase;
  background: #595959;
  color: #ffffff;
  height: 40px;
  line-height: 43px;
  width: 130px;
  font-weight: 700;
  display: inline-block;
}

.table-content table td.hiraola-cart_btn a:hover {
  background: #cda557;
  color: #ffffff !important;
}

/*----------------------------------------*/
/*  35 - Pages - Cart
/*----------------------------------------*/
.hiraola-cart-area {
  padding: 80px 0;
}

.table-content table td.product-subtotal {
  font-size: 16px;
  font-weight: 700;
}

.table-content table td.quantity .cart-plus-minus {
  margin: 0 auto;
}

@media (max-width: 767px) {
  .hiraola-cart-area .table-content table td:nth-child(3) {
    padding: 45.1px 10px;
  }
}

.coupon-all {
  margin-top: 30px;
}

.coupon-all .coupon {
  float: left;
}

.coupon-all .coupon input {
  background-color: transparent;
  border: 1px solid #e5e5e5;
  font-size: 13px;
  border-radius: 0;
  height: 42px;
  width: 120px;
  padding: 0 10px;
}

.coupon-all .coupon input.button,
.coupon-all .coupon2 input.button {
  background-color: #595959;
  border: 0 none;
  border-radius: 2px;
  color: #ffffff;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  height: 42px;
  letter-spacing: 1px;
  line-height: 42px;
  padding: 0 25px;
  text-transform: uppercase;
  width: inherit;
}

.coupon-all .coupon input.button:hover,
.coupon-all .coupon2 input.button:hover {
  background: #cda557;
}

.coupon-all .coupon.coupon2,
.coupon-all .coupon2.coupon2 {
  float: right;
}

@media (max-width: 479px) {
  .coupon-all .coupon.coupon2,
  .coupon-all .coupon2.coupon2 {
    float: left;
    padding-top: 30px;
  }
}

.cart-page-total {
  padding-top: 30px;
}

.cart-page-total > h2 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.cart-page-total > ul {
  border: 1px solid #e5e5e5;
}

.cart-page-total > ul li {
  list-style: none;
  font-size: 15px;
  padding: 10px 30px;
  border-bottom: 1px solid #e5e5e5;
  font-weight: 700;
}

.cart-page-total > ul li:last-child {
  border-bottom: 0;
}

.cart-page-total > ul li span {
  float: right;
}

.cart-page-total a {
  background-color: #595959;
  border: 1px solid #e5e5e5;
  color: #ffffff;
  display: inline-block;
  margin-top: 30px;
  padding: 10px 20px;
  text-transform: capitalize;
}

.cart-page-total a:hover {
  background: #cda557;
  border-color: #cda557;
  color: #ffffff !important;
}

/*----------------------------------------*/
/*  36 - Pages - Checkout
/*----------------------------------------*/
.checkout-area {
  padding: 80px 0 75px;
}

.coupon-accordion h3 {
  background-color: #f4f4f4;
  border-top: 3px solid #cda557;
  font-size: 14px;
  font-weight: 400;
  list-style: outside none none;
  margin: 0 0 30px;
  padding: 16px 32px 16px 56px;
  position: relative;
  width: auto;
  text-transform: none;
}

.coupon-accordion h3:before {
  color: #cda557;
  content: "\f133";
  display: inline-block;
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  left: 24px;
  position: absolute;
  top: 16px;
}

.coupon-accordion span {
  cursor: pointer;
  text-transform: none;
}

.coupon-accordion span:hover {
  color: #cda557;
}

.coupon-accordion .coupon-content {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  display: none;
  padding: 20px;
}

.coupon-accordion .coupon-content .coupon-info p {
  margin-bottom: 0;
}

.coupon-accordion .coupon-content .coupon-info p.form-row-first label, .coupon-accordion .coupon-content .coupon-info p.form-row-last label {
  display: block;
}

.coupon-accordion .coupon-content .coupon-info p.form-row-first label span.required, .coupon-accordion .coupon-content .coupon-info p.form-row-last label span.required {
  color: #ff0000;
  font-weight: 700;
}

.coupon-accordion .coupon-content .coupon-info p.form-row-first input, .coupon-accordion .coupon-content .coupon-info p.form-row-last input {
  border: 1px solid #e5e5e5;
  height: 36px;
  margin: 0 0 14px;
  border-radius: 0;
  max-width: 100%;
  padding: 0 0 0 10px;
  width: 370px;
  background-color: transparent;
}

.coupon-accordion .coupon-content .coupon-info p.form-row input[type="submit"] {
  background: #595959 none repeat scroll 0 0;
  border: medium none;
  border-radius: 0;
  box-shadow: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  float: left;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  padding: 0 30px;
  text-shadow: none;
  text-transform: uppercase;
  width: inherit;
}

.coupon-accordion .coupon-content .coupon-info p.form-row input[type="submit"]:hover {
  background: #cda557;
}

.coupon-accordion .coupon-content .coupon-info p.form-row input[type="checkbox"] {
  height: inherit;
  position: relative;
  top: 2px;
  width: inherit;
}

.coupon-accordion .coupon-content .coupon-info p.form-row label {
  margin-top: 7px;
}

.coupon-accordion .coupon-content .coupon-info p.checkout-coupon input[type="submit"] {
  background: #cda557;
}

.coupon-accordion .coupon-content .coupon-info p.lost-password {
  margin-top: 15px;
}

.coupon-accordion .coupon-content .coupon-info p.lost-password a:hover {
  color: #cda557;
}

.coupon-checkout-content {
  margin-bottom: 30px;
  display: none;
}

.coupon-checkout-content .coupon-info .checkout-coupon {
  margin-bottom: 0;
}

.coupon-checkout-content .coupon-info .checkout-coupon input[type="text"] {
  background-color: transparent;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 36px;
  padding-left: 10px;
  width: 170px;
}

.coupon-checkout-content .coupon-info .checkout-coupon input[type="submit"] {
  background: #595959;
  border: medium none;
  border-radius: 0;
  color: #ffffff;
  height: 36px;
  cursor: pointer;
  margin-left: 6px;
  padding: 5px 10px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: inherit;
}

.coupon-checkout-content .coupon-info .checkout-coupon input[type="submit"]:hover {
  background: #cda557;
}

@media (max-width: 479px) {
  .coupon-checkout-content .coupon-info .checkout-coupon .coupon-inner_btn {
    margin: 28px 0 0 !important;
  }
}

.checkbox-form h3 {
  border-bottom: 1px solid #e5e5e5;
  font-size: 25px;
  margin: 0 0 25px;
  padding-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
}

@media (max-width: 479px) {
  .checkbox-form h3 {
    font-size: 18px;
  }
}

.checkbox-form .country-select {
  margin-bottom: 25px;
}

.checkbox-form .country-select .myniceselect.nice-select span {
  height: 40px;
  line-height: 40px;
  display: inline-block;
  width: 100%;
  padding: 0 0 0 15px;
}

.checkbox-form .checkout-form-list {
  margin-bottom: 30px;
}

.checkbox-form .checkout-form-list input[type="text"],
.checkbox-form .checkout-form-list input[type="password"],
.checkbox-form .checkout-form-list input[type="email"] {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 42px;
  width: 100%;
  padding: 0 0 0 10px;
}

.checkbox-form .checkout-form-list input[type="checkbox"] {
  display: inline-block;
  height: inherit;
  margin-right: 10px;
  position: relative;
  top: 2px;
  width: inherit;
}

.checkbox-form .checkout-form-list.create-acc {
  margin-bottom: 15px !important;
}

.checkbox-form .checkout-form-list.create-acc label {
  display: inline-block;
}

.checkbox-form .country-select label, .checkbox-form.checkout-form-list label {
  margin: 0 0 5px;
  display: block;
}

.checkbox-form .country-select label span.required, .checkbox-form.checkout-form-list label span.required {
  color: #cda557;
  display: inline-block;
  font-size: 24px;
  line-height: 16px;
  position: relative;
  top: 5px;
}

.different-address h3 label {
  display: inline-block;
  margin-right: 20px;
  font-size: 25px;
  margin-bottom: 10px;
}

@media (max-width: 479px) {
  .different-address h3 label {
    font-size: 18px;
  }
}

.different-address h3 input {
  height: inherit;
  line-height: normal;
  margin: 4px 0 0;
  position: relative;
  top: 0;
  width: auto;
}

.order-notes .checkout-form-list-2 {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .order-notes .checkout-form-list-2 {
    margin-bottom: 30px;
  }
}

.order-notes textarea {
  background-color: transparent;
  border: 1px solid #e5e5e5;
  height: 90px;
  padding: 15px;
  width: 100%;
}

.create-account,
#ship-box-info {
  display: none;
}

.your-order {
  background: #f4f4f4;
  padding: 30px 40px 30px;
}

.your-order h3 {
  border-bottom: 1px solid #e5e5e5;
  font-size: 25px;
  padding-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
}

@media (max-width: 479px) {
  .your-order h3 {
    font-size: 18px;
  }
}

.your-order .your-order-table table {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  width: 100%;
}

.your-order .your-order-table table thead {
  background-color: #f4f4f4;
}

.your-order .your-order-table table thead tr th {
  border-top: medium none;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  width: 250px;
}

.your-order .your-order-table table thead tr th,
.your-order .your-order-table table thead tr td {
  border-bottom: 1px solid #e5e5e5;
  border-right: medium none;
  font-size: 14px;
  padding: 15px 0;
  text-align: center;
}

.your-order .your-order-table table .cart_item:hover {
  background: #f4f4f4;
}

.your-order .your-order-table table .order-total td {
  border-bottom: medium none;
}

.your-order .your-order-table table .order-total td span {
  font-size: 20px;
}

.your-order .your-order-table table .order-total th {
  border-bottom: medium none;
  font-size: 18px;
}

.payment-method {
  margin-top: 20px;
}

.payment-method .payment-accordion .card {
  border: 0;
  border-radius: 0;
  background: #f4f4f4;
}

.payment-method .payment-accordion .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: #f4f4f4;
  border-bottom: 0;
}

.payment-method .payment-accordion .card-body {
  padding: 0;
}

.payment-method .payment-accordion .panel.panel-default {
  margin-bottom: 20px;
}

.payment-method .payment-accordion .panel-title {
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}

.payment-method .payment-accordion .panel-title a:hover {
  color: #cda557 !important;
}

.order-button-payment input {
  background: #595959;
  border: medium none;
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  height: 50px;
  margin: 20px 0 0;
  padding: 0;
  text-transform: uppercase;
  width: 100%;
  border: 1px solid transparent;
  cursor: pointer;
}

.order-button-payment input:hover {
  background: #cda557;
}

/*----------------------------------------*/
/*  37 - Pages - Compare
/*----------------------------------------*/
.compare-area {
  padding: 80px 0;
}

.compare-table .table thead th {
  border-width: 1px;
  padding: 15px 10px;
}

.compare-table .table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.compare-table .table tbody tr td,
.compare-table .table tbody tr th {
  vertical-align: middle;
  text-align: center;
}

.compare-table .table tbody tr th {
  min-width: 200px;
}

.compare-table .table tbody tr td {
  min-width: 250px;
}

.compare-table .table tbody tr td .compare-pdoduct-image {
  max-width: 200px;
  display: inline-block;
  padding: 20px 0;
}

.compare-table .table tbody tr td .compare-pdoduct-image a {
  display: inline-block;
}

.compare-table .table tbody tr td .compare-pdoduct-image .hiraola-compare_btn {
  margin-top: 30px;
}

.compare-table .table tbody tr td .compare-pdoduct-image .hiraola-compare_btn:hover {
  color: #ffffff !important;
}

.compare-table .table tbody tr td .compare-product-name {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
}

.compare-table .table tbody tr td .compare-product-name a {
  -webkit-transition-property: color;
  -o-transition-property: color;
  transition-property: color;
}

.compare-table .table tbody tr td .compare-product-name a:hover {
  color: #cda557;
}

.compare-table .table tbody tr td .rating-box > ul > li {
  display: inline-block;
}

/*----------------------------------------*/
/*  38 - Pages - Contact
/*----------------------------------------*/
/* ---Google Map Related Stuff--- */
#google-map {
  height: 400px;
}

/* ---Contact Main Page--- */
.contact-main-page {
  padding: 80px 0 60px;
  /* ---Contact Form Area--- */
}

.contact-main-page .contact-page-side-content {
  background-color: #f4f4f4;
  padding: 30px 30px 19px;
  margin-top: 60px;
}

@media (max-width: 479px) {
  .contact-main-page .contact-page-side-content {
    padding: 25px 30px 19px;
  }
}

.contact-main-page .contact-page-side-content .contact-page-message {
  margin-bottom: 0;
  padding-bottom: 25px;
}

.contact-main-page .contact-page-side-content .single-contact-block {
  padding-bottom: 3px;
  margin-bottom: 27px;
  border-bottom: 1px solid #e5e5e5;
}

.contact-main-page .contact-page-side-content .single-contact-block:last-child {
  border-bottom: none;
}

.contact-main-page .contact-page-side-content .single-contact-block h4 {
  margin-bottom: 0;
  padding-bottom: 25px;
}

.contact-main-page .contact-page-side-content .single-contact-block p {
  margin-bottom: 0;
  padding-bottom: 20px;
}

.contact-main-page .contact-page-side-content .single-contact-block.last-child > h4 {
  padding-bottom: 20px;
}

.contact-main-page .contact-page-title {
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 20px;
}

@media (max-width: 479px) {
  .contact-main-page .contact-page-title {
    font-size: 25px;
  }
}

.contact-main-page .contact-form-content {
  margin-top: 60px;
}

.contact-main-page .contact-form-content .contact-form .form-group {
  margin-bottom: 20px;
}

.contact-main-page .contact-form-content .contact-form .form-group.form-group-2 {
  margin-bottom: 25px;
}

.contact-main-page .contact-form-content .contact-form .form-group label {
  display: block;
  font-size: 15px;
  line-height: 29px;
  font-weight: 400;
  margin-bottom: 20px;
  cursor: text;
}

.contact-main-page .contact-form-content .contact-form .form-group label span, .contact-main-page .contact-form-content .contact-form .form-group label.required {
  color: #ff0000;
}

.contact-main-page .contact-form-content .contact-form .form-group input {
  background-color: #ffffff;
  width: 100%;
  height: 50px;
  line-height: 50px;
  outline: none;
  border: 1px solid #e5e5e5;
  padding-left: 20px;
}

.contact-main-page .contact-form-content .contact-form .form-group textarea {
  background-color: #ffffff;
  width: 100%;
  outline: none;
  border: 1px solid #e5e5e5;
  padding-left: 20px;
  line-height: 50px;
  height: 200px;
}

.contact-main-page .contact-form-content .contact-form .form-group .alsita-contact-form_btn {
  line-height: 12px;
  display: inline-block;
  padding: 16px 30px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  background-color: #595959;
  border: 1px solid #595959;
  color: #ffffff;
  text-transform: uppercase;
}

.contact-main-page .contact-form-content .contact-form .form-group .alsita-contact-form_btn:hover {
  background-color: #cda557;
  color: #ffffff;
  border-color: #cda557;
}

/*----------------------------------------*/
/*  39 - Pages - About Us
/*----------------------------------------*/
.about-us-area {
  padding-top: 80px;
}

.about-us-area .overview-content > h2 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 25px;
  padding-bottom: 20px;
  position: relative;
  text-transform: uppercase;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-area .overview-content > h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-area .overview-content > h2 {
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  .about-us-area .overview-content > h2 {
    font-size: 20px;
  }
}

@media (max-width: 479px) {
  .about-us-area .overview-content > h2 {
    font-size: 18px;
  }
}

.about-us-area .overview-content > h2 span {
  color: #cda557;
}

.about-us-area .overview-content > h2:before {
  background-color: #f4f4f4;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%;
}

.about-us-area .overview-content > h2:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: #cda557;
  height: 2px;
  width: 170px;
}

.about-us-area .overview-content > .short_desc {
  margin-bottom: 0;
  padding-bottom: 25px;
}

@media (max-width: 767px) {
  .about-us-area .overview-content .hiraola-about-us_btn-area {
    float: left;
    width: 100%;
    padding-bottom: 30px;
  }
}

.about-us-area .overview-content .hiraola-about-us_btn-area .about-us_btn {
  background: #cda557;
  color: #ffffff;
  display: block;
  float: left;
  height: 50px;
  line-height: 50px;
  width: 145px;
  text-align: center;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.about-us-area .overview-content .hiraola-about-us_btn-area .about-us_btn:hover {
  background: #595959;
  color: #ffffff !important;
}

.project-count-area {
  padding-top: 60px;
}

.project-count-area .single-count:hover .count-icon span {
  animation: 850ms ease-in-out 0s normal none 1 running tada;
}

.project-count-area .single-count .count-icon span {
  font-size: 50px;
  color: #595959;
  line-height: 1;
  display: inline-block;
}

.project-count-area .single-count .count-title h2 {
  color: #cda557;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.2px;
  margin: 15px 0 5px;
}

.project-count-area .single-count .count-title > span {
  font-size: 20px;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-count-area [class*="col-"]:nth-child(-n + 2) .single-count {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .project-count-area [class*="col-"]:nth-child(-n + 2) .single-count {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .project-count-area [class*="col-"] {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .project-count-area [class*="col-"]:last-child {
    padding-bottom: 0;
  }
}

.team-area {
  padding-top: 70px;
  padding-bottom: 80px;
}

.team-area .section_title-2 {
  margin-bottom: 30px;
}

.team-area .team-member .team-thumb img {
  width: 100%;
  display: block;
  overflow: hidden;
}

.team-area .team-member .team-content {
  padding-top: 25px;
}

.team-area .team-member .team-content h3 {
  font-size: 20px;
  line-height: 28px;
  text-transform: capitalize;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.team-area .team-member .team-content p {
  margin-bottom: 0;
  padding-bottom: 15px;
}

.team-area .team-member .team-content .hiraola-social_link {
  padding-top: 20px;
}

.team-area .team-member .team-content .hiraola-social_link > ul > li {
  padding-right: 5px;
}

.team-area .team-member .team-content .hiraola-social_link > ul > li > a {
  width: 35px;
  height: 35px;
  line-height: 33px;
}

@media (max-width: 1199px) {
  .team-area .team-member .team-content .hiraola-social_link > ul > li > a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-area [class*="col-"]:nth-child(-n + 2) .team-member {
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .team-area [class*="col-"]:nth-child(-n + 2) .team-member {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .team-area [class*="col-"]:nth-child(-n + 3) .team-member {
    padding-bottom: 30px;
  }
}

/*----------------------------------------*/
/*  40 - Pages - FAQ
/*----------------------------------------*/
.frequently-area {
  padding: 80px 0 65px;
}

.frequently-area .frequently-content .frequently-desc {
  margin-bottom: 25px;
}

.frequently-area .frequently-content .frequently-desc h3 {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.frequently-area .frequently-content .frequently-desc p {
  margin: 0;
  font-size: 14px;
}

.frequently-area .frequently-accordion .actives {
  border: 1px solid #cda557;
}

.frequently-area .frequently-accordion .card {
  border-radius: 0;
  margin-bottom: 15px;
}

.frequently-area .frequently-accordion .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: #f4f4f4;
  border: 0;
}

.frequently-area .frequently-accordion .card-header a {
  display: block;
  padding: 15px 10px 15px 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.frequently-area .frequently-accordion .card-header a:hover {
  color: #cda557 !important;
}

.frequently-area .frequently-accordion .card-header a.collapsed:before {
  content: "\f067";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  position: absolute;
  right: 15px;
  top: 15px;
  color: #bababa;
}

@media (max-width: 479px) {
  .frequently-area .frequently-accordion .card-header a.collapsed:before {
    display: none;
  }
}

.frequently-area .frequently-accordion .card-header a:after {
  content: "\f068";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  position: absolute;
  right: 15px;
  top: 15px;
  color: #bababa;
}

@media (max-width: 479px) {
  .frequently-area .frequently-accordion .card-header a:after {
    display: none;
  }
}

/*----------------------------------------*/
/*  41 - Pages - 404
/*----------------------------------------*/
.error404-area {
  padding: 60px 0 80px;
}

.error404-area .search-error-wrapper h1 {
  color: #cda557;
  font-size: 120px;
  margin-bottom: 0;
  padding-bottom: 22px;
}

.error404-area .search-error-wrapper h2 {
  font-size: 28px;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.error404-area .search-error-wrapper > p {
  margin: 0 auto 0;
  max-width: 520px;
  font-size: 16px;
}

.error404-area .search-error-wrapper .error-form .inner-error_form {
  position: relative;
  width: 275px;
  margin: 22px auto 30px;
}

.error404-area .search-error-wrapper .error-form .inner-error_form input {
  border: 1px solid #e5e5e5;
  font-size: 13px;
  position: relative;
  width: 270px;
  height: 40px;
  line-height: 40px;
  padding: 0 40px 0 15px;
}

.error404-area .search-error-wrapper .error-search_btn {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 7px 20px;
  position: absolute;
  right: 0;
  top: 0;
}

.error404-area .search-error-wrapper .error-search_btn:hover {
  color: #cda557;
}

.error404-area .search-error-wrapper .hiraola-error_btn {
  background: #cda557;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  line-height: 43px;
  padding: 0 30px;
  text-transform: uppercase;
}

.error404-area .search-error-wrapper .hiraola-error_btn:hover {
  background: #595959;
  color: #ffffff !important;
}

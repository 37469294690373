/*----------------------------------------*/
/*  12 - Components - Animation
/*----------------------------------------*/
/* Hiraola's Animation Style One */
.slick-active {
	&.animation-style-01 {
		.slider-content {
			> span {
				display: block;
				-webkit-animation: 1200ms ease-in-out 0s normal none 1 running zoomInUp;
				animation: 1200ms ease-in-out 0s normal none 1 running zoomInUp;
			}
			> h2 {
				-webkit-animation: 1400ms ease-in-out 0s normal none 1 running zoomInUp;
				animation: 1400ms ease-in-out 0s normal none 1 running zoomInUp;
			}
			> h3 {
				-webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
				animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
			}
			> h4 {
				-webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
				animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
			}
			> h5 {
				-webkit-animation: 1800ms ease-in-out 0s normal none 1 running zoomInUp;
				animation: 1800ms ease-in-out 0s normal none 1 running zoomInUp;
			}
			> .slide-btn {
				-webkit-animation: 2000ms ease-in-out 0s normal none 1 running zoomInUp;
				animation: 2000ms ease-in-out 0s normal none 1 running zoomInUp;
			}
		}
	}
	&.animation-style-02 {
		.slider-content {
			> span {
				display: block;
				-webkit-animation: 1200ms ease-in-out 0s normal none 1 running zoomInRight;
				animation: 1200ms ease-in-out 0s normal none 1 running zoomInRight;
			}
			> h2 {
				-webkit-animation: 1400ms ease-in-out 0s normal none 1 running zoomInRight;
				animation: 1400ms ease-in-out 0s normal none 1 running zoomInRight;
			}
			> h3 {
				-webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInRight;
				animation: 1600ms ease-in-out 0s normal none 1 running zoomInRight;
			}
			> h4 {
				-webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInRight;
				animation: 1600ms ease-in-out 0s normal none 1 running zoomInRight;
			}
			> h5 {
				-webkit-animation: 1800ms ease-in-out 0s normal none 1 running zoomInRight;
				animation: 1800ms ease-in-out 0s normal none 1 running zoomInRight;
			}
			> .slide-btn {
				-webkit-animation: 2000ms ease-in-out 0s normal none 1 running zoomInRight;
				animation: 2000ms ease-in-out 0s normal none 1 running zoomInRight;
			}
		}
	}
}

// Kyframes

/*Top Up Keyframes*/
@-webkit-keyframes alisSlideInUp {
	0% {
		-webkit-transform: translateY(250px);
		transform: translateY(250px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes alisSlideInUp {
	0% {
		-webkit-transform: translateY(250px);
		transform: translateY(250px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
// Begin Slide In Right Kyframes
.slide-in-right {
	-webkit-animation: slide-in-right 4s linear infinite alternate both;
	animation: slide-in-right 4s linear infinite alternate both;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-1-7 17:0:13
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(340px);
		transform: translateX(340px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
@keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(340px);
		transform: translateX(340px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
// Slide In Right Kyframes End Here
